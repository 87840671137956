@import 'assets/scss/custom/_variables.scss';

.wrapper {
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
  }

  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    margin-top: 32px;
    margin-bottom: 32px;
    /* Primary text (32325D) */
    color: #32325d;
  }
  .contentWrapper {
    .nav {
      margin-bottom: 25px;
      gap: 20px;
      .navItem {
        .navLink {
          cursor: pointer;
          color: #32325d;
          font-family: Open Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:hover {
            border: 1px solid transparent;
          }
          &.active {
            font-weight: 600;
            border-bottom: 4px solid#08A5EF;
          }
        }
      }
    }
  }
}
