.wrapper {
  margin-bottom: 30px !important;
  @media (max-width: 767px) {
    gap: 30px;
  }
}
.classes.accountOverviewWrapper {
  .cards {
    display: flex;
    :global {
      .card-body {
        padding: 0;
      }
    }

    .agreementsCardBody {
      margin: 0;

      .engagementsWrapper {
        padding: 25px;
        border-bottom: 1px solid #e9ecef;
        .engagementsTitles {
          font-size: 14px;
          font-weight: bold;
          margin-left: 8px;
        }
      }
    }
  }
}
.contentWrapper {
  .nav {
    margin-bottom: 25px;
    gap: 20px;
    .navItem {
      .navLink {
        cursor: pointer;
        color: #32325d;
        font-family: Open Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &:hover {
          border: 1px solid transparent;
        }
        &.active {
          font-weight: 600;
          border-bottom: 4px solid#08A5EF;
        }
      }
    }
  }
}
.card {
  max-height: calc(100vh - 318px) !important;
}

.userCard {
  .userCardHeader {
    display: flex;
    justify-content: space-between;
    .userLabel {
      font-size: 16px;
      font-weight: 700;
      line-height: 27px;
      display: flex;
      align-items: center;
    }
    .inviteUserButton {
      background-color: #1da1f2;
      color: #ffffff;
      padding: 7px !important;
      border-radius: 4px !important;
      box-shadow: none;
      gap: 7px;
    }
  }
}
.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #32325d;
  margin-bottom: 8px;
}
