.btnWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
}

.selectIcon {
  width: 150px;
  line-height: 24px;
  font-size: 11px;
}

.selectVariable {
  width: 200px;
  line-height: 24px;
  font-size: 11px;
}
