.openDeclineExitModal {
  display: flex !important;
  align-items: center;
  border-radius: 6px;
  z-index: 9;
  position: absolute;
  inset: 0;
  background: rgba(128, 128, 128, 0.5);
  backdrop-filter: blur(4px);
}
.declineExitModal {
  display: none;
  padding: 15px;
  height: calc(100vh- 230px);
  // max-width: 514px;
  // margin: 0 auto;
  @media screen and (max-width: 480px) {
    overflow: auto;
  }
  .card {
    max-width: 514px;
    margin: 0 auto;
    padding: 44px 33px;
    text-align: center;
    .image {
      text-align: center;
      margin-bottom: 16px;
    }
    .alertText {
      h3 {
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #32325d;
        margin-bottom: 16px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #525f7f;
      }
    }
  }
}
