.modalWrapper {
  max-width: 732px;
  .modalHeader {
    padding: 29px 28px;
    h5 {
      max-width: 500px;
      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 0;
        strong {
          font-weight: 700;
        }
      }
    }
    button {
      span {
        font-size: 34px;
      }
    }
  }
}
