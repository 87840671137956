@import 'assets/scss/custom/_variables.scss';

.commentsTab {
  display: flex;
  flex-grow: 1;
  min-height: 0;
  flex-direction: column;
}

.hide-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.projectName {
  width: fit-content;
  max-width: 100%;
}

.priority {
  border-radius: 50% !important;
  justify-content: center;
  background: $lyght-blue;
  color: $white;
  width: 2rem !important;
  display: flex;
  height: 2rem;
  align-items: center;
  font-size: 0.75rem;
}

.storyPriority {
  padding: 2px 8px;
  border-radius: 11px;
  font-size: 10px;
  font-weight: 600;
}
.storyPriorityLow {
  color: #1aae6f;
  background-color: #b0eed3;
}
.storyPriorityHigh {
  color: #f80031;
  background-color: #fdd1da;
}
.storyPriorityMedium {
  color: #fb6340;
  background-color: #ffe3b8;
}

.empty-value-badge {
  background-color: $gray-400 !important;
}

.dateDetails {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
  div {
    margin-right: 1.25rem;
    margin-left: 1rem;
    h6 {
      font-weight: $font-weight-normal;
      margin: 0;
      color: $gray-500;
    }
  }
}

.commentAvatar {
  width: 40px;
  height: 40px;
}

.userAvatar {
  width: 42px;
  height: 42px;
  margin-right: 12px;
}

.commentsFooter {
  width: calc(100% + 30px);
  margin: 0 -15px;
  z-index: 1;
  display: flex;
  align-items: flex-end;
}

.noCommentsWrapper {
  width: 213px;
  height: 213px;
  border-radius: 50%;
  background: $gray-100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  i {
    font-size: 50px;
    color: $gray-400;
  }
  span {
    text-align: center;
    color: #ced4da !important;
    margin-top: 0.5rem;
  }
}
.commentsContainer {
  margin: 0 -8px;
  position: relative;
  flex: 2;
  flex-grow: 2;
  overflow-y: auto;
}
.commentsWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  @media (max-width: 1350px) {
    max-height: calc(100vh - 190px - var(--height) - var(--height-admin));
    &.editorActive {
      max-height: calc(100vh - 190px - var(--height) - var(--height-admin));
      // if editor is active need to decrease size
      &.typingUsers {
        max-height: calc(100vh - 217px - var(--height) - var(--height-admin));
      }
    }
    &.typingUsers {
      max-height: calc(100vh - 217px - var(--height) - var(--height-admin));
    }
  }

  max-height: calc(100vh - 190px - var(--height) - var(--height-admin));
  &.editorActive {
    max-height: calc(100vh - 190px - var(--height) - var(--height-admin));
    // if editor is active need to decrease size
    &.typingUsers {
      max-height: calc(100vh - 217px - var(--height) - var(--height-admin));
    }
  }
  &.typingUsers {
    max-height: calc(100vh - 217px - var(--height) - var(--height-admin));
  }
  // Adding custom scrollbar css because using Scrollbars component causes issue sometimes
  :global {
    .media-comment {
      margin-bottom: 1rem;
    }
  }
}
.customScrollBar {
  &:hover {
    &::-webkit-scrollbar {
      background-color: transparent;
      opacity: 0.9;
      width: 4px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar;
      border-radius: 6px;
      transition: opacity 0.2s, background-color 0.2s linear,
        width 0.2s ease-in-out;
      -webkit-transition: opacity 0.2s, background-color 0.2s linear,
        width 0.2s ease-in-out;
      width: 4px;
    }
  }
  &::-webkit-scrollbar {
    opacity: 0;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    opacity: 0;
    width: 6px;
  }
}
.commentDate {
  width: 100%;
  text-align: center;
  line-height: 0.1em;
  margin: 2rem 0 20px;
  span {
    background: $white;
    color: $gray-700;
    padding: 0 10px;
  }
}

.scrollToBottom {
  position: absolute !important;
  right: 5px;
  z-index: 100 !important;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  margin: 0 !important;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hide {
    display: none !important;
  }

  i {
    transition: all 0.3s ease-in-out;
    width: 32px;
    height: 32px;
    align-items: center;
    display: flex;
    justify-content: center;
    &.open {
      transform: rotate(0deg);
    }

    &.close {
      transform: rotate(180deg);
    }
  }
}
.editorWrapper {
  :global {
    .ql-container {
      width: 100%;
      height: inherit;
    }
    .ql-editor {
      min-height: 1rem !important;
      overflow-y: auto;
      flex-grow: 1;
      max-height: 150px;
    }
    /*.mention-list-container {
      transform: translate(0, calc(-100% - 25px));
      @media(max-width: 1366px) {
        transform: unset;
      }
    }*/
  }
}
.activityWrapper {
  max-height: 585px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.75rem;
  margin-right: -12px;
}
