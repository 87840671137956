@import 'assets/scss/custom/_variables.scss';

.root {
  display: flex;
  align-items: center;

  > * {
    margin: 0px;
    padding: 0px;
  }
  img {
    margin-right: 10px;
    max-height: 24px;
    max-width: 24px;
  }

  img,
  label {
    cursor: pointer;
  }
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $warning !important;
}
