@import 'assets/scss/custom/_variables.scss';

.root {
  .menu {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .summary {
    .filter {
      display: flex;
      align-items: center;
      margin-top: 15px;

      .collapseAll {
        padding: 0;
        margin-right: 10px;
      }
    }

    .btnGroup {
      padding: 0px;
      display: flex;
      align-items: center;
      text-align: left;

      i {
        color: $gray-600;
        font-size: 21px;
        margin-right: 10px;
      }
    }

    tr {
      td {
        white-space: normal !important;
      }
    }

    tr.subGroup {
      td {
        span {
          padding-left: 30px;
          display: block;
          white-space: normal;
          cursor: pointer;
        }
      }
    }
  }

  .filterDropdown {
    margin-right: 30px;
    @media (max-width: 375.98px) {
      margin-right: 20px;
    }

    font-size: 11px;

    display: flex;
    align-items: flex-end;

    .label {
      text-transform: uppercase;
      color: $gray-500;
      margin-right: 5px;
    }

    .dropdownMenu {
      margin-left: 2px;
      button {
        text-transform: uppercase;
        font-size: 11px;
        padding: 0;
        background: none;
        border: none;
        box-shadow: none;
        transform: translateY(-1px);
      }

      :global {
        .dropdown-menu {
          min-width: 8rem;
        }
        .dropdown-item {
          text-transform: capitalize;
        }
      }
    }

    &.sortedDropdown {
      .dropdownMenu {
        :global {
          .dropdown-menu {
            min-width: 10rem !important;
            padding: 15px;

            button {
              font-size: 14px;
            }

            .custom-control {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .checkbox {
    :global {
      .custom-control-label {
        margin-left: 12px;
      }
    }
  }

  .hide {
    display: none;
  }
}
