.modalWrapper {
  .title {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    color: #172b4d;
    margin-bottom: 7px;
  }
  .checkboxWrapper {
    padding: 10px 43px 20px 29px;
    max-width: 129px;
    border-radius: 10px;
    background-color: #f7fafc;
    .checkbox {
      margin-bottom: 4px;
      label {
        display: block;
        font-size: 16px !important;
        color: #8898aa;
        &.activeYear {
          font-weight: 700;
          color: #979797;
        }
      }
    }
    .inputWrapper {
      padding: 10px 43px 20px 29px;
      border-radius: 10px;
      background-color: #f7fafc;
      input {
        margin-bottom: 4px;
        label {
          display: block;
          font-size: 16px !important;
          color: #8898aa;
          &.activeYear {
            font-weight: 700;
            color: #979797;
          }
        }
      }
    }
  }
  .modalTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    .docType {
      color: rgba(50, 50, 93, 1);
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .modalFooter {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #0000000d;
    .cancelBtn {
      padding: 0px;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
      color: #322d52;
      box-shadow: none;
    }
    .saveBtn {
      padding: 7px 15px;
      font-size: 20px;
    }
  }
}
