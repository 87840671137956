.comment {
  &:hover {
    .commentsDropdown {
      display: block;
    }
  }
}
.commentAuthor {
  display: flex;
  min-width: 200px;
  max-width: 200px;
  white-space: nowrap;

  h6 {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
  }
  span {
    font-size: 13px;
    width: auto;
  }
}
.commentHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -20px;
  margin-bottom: 0.5rem;
  min-height: 28px;
  width: 100%;
  :global {
    .dropdown {
      position: absolute;
      right: 0;
    }
  }
}
.commentsDropdown {
  display: none;
}
