@import 'assets/scss/custom/_variables.scss';
.container {
  .fileName {
    color: #525f7f;
    font-family: Open Sans;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    span {
      // max-width: 350px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .tData {
    color: #8898aa;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .dropdownMenu {
      min-width: fit-content;
      text-align: right;
      :global {
        .dropdown-item {
          padding: 8px 16px;
          display: flex;
          align-items: center;
          // justify-content: right;
        }
      }

      .downloadText {
        span {
          color: #3c4d69;
          font-family: Open Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        svg {
          padding-left: 0;
          margin-right: 4px;
        }
      }

      .deleteText {
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $danger;

        i {
          margin-right: 4px;
          font-size: 12px;
          // padding-left: 3px;
        }
      }
    }
  }

  :global {
    .table {
      border-bottom: 0;
    }

    tbody {
      padding: 10px !important;
    }
    .react-bs-table-no-data {
      width: 100% !important;
    }
    .table thead th {
      color: #8898aa;
      font-family: Open Sans;
      text-transform: capitalize;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
      /* 120% */
      letter-spacing: 0.9px;
    }

    .table th {
      padding: 16px;
      vertical-align: baseline;
    }

    .table td {
      vertical-align: middle;
      font-size: 14px;
      color: $gray-800;
      padding: 0;
      border-top: 0px !important;
    }
    tbody,
    thead {
      display: block;
      width: 100%;
    }
    table {
      display: block;
    }
    tr {
      display: flex;
      width: 100%;
    }
    td:nth-child(1) {
      width: 65%;
    }
    td:nth-child(2) {
      width: 10%;
    }
    td:nth-child(3) {
      width: 25%;
    }
    th:nth-child(1) {
      width: 65%;
    }
    th:nth-child(2) {
      width: 10%;
    }
    th:nth-child(3) {
      width: 25%;
    }

    .table tr {
      .fileName {
        overflow: hidden;
        color: #525f7f;
        text-overflow: ellipsis;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.28px;
      }
    }

    .table .table-data {
      border-radius: 2.591px;
      border-top: 1px solid #d2e3ee;
      border-bottom: 1px solid #d2e3ee;
      min-height: 49px;
    }

    .table tbody tr td {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .table tr :first-child .table-data {
      border-left: 1px solid #d2e3ee;
    }

    .table tr :last-child .table-data {
      border-right: 1px solid #d2e3ee;
    }

    .table tbody tr :first-child {
      padding-left: 4px;
    }

    .table tbody tr :last-child {
      padding-right: 4px;
      // max-width: 350px;
      // overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media (max-width: 1200px) {
        // max-width: 250px;
      }
    }
  }

  .tableHeader {
    background: white;
    margin-top: 10px;
  }

  .noDataIndication {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #000;
    padding: 30px;
    .p {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 300;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
    }
    // margin-top: 80px;

    .uploadButton {
      border-radius: 4px;
      margin: 0;
      min-width: 110px;
      padding: 8px 5px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid #adb5bd;
      background: #fff;
      color: #172b4d;
      margin-top: 25px;
    }
  }

  // p {
  //   margin-bottom: 0;
  // }

  .owner-popover {
    margin: 0;

    .owner-popover-body {
      width: 16rem;
      padding: 0.5rem;

      .owner-selector {
        :global {
          .rselect__menu {
            margin-top: 0.125rem;
            box-shadow: 0rem 2rem 2rem 0rem rgba(0, 0, 0, 0.2);
            width: 16rem;
            display: flex;
            justify-content: center;
            left: -0.5rem;
            border-radius: 0 0 0.4375rem 0.4375rem;
          }

          .rselect__menu-list {
            max-height: 15rem;
            width: 100%;
            margin: 0 0.5rem;
          }

          .rselect__option:hover,
          .rselect__option--is-focused,
          .rselect__option--is-selected {
            background-color: $lyght-blue;
            color: $white;
            border-radius: 0.375rem;
          }
        }
      }
    }
  }

  h4 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .hover-hand {
    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-wrapper {
    max-width: 100%;

    > button {
      max-width: 100%;
    }
  }

  .dropdown-menu {
    max-width: 12.5rem;
    min-width: 10rem;
    padding: 0.5rem 0.75rem;
  }

  .result-wrapper {
    margin-top: 0.5rem;
    max-height: 12.5rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .toggle {
    padding: 0;
    border: unset;
    background: unset;
    box-shadow: unset;

    &:hover,
    &:focus {
      box-shadow: none !important;
      transform: unset !important;
      border: unset !important;
      background: unset !important;
    }
  }

  .result-item {
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 6px;

    &.active {
      background: $lyght-blue;
      color: $white;
    }

    &:hover {
      background: $lyght-blue;
      color: $white;
    }
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .left-column {
    color: $gray-700;

    .epic-badge {
      border-radius: 10rem !important;
      max-width: 100% !important;
      transition: all 0.3s;
      cursor: default;

      .close-icon {
        display: none;
      }
    }

    .allow-epic-edit {
      cursor: pointer;

      &:hover {
        .close-icon {
          transition: all 0.3s;
          display: inline-block;
        }
      }
    }

    .description {
      height: max-content;
      border-color: transparent;
      border-radius: 0.5rem;

      img {
        max-width: 100%;
        cursor: pointer;
      }
    }

    .empty-description {
      height: 9rem !important;
    }

    .empty-content {
      background-color: $gray-100;
    }
  }

  .document-modal-mobile {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
  }

  .fileTable {
    &::-webkit-scrollbar {
      background-color: transparent;
      opacity: 0.9;
      width: 4px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar;
      border-radius: 6px;
      transition: opacity 0.2s, background-color 0.2s linear,
        width 0.2s ease-in-out;
      -webkit-transition: opacity 0.2s, background-color 0.2s linear,
        width 0.2s ease-in-out;
      width: 4px;
    }

    width: 100%;
    max-height: calc(100vh - 117px);
    overflow: auto;
    border-right: 1px solid #e9ecef;
    &.height {
      max-height: calc(100vh - 217px);
    }

    @media (max-width: 768px) {
      width: 100%;
      overflow: auto;
    }

    .cursor {
      cursor: pointer;
    }
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 15px;
  border-bottom: 1px solid #e9ecef;
  .folderTitle {
    color: #172b4d;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7.5px;
    i {
      margin-right: 8px;
    }
  }

  .redirectButton {
    color: #1da1f2;
    text-align: center;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    padding: 6px 9px;
    &:hover {
      color: white;
    }
    i {
      top: 0;
    }
  }

  .adminAction {
    display: flex;
    align-items: center;
    gap: 12px;
    .dropdownMenu {
      min-width: fit-content;
      text-align: right;
      :global {
        .dropdown-item {
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        .dropdown-menu {
          min-width: auto;

          padding: 0.5rem 0.75rem;
        }
      }

      .downloadText {
        span {
          color: #3c4d69;
          font-family: Open Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        svg {
          padding-left: 0;
          margin-right: 4px;
        }
      }

      .deleteText {
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $danger;

        i {
          margin-right: 4px;
          font-size: 12px;
          // padding-left: 3px;
        }
      }
    }
    .uploadButton {
      border-radius: 4px;
      margin: 0;
      min-width: 110px;
      padding: 8px 5px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid #adb5bd;
      background: #fff;
      color: #172b4d;
    }

    .downloadAllDocuments {
      span {
        color: #3c4d69;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      svg {
        padding-left: 0;
        margin-left: 10px;
      }
    }
  }
}
