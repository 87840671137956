.root {
  img {
    max-width: 100%;
    border-radius: 6px 6px 0px 0px;
  }

  p {
    margin-bottom: 0;
  }

  // this is the indent styles based on Quill editor classes to show formatted li elements
  :global {
    ol li {
      list-style: none;
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
        list-9;
      counter-increment: list-0;
    }

    // this are the number types for all the ordered list indentations
    ol li:before {
      content: counter(list-0, decimal) '. ';
    }
    ol li.ql-indent-1 {
      counter-increment: list-1;
    }
    ol li.ql-indent-1:before {
      content: counter(list-1, lower-alpha) '. ';
    }
    ol li.ql-indent-1 {
      counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-2 {
      counter-increment: list-2;
    }
    ol li.ql-indent-2:before {
      content: counter(list-2, lower-roman) '. ';
    }
    ol li.ql-indent-2 {
      counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-3 {
      counter-increment: list-3;
    }
    ol li.ql-indent-3:before {
      content: counter(list-3, decimal) '. ';
    }
    ol li.ql-indent-3 {
      counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-4 {
      counter-increment: list-4;
    }
    ol li.ql-indent-4:before {
      content: counter(list-4, lower-alpha) '. ';
    }
    ol li.ql-indent-4 {
      counter-reset: list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-5 {
      counter-increment: list-5;
    }
    ol li.ql-indent-5:before {
      content: counter(list-5, lower-roman) '. ';
    }
    ol li.ql-indent-5 {
      counter-reset: list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-6 {
      counter-increment: list-6;
    }
    ol li.ql-indent-6:before {
      content: counter(list-6, decimal) '. ';
    }
    ol li.ql-indent-6 {
      counter-reset: list-7 list-8 list-9;
    }
    ol li.ql-indent-7 {
      counter-increment: list-7;
    }
    ol li.ql-indent-7:before {
      content: counter(list-7, lower-alpha) '. ';
    }
    ol li.ql-indent-7 {
      counter-reset: list-8 list-9;
    }
    ol li.ql-indent-8 {
      counter-increment: list-8;
    }
    ol li.ql-indent-8:before {
      content: counter(list-8, lower-roman) '. ';
    }
    ol li.ql-indent-8 {
      counter-reset: list-9;
    }
    ol li.ql-indent-9 {
      counter-increment: list-9;
    }
    ol li.ql-indent-9:before {
      content: counter(list-9, decimal) '. ';
    }

    // this are the indents used at nested level
    .ql-indent-1 {
      margin-left: 3em;
    }
    .ql-indent-2 {
      margin-left: 6em;
    }
    .ql-indent-3 {
      margin-left: 9em;
    }
    .ql-indent-4 {
      margin-left: 12em;
    }
    .ql-indent-5 {
      margin-left: 15em;
    }
    .ql-indent-6 {
      margin-left: 18em;
    }
    .ql-indent-7 {
      margin-left: 21em;
    }
    .ql-indent-8 {
      margin-left: 24em;
    }
    .ql-indent-9 {
      margin-left: 27em;
    }
  }
}
