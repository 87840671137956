.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  &.withoutSearchHeader {
    justify-content: flex-end;
  }
  &.withoutHeader {
    display: none;
  }
  .titleWithNoData {
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: #32325d;
  }
}

.tableSearch {
  font-size: 16px;
  .searchInput {
    width: 160px;
  }
}

.tableHeaderSection {
  cursor: pointer;
}

.loading {
  > div:first-child:not(:global(.react-bootstrap-table)) {
    height: calc(100% - 60px) !important;
  }
}

:global {
  .react-bootstrap-table-pagination-list {
    margin-top: 20px;
  }
}
