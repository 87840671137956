@import 'assets/scss/custom/_variables.scss';

.greeting {
  position: relative;
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    h1 {
      font-size: $font-size-base * 2;
    }
    p {
      font-size: $font-size-lg;
    }
    > * {
      color: $white;
    }
  }
}

.card {
  margin-left: 2rem;
  margin-right: 2rem;
}

.noDataWrapper {
  min-height: calc(100vh - 32rem);
  .image {
    max-width: 300px !important;
  }
}
