.wrapper {
  .modalBody {
    padding: 24px 28px 40px 28px;
    justify-content: center;
    .header {
      h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #32325d;
      }
      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #525f7f;
      }
    }
    .yearOption {
      margin-bottom: 32px;
      .buttonGroup {
        gap: 24px;
        :global {
          .active {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff !important;
            background: #525f7f !important;
            box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
            border-radius: 4px;
          }
        }

        .selectButton {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #525f7f;
          border: 1px solid #525f7f;
          filter: drop-shadow(0px 3px 2px rgba(233, 236, 239, 0.05));
          border-radius: 4px;

          &:hover {
            color: inherit;
            background-color: unset;
            border-color: unset;
          }
        }
      }
    }
    .additionalComments {
      .customLabel {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #525f7f;
      }
    }
  }
  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 5px 24px 11px 24px;
    .back {
      box-shadow: none !important;
      background: transparent;
      &:active {
        background-color: transparent;
      }
      &:hover {
        transform: none;
      }

      span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #4f4f4f;
        i {
          top: 0;
        }
      }
    }
    .submitActions {
      .save {
        border: 1px solid #d2e3ee;
        background: transparent;
        box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.5);
        border-radius: 4px;
      }
      .submit {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}
