.comment {
  margin-top: 24px;
  padding-left: 24px;
  margin-bottom: 24px;
  &:hover {
    .commentsDropdown {
      display: block;
    }
  }

  .mediaWrapper {
    .avatar {
      height: 25px;
      width: 25px;
    }

    .media {
      .content {
        position: relative;
        padding: 2px 16px 0 24px;

        .text {
          p:first-of-type {
            &::before {
              content: var(--before-content);
              font-weight: 700;
              color: #322d52;
            }
          }
          p {
            font-size: 14px;
            font-weight: 400;
          }
        }

        .commentHeading {
          align-items: baseline;

          :global {
            .dropdown {
              position: absolute;
              top: 0;
              right: 0;
            }
          }
          .commentAuthor {
            display: flex;
            min-width: 200px;
            max-width: 200px;
            white-space: nowrap;

            h6 {
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: auto;
            }
            .date {
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              color: #a2a0b4;
              margin-bottom: 4px;
            }
            span {
              font-size: 13px;
              width: auto;
            }
          }
        }
      }
    }
  }
}

.commentsDropdown {
  display: none;
}

.tooltipCustom {
  box-shadow: none !important;
  :global {
    .tooltip-inner {
      background-color: #fff;
      opacity: 1;
      padding: 15px;
      filter: none !important;
      border: 1px solid #e9ecef;
      margin: -10px 0px 0px 20px;
    }

    .tooltip {
      opacity: 1 !important;
    }
  }
  .tooltipContent {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    .avatar {
      height: 25px;
      width: 25px;
    }
    .info {
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: #a2a0b4;
        margin-bottom: 0px;
        color: #000000;
      }
    }
  }
}
