.card {
  margin-bottom: 0px;
  box-shadow: none;
  border: 1px solid #8898aa;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  .cardBody {
    padding: 16px 10px 16px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 15px;
    .infoWrapper {
      width: 100%;
      .info {
        display: grid;
        align-items: center;
        grid-template-columns: 2fr 1fr;
        gap: 26px;
        .title {
          p {
            margin-bottom: 0;
          }
        }
        .wrapper {
          display: grid;
          grid-template-columns: 2fr 0.8fr 10px;
          gap: 20px;
          align-items: center;
          &.hideButton {
            grid-template-columns: 1fr;
            width: 108px;
          }
          .statusWrapper {
            min-width: 200px;
            .label {
              text-transform: uppercase;
              margin-bottom: 6px;
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 11px;
              line-height: 15px;
              color: #32325d;
            }
            .status {
              font-family: 'Open Sans';
              text-transform: capitalize;
              margin-bottom: 0;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              color: #32325d;

              &.new_doc {
                color: #32325d;
              }
              &.docs_submited {
                color: #037fbe;
              }

              &.not_started {
                color: #32325d;
              }

              &.in_review {
                color: #e9b411;
              }

              &.needs_attention {
                color: #ec0c38;
                text-decoration: underline;
              }
              &.ready_for_review {
                color: #1da1f2;
              }
              &.completed {
                color: #24a46d;
              }
              &.ready_for_review {
                color: #04a0f0;
              }
            }
          }
          .action {
            display: flex;
            flex-direction: column;

            gap: 4px;
            .addButton {
              margin: 0;
              min-width: 110px;
              padding: 8px 5px;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
            }
            .viewDocButton {
              margin: 0;
              min-width: 110px;
              padding: 8px 5px;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
