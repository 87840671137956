.container {
  margin-top: 27px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    margin-bottom: 18px;
    .contractTypeSwitch {
      span {
        margin-right: 8px;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #000;
      }
      .buttonGroup {
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
        button {
          border-radius: 4px;
          background: #fff;
          color: #a2a0b4;
          &.active {
            background: #0ca8eb;
            span {
              color: #fff;
              font-weight: 600;
            }
          }
        }
      }
    }
    p {
      font-weight: 700;
      font-size: 26px;
      line-height: 35px;
      color: #32325d;
    }
  }
  .formWrapper {
    max-width: 800px;
    .root {
      .card {
        .cardBody {
          .row {
            h5 {
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              color: #525f7f;
              .light {
                font-weight: 400;
              }
            }
            .inputWrapper {
              display: flex;
              width: 100%;
              justify-content: space-between;
              gap: 27px;

              .checkBoxWrapper {
                height: 44px;
                gap: 9px;
                p {
                  margin-bottom: 0;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19px;
                  color: #525f7f;
                }
              }
            }
            .summaryEditor {
              :global {
                .ql-container {
                  min-height: 97px;
                  resize: vertical;
                  overflow-y: auto;
                }
                .ql-editor {
                  max-height: max-content;
                  height: 100% !important;
                  color: #32325d;
                  &::-webkit-scrollbar {
                    display: none;
                  }
                  span {
                    color: #32325d !important;
                  }
                  em {
                    color: #32325d !important;
                  }
                  b {
                    color: #32325d !important;
                  }
                  i {
                    color: #32325d !important;
                  }
                  strong {
                    color: #32325d !important;
                  }
                  ul {
                    li {
                      span {
                        color: #32325d !important;
                      }
                      strong {
                        color: #32325d !important;
                      }
                    }
                  }
                  p {
                    color: #32325d !important;
                    span {
                      color: #32325d !important;
                    }
                    strong {
                      color: #32325d !important;
                    }
                  }
                }
              }
            }
            .fullContractEditor {
              :global {
                .ql-container {
                  min-height: 424px;
                  resize: vertical;
                  overflow-y: auto;
                }
                .ql-editor {
                  height: 100% !important;
                  max-height: max-content;
                  color: #32325d !important;
                  &::-webkit-scrollbar {
                    display: none;
                  }
                  span {
                    color: #32325d !important;
                  }
                  em {
                    color: #32325d !important;
                  }
                  b {
                    color: #32325d !important;
                  }
                  i {
                    color: #32325d !important;
                  }
                  ul {
                    li {
                      span {
                        color: #32325d !important;
                      }
                      strong {
                        color: #32325d !important;
                      }
                    }
                  }
                  p {
                    color: #32325d !important;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 0;
                    span {
                      color: #32325d !important;
                    }
                    strong {
                      color: #32325d !important;
                      font-size: 18px;
                      line-height: 25px;
                      font-weight: 700;
                    }
                  }
                }
              }
            }

            .required::after {
              content: '*';
              margin-left: 0.5em;
              color: #ff0000;
            }
          }
        }
        .cardFooter {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: none;
          .rightBtn {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .previewButton:disabled {
              border-color: inherit;
            }
          }
        }
      }
    }
  }
}

.previewModal {
  max-width: 977px;
  .card {
    overflow: auto;
    margin-bottom: 0px;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(128, 128, 128, 0.3);
      border-radius: 60px;
    }
    .cardBody {
      padding: 50px 36px 36px 36px;
      min-height: auto;
      blockquote {
        font-size: 24px;
      }

      .content {
        color: #32325d !important;
        margin-bottom: 24px;
        span {
          color: #32325d !important;
        }
        em {
          color: #32325d !important;
        }
        b {
          color: #32325d !important;
        }
        i {
          color: #32325d !important;
        }
        ul {
          li {
            span {
              color: #32325d !important;
            }
            strong {
              color: #32325d !important;
            }
          }
        }
        p {
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 0;
          color: #32325d !important;
          strong {
            color: #32325d !important;
            font-size: 18px;
            line-height: 25px;
            font-weight: 700;
          }
        }
      }

      .agreement {
        color: #32325d !important;
        overflow: hidden;
        position: relative;
        padding: 35px 32px;
        background: #f5f5f5;
        border: 1px solid #dfddea;
        box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        em {
          color: #32325d !important;
        }
        b {
          color: #32325d !important;
        }
        i {
          color: #32325d !important;
        }
        ul {
          li {
            span {
              color: #32325d !important;
            }
            strong {
              color: #32325d !important;
            }
          }
        }
        p {
          color: #32325d !important;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0;
          span {
            color: #32325d !important;
          }
          strong {
            color: #32325d !important;
            font-size: 18px;
            line-height: 25px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
