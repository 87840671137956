.wrapper {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .title {
      cursor: pointer;
      display: flex;
      gap: 8px;
      align-items: center;
      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: #32325d;
        margin-bottom: 0;
      }
      i {
        color: #32325d;
      }
    }
    .tasks {
      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #32325d;
        margin-bottom: 0;
      }
    }
  }
  .cards {
    .documentCard {
      margin-bottom: 12px;
      border-radius: 6px;
      box-shadow: 0px 15px 35px rgba(50, 50, 93, 0.2),
        0px 5px 5px rgba(0, 0, 0, 0.17);
    }
  }
}
