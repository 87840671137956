.previewModal {
  max-width: 977px;
  .card {
    overflow: auto;
    margin-bottom: 0px;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(128, 128, 128, 0.3);
      border-radius: 60px;
    }
    .cardBody {
      padding: 50px 36px 36px 36px;
      min-height: auto;
      blockquote {
        font-size: 24px;
      }

      .content {
        color: #32325d !important;
        margin-bottom: 24px;
        em {
          color: #32325d !important;
        }
        b {
          color: #32325d !important;
        }
        i {
          color: #32325d !important;
        }
        ul {
          li {
            span {
              color: #32325d !important;
            }
            strong {
              color: #32325d !important;
            }
          }
        }
        p {
          font-size: 18px;
          margin-bottom: 0;
        }
      }

      .agreement {
        color: #32325d !important;
        overflow: hidden;
        position: relative;
        padding: 35px 32px;
        box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        b {
          color: #32325d !important;
        }
        i {
          color: #32325d !important;
        }
        em {
          color: #32325d !important;
        }
        ul {
          li {
            span {
              color: #32325d !important;
            }
            strong {
              color: #32325d !important;
            }
          }
        }
        p {
          color: #32325d !important;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 0;
          em {
            color: #32325d !important;
          }
          span {
            color: #32325d !important;
          }
          strong {
            color: #32325d !important;
            font-size: 18px;
            line-height: 25px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
