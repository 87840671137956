@import 'assets/scss/custom/_variables.scss';

.projects {
  .project-name {
    font-weight: 600;
    height: 2rem;
  }
  .confirmedCheck {
    color: #4fd69c;
  }
}
