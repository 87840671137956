@import 'assets/scss/custom/_variables.scss';

.AssignmentItem {
  background-color: $white;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 8px;
  padding-left: 14px;

  border-left: 3px solid #afb8bf;
  position: relative;
  display: block;

  .noPointer {
    cursor: initial !important;
  }

  &.active {
    border-left-color: $green;
  }

  .noAssignmentType {
    margin-left: -2rem;
  }

  .addTypeButton {
    position: sticky;
    padding-top: 0.5rem;
    bottom: 0;
    order: 1;
  }

  .start {
    width: 19px;
    height: 19px;
    position: absolute;
    top: 0px;
    left: -11px;
    background: #afb8bf;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 11px;
      color: $white;
    }

    &.active {
      background: $green !important;
    }
  }

  .end {
    width: 19px;
    height: 19px;
    position: absolute;
    bottom: 0px;
    left: -11px;
    background: #afb8bf;
    border-radius: 50%;

    &.active {
      background: $green;
    }
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    color: unquote($black + '80');
    margin-bottom: 15px;
  }

  .addDependency {
    color: $gray-900;
    background: unquote($gray-900 + '33');
    font-size: 9.24px;
    cursor: pointer;
    margin-left: 10px;
    text-transform: none;

    .closeIcon {
      display: none;
    }

    &.active {
      color: $green;
      background-color: unquote($green + '33');
      cursor: initial;

      &:hover {
        .closeIcon {
          display: inline-block;
          cursor: pointer;
        }
      }
    }

    &.locked {
      color: $red;
      background-color: unquote($red + '33');
      cursor: initial;

      &:hover {
        .closeIcon {
          display: inline-block;
          cursor: pointer;
        }
      }
    }
  }

  .body {
    :global {
      .card {
        background-color: $gray-100;
        box-shadow: none;
        margin-bottom: 15px;

        .card-body {
          padding: 15px 15px 15px 30px;
        }
      }
    }

    .dragIcon {
      color: $gray-400;
      font-size: 14px;
      cursor: pointer;
      margin-left: -19px;
      margin-right: 9px;
      transform: translateY(7px);
    }

    .assignmentType {
      font-size: 20px;
      color: $gray-800;
      font-weight: normal;
      position: relative;
      cursor: pointer;
      display: inline-block;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
      }

      :global .fa-sort {
        vertical-align: middle;
        font-size: $font-size-base;
      }
    }

    .assignmentOwner {
      .avatar {
        cursor: pointer;

        img {
          width: 29px;
          height: 29px;
          border-radius: 50%;
        }

        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 10px;
          width: calc(100% - 40px);

          &.label {
            color: $gray-800;
          }
        }
      }

      .empty {
        color: $gray-500;
      }
    }

    .assignmentDueDate {
      > span {
        cursor: pointer;
      }

      .pointer {
        cursor: pointer;
        color: $gray-800;
      }

      .dropdownCalendar {
        position: absolute;
        top: 80px;

        &.right {
          right: 0px;
        }

        &.left {
          left: 0px;
        }

        .calendar {
          z-index: $zindex-dropdown;
          border: $input-border-color;
          box-shadow: $dropdown-box-shadow;
          border-radius: $dropdown-border-radius;
        }
      }
    }

    .title {
      margin-top: 16px;
      font-size: 16px;
      color: $gray-700;
      letter-spacing: -0.3px;
      margin-bottom: 10px;

      + span {
        color: $gray-500;
      }
    }

    .subTitle {
      font-size: 13px;
      color: $gray-700;
      letter-spacing: -0.3px;
      margin-bottom: 10px;
    }

    .budgetHours {
      margin-bottom: 10px;

      :global {
        .pagination {
          flex-wrap: wrap;

          li {
            margin-bottom: 5px;
          }

          ul {
            margin-bottom: 0;
          }

          .page-item .page-link {
            margin: 0 3px !important;
          }
        }
      }
    }

    .checkList {
      margin-top: 15px;

      button {
        &:disabled {
          color: $gray-400;
          background-color: transparent;
          border: 1px solid $gray-400;
        }
      }

      .CheckListItemContainer {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        padding-top: 5px;
        padding-bottom: 5px;

        .dragIconCheckList {
          color: $gray-400;
          font-size: 14px;
          margin-right: 10px;
          cursor: pointer;
          transform: translateY(5px);
        }

        .description {
          cursor: pointer;
        }

        .deleteIconCheckList {
          color: $gray-800;
          font-size: 14px;
          margin-left: 10px;
          cursor: pointer;
        }

        &:hover {
          background: #e7ebf0;
          border-radius: $border-radius;
          margin-left: -10px;
          padding-left: 10px;
          padding-right: 10px;
        }

        &.noHover {
          background: none !important;
          border-radius: none !important;
        }
      }
    }

    .deliverables {
      margin-top: -10px;
      margin-bottom: 15px;

      .deleteIcon {
        cursor: pointer;
      }

      ul {
        list-style: none;
        padding-left: 0px;

        li {
          display: flex;
          align-items: baseline;
          a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
          }
        }
      }

      button {
        &:disabled {
          color: $gray-400;
          background-color: transparent;
          border: 1px solid $gray-400;
        }
      }
    }
  }

  .footer {
    font-size: 12px;
    color: unquote($black + '80');
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.completed {
      transform: translateY(12px);
    }

    &.readyComplete {
      transform: translateY(5px);
    }

    &.readyRestore {
      transform: translateY(5px);
    }

    button {
      padding: 5px;
    }

    span {
      padding: 0;
    }
  }
}

.CheckListItemDragging {
  .CheckListItemContainer {
    background: #e7ebf0;
    padding-left: 10px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    border-radius: $border-radius;
    padding-top: 5px;
    padding-bottom: 5px;

    .dragIconCheckList {
      color: $gray-400;
      font-size: 14px;
      margin-right: 10px;
      cursor: pointer;
      transform: translateY(5px);
    }

    .description {
      cursor: pointer;
    }

    .deleteIconCheckList {
      color: $gray-800;
      font-size: 14px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.AssignmentItemDragging {
  .AssignmentItem {
    background: none;
    border: none;

    :global {
      .card {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      }
    }

    .start,
    .end,
    .head,
    .footer {
      display: none;
    }
  }
}

.dependencyItem {
  white-space: nowrap;
  overflow: hidden;

  .dependencyName {
    color: $black;
  }

  .dependencyUser {
    color: $gray-600;
  }

  &:hover {
    .dependencyName {
      color: $white;
    }

    .dependencyUser {
      color: $white;
    }
  }
}

:global {
  .rselect__option--is-focused {
    :local {
      .dependencyItem {
        .dependencyName {
          color: $white;
        }

        .dependencyUser {
          color: $white;
        }
      }
    }
  }
}

.popoverLocked {
  font-size: 14px;
  text-align: left;
  padding: 15px 10px;
  min-width: 280px;
}
