@import 'assets/scss/custom/_variables.scss';

$administratorColor: $blue-6;
$financialCollaboratorColor: $lime-7;
$masterCollaboratorColor: $orange-7;
$basicTeamMemberColor: $blue-6;
$trustedTeamMemberColor: $orange-7;
$projectManagerColor: $moonstone;
$companyAdministratorColor: $lime-7;
$partnerAdministratorColor: $red-1;
$partnerManagerColor: $yellow;

:global {
  .textXxs {
    font-size: 50%;
  }
  .textXs {
    font-size: 75%;
  }
  .textLg {
    font-size: 125%;
  }
  .textXl {
    font-size: 150%;
  }
}

@mixin generateTabColor($base-color) {
  color: $base-color !important;
  &.active {
    background-color: rgba($color: $base-color, $alpha: 0.1) !important;
  }
}

@mixin generatePaneColor($base-color) {
  background-color: rgba($color: $base-color, $alpha: 0.03) !important;
}

@mixin generateColor($base-color) {
  &.tab {
    @include generateTabColor($base-color);
  }
  &.rolesPermissionList {
    @include generatePaneColor($base-color);
  }
}
