@import 'assets/scss/custom/_variables.scss';
.modalWrapper {
  max-width: 952px;
  height: 500px;
  .totalWrapper {
    display: flex;
    gap: 50px;
    h4 {
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #32325d;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.02em;
      color: #32325d;
    }
  }
  .disbursementTable {
    :global {
      .card {
        box-shadow: none;
      }
    }
  }
  table {
    * {
      td {
        vertical-align: middle;
        padding-right: 0.5rem;
      }
    }

    thead {
      tr {
        th {
          background-color: #fff;
          font-weight: 700;
          font-size: 12px;
          line-height: 12px;
          color: #000000;
          text-transform: none;
          border-top: none;
        }
      }
    }
    tbody {
      tr {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #525f7f;
      }
    }
  }
}
