@import 'assets/scss/custom/_variables.scss';

.root {
  :global {
    .custom-control-label {
      height: auto !important;
    }
  }
  .card {
    background-color: $login-card-bg-color;
    margin-bottom: 0;
    border-radius: $login-card-border-radius;
  }
}

.brandImage {
  width: 50%;
  min-width: 200px;
}
