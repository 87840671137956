.modalWrapper {
  max-width: 732px;

  .modalHeader {
    padding: 29px 28px;
    h5 {
      max-width: 500px;
      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 0;
        strong {
          font-weight: 700;
        }
      }
    }
    button {
      span {
        font-size: 34px;
      }
    }
  }
  .title {
    background: #f6f9fc;
    height: 37px;
    display: flex;
    align-items: center;
    p {
      margin-left: 28px;
      color: #32325d;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0;
    }
  }
  .icon {
    position: absolute;
    font-size: 20px;
    top: 30%;
    right: 4%;
    color: #a2a0b4;
  }
  .modalBody {
    min-height: 375px;
    max-height: 700px;
    .formGroup {
      width: 100%;
    }
    .inputWrapper {
      .text {
        max-width: 527px;
        color: #322d52;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .textareaLabel {
        color: #525f7f;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.24px;
      }
      .label {
        margin-bottom: 8px;
        color: #322d52;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 19px;
        &::after {
          content: '*';
          margin-left: 0.5em;
          color: white;
        }
      }
      .option {
        margin-bottom: 0px !important;
        color: #322d52;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: inline-flex;
        gap: 3px;
        margin-right: 16px;
      }
    }
  }
  .modalFooter {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 27px;
    .back {
      color: #322d52;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0;
    }
    .submit {
      color: #fff;
      text-align: center;
      font-family: Open Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 7px 15px;
      &:disabled {
        cursor: not-allowed;
        background: #d4d4d4;
        border-color: #d4d4d4;
      }
    }
  }
}
