@import 'assets/scss/custom/_variables.scss';
.wrapper {
  .container {
    max-width: 100%;
    width: 100%;
    padding: 32px 24px 0 24px;
    display: grid;
    grid-template-columns: 8fr 5fr;
    gap: 32px;
    @media (max-width: 1200px) {
      grid-template-columns: 8fr 6fr;
    }
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

  .widgets {
    position: sticky;
    top: 100px;
    align-self: start;
    overflow-x: auto;
    @media (max-width: 991px) {
      position: relative;
      top: 0;
    }
  }
}
