.modalWrapper {
  max-width: 732px;

  .modalHeader {
    padding: 29px 28px;
    h5 {
      max-width: 500px;
      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 0;
        strong {
          font-weight: 700;
        }
      }
    }
    button {
      span {
        font-size: 34px;
      }
    }
  }
  .title {
    background: #f6f9fc;
    height: 37px;
    display: flex;
    align-items: center;
    p {
      margin-left: 28px;
      color: #32325d;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0;
    }
  }
  .modalBody {
    min-height: 375px;
    .formGroup {
      width: 100%;
    }
    .studyCard {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 12px;
      padding: 13px 12px 13px 12px;
      border: 1px solid #1da1f2;
      border-radius: 4px;
    }
    .studyName {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 0px;
    }
    .inputWrapper {
      margin-bottom: 16px;
      .input {
        max-width: 253px;
        width: 6px;
        height: 6px;
      }

      .label {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
      }
      .option {
        margin-bottom: 0px !important;
        color: #322d52;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: inline-flex;
        gap: 3px;
        margin-right: 16px;
      }
    }
  }
  .modalFooter {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 27px;
    .back {
      color: #322d52;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0;
    }
    .submit {
      color: #fff;
      text-align: center;
      font-family: Open Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 7px 15px;
      &:disabled {
        cursor: not-allowed;
        background: #d4d4d4;
        border-color: #d4d4d4;
      }
    }
  }
}
