@import 'assets/scss/custom/_variables.scss';

.storyModal {
  .modalHeader {
    width: 100%;
    padding: 1.25rem;
    border-top-left-radius: 0.4375rem;
    border-top-right-radius: 0.4375rem;
    .headerWrapper {
      .header {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .content {
          display: flex;
          align-items: center;
          gap: 16px;

          .cursor {
            cursor: pointer;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 17px;
            line-height: 23px;

            color: #32335a;
          }
          .tags {
            display: flex;
            gap: 8px;
            align-items: center;
            div {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              display: flex;
              text-transform: uppercase;
              border-radius: 16px;
              padding: 3px 9px;
            }

            .processed {
              color: #1aae6f;
              background: #b0eed3;
            }
            .failed {
              color: #f80031;
              background: #fdd1da;
            }
          }
        }
      }
      .fileTypesWrapper {
        background-color: #f8f9fa;
        padding: 5px;
        border-radius: 8px;
        .fileTypes {
          &.disable {
            pointer-events: none;
          }
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 5px;
          div {
            cursor: pointer;
            text-align: center;
            padding: 6px;
            background: transparent;

            border-radius: 8px;
            &.active {
              box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08),
                0px 1px 2px rgba(50, 50, 93, 0.1);
              background: #ffffff;
            }
          }
        }
      }
    }
  }

  .fileName {
    color: #fff;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .downloadIcon {
    cursor: pointer;
  }

  .modalBodyWrapper {
    position: relative;
    padding: 0;

    .hoverBar {
      //position: absolute;
      width: 100%;
      z-index: 999;
      background: #504b70;
      opacity: 0.7;
      padding: 14px 36px 14px 25px;
      //top: -70px;
      color: white;
      display: flex;
      justify-content: space-between;
      transition: top 1s;
      @media (max-width: 468px) {
        align-items: flex-start;
      }
      .downloadButton {
        width: 150px;
        justify-content: end;
      }
    }

    .pdfPreview {
      max-height: calc(100vh - 290px);
      overflow: auto;
      text-align: center;
    }

    //&:hover > .hoverBar {
    //  top: 0;
    //}
  }
  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
      margin-bottom: 0;
    }
    .active {
      a {
        background-color: #5e72e4;
        color: #fff;
      }
    }
  }
}
