@import 'assets/scss/custom/_variables.scss';
.modalSuccess {
  .successState {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #32325d;

    img {
      display: block;
      margin-bottom: 20px;
      width: 50px;
      height: 50px;
    }
  }
}
