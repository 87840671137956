@import 'assets/scss/custom/_variables.scss';

.loadingContainer {
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  z-index: 9999;
}

.container {
  margin-top: -17rem;
  .card {
    border-radius: $login-card-border-radius;
    background-color: $login-card-bg-color;
  }
  .socialLoading {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
  }
  .alert {
    :global {
      .alert {
        align-items: flex-start;
        text-align: left;
        margin-right: 15px;
        padding: 1rem;
        button {
          top: 50%;
          right: 0.5rem;
          span {
            font-size: 1.5rem;
          }
        }
      }

      .alert-inner--text {
        text-align: left !important;
      }
    }
  }
  .forgotPasswordLink {
    color: #504b70;
    :hover {
      color: $primary;
    }
  }
}
