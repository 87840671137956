@import 'assets/scss/custom/_variables.scss';

.root {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  .toggleFilter {
    text-decoration: underline;
    i {
      margin-left: 5px;
    }
  }

  .noTimeLogImg {
    width: 35%;
    max-width: 450px;
  }

  .chartContainer {
    :global {
      .row.equal-height {
        & > * {
          margin-bottom: 30px;
        }

        .card {
          height: 100%;
          // max-height: 700px;

          .row.equal-height,
          .col-md-6.equal-height {
            height: 100%;
            max-height: 300px;
          }

          canvas {
            max-height: 300px;
          }
        }

        .card.card-stats {
          box-shadow: none;
          margin-bottom: 0px;
          width: 100%;
        }
      }

      .doughnutChart {
        canvas {
          @media (max-width: 450.98px) {
            max-width: 400px;
            max-height: 400px;
          }
        }
      }

      .bg-icon {
        background: linear-gradient(90deg, #f5365c 4.99%, #f56036 96.48%);
        border: 1px solid #e9ecef;
      }
    }
  }

  :global {
    .bootstrap-tagsinput {
      background: none;
      padding: 0px;
    }

    .bootstrap-tagsinput input {
      display: none;
    }

    .bootstrap-tagsinput .badge {
      white-space: normal;
      text-align: left;
      a {
        visibility: hidden;
        right: 10px;
      }

      &:hover {
        a {
          visibility: visible;
        }
      }
    }
  }

  .dataContainer {
    margin-bottom: 30px;
  }
}
