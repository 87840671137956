@import 'assets/scss/custom/_variables.scss';

.container {
  margin-top: -15rem;
  max-width: 950px;
  .acceptAndSetup {
    background: $login-card-bg-color;
    border-radius: $login-card-border-radius;
  }
}

.email {
  label {
    color: #172b4d;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  p {
    color: #000;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
}
.inputGroup {
  label {
    color: #172b4d;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.footerMain {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.rootStyles {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.mainContent {
  flex: 1;
}
.terms {
  padding-left: 24px;
  padding-right: 24px;
  p {
    color: #0e0931;
    text-align: center;
    /* Regular / 12px | 20px */
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.tooltipCustom {
  min-width: auto !important;
  :global {
    .tooltip-inner {
      min-width: auto !important;
    }
    .tooltip {
      top: -15px !important;
      left: 30px !important;
      opacity: 1;
    }
  }

  .arrow {
    &::before {
      border-width: 1rem 1rem 0 !important;
      left: -40px !important;
    }
  }
  .content {
    p {
      color: #000;
      font-family: Open Sans;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 16.5px */
      margin-bottom: 0;
      text-align: left;
    }
    ul {
      text-align: left;
      list-style-position: inside;
      padding-left: 10px;
      margin-bottom: 0;
      li {
        color: #000;
        font-family: Open Sans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 16.5px */
        margin-bottom: 0;
        &::marker {
        }
      }
    }
  }
}
