@import 'assets/scss/custom/_variables.scss';

.profileLoader {
  height: calc(100vh - 150px) !important;
}
.popoverClass {
  margin: 0.1rem 0;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
}
.clientProfile {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: sticky;
    top: 77px;
    z-index: 1;
    background: #f5f7fc;
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .noDataWrapper {
    height: calc(100vh - 380px);
  }

  .imageWrapper {
    display: flex;
    width: 150px;
    height: 150px;
    background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
    border-radius: 50%;
    margin-bottom: 20px;
  }
  .defaultImage {
    max-width: 45px;
  }
  .defaultText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #cdcdcd;
  }
  .desktopNavigation {
    display: flex;
    margin-bottom: 24px;
    margin-right: 18px;

    @media (max-width: 992px) {
      margin-left: 15px;
      margin-right: 0;
    }
  }
  .name {
    font-weight: 600;
  }
  .options {
    min-width: 150px;
  }
  .textField {
    cursor: default !important;
    vertical-align: middle !important;
    @media (max-width: 768px) {
      max-width: 40vw;
    }
    span,
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: fit-content;
    }
  }
  .agreementsCardBody {
    padding: 25px;
    margin: 0;
    .engagementsTitles {
      font-size: 14px;
      font-weight: bold;
      margin-left: 8px;
    }
  }
  .actions {
    width: 33.33%;

    i {
      cursor: pointer;
    }
  }
  .grey {
    color: $gray-400;
  }
  .addAgreement {
    color: $primary;
    cursor: pointer;
  }
  .image {
    max-width: 320px;
  }
  .cards {
    max-height: 600px;
  }
}
.customIconWrapper {
  width: 88px;
  height: 88px;
  margin: 20px auto;
}

.timeSpentChart {
  min-height: 35rem;
  display: flex;
}

.recentTimeEntries {
  .cardBody {
    &.scroll {
      max-height: 37.5rem;
      overflow-y: auto;
    }
    .table {
      overflow: auto;
      table-layout: fixed;
      .row {
        @media (max-width: 480px) {
          // using vw here so that we can show the details to user
          width: 130vw;
        }
        .userColumn {
          .initiative {
            text-overflow: ellipsis;
          }
        }
        .storyColumn {
          max-width: 300px;
          white-space: normal;
        }
      }
    }
  }
}

.noTimeLogImg {
  max-width: 300px;
}

.recentActivities {
  max-height: 300px;
  overflow: auto;
  padding: 1.5rem 3rem;
}

.ClientTeams {
  .ClientTeamsHeader {
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: #32325d;
  }
  .cardBody {
    max-height: 470px;
    overflow: auto;
    &.scroll {
    }
    .tableWrapper {
      min-height: 10.5rem;
      overflow-x: inherit;
      table {
        * {
          td {
            vertical-align: middle;
            padding-right: 0.5rem;
          }
        }
        tbody {
          tr {
            td:first-child {
              border-left: 5px solid $lyght-blue;
            }
          }
        }
        .row {
          cursor: pointer;
        }
        .storyName {
          width: fit-content;
          max-width: 100%;
        }
        .nameField {
          color: #525f7f;
          font-family: 'Open Sans';
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }
        .projectName {
          span,
          a {
            font-weight: $font-weight-bold;
            display: block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: unset;
          }
        }
        .roleField {
          padding: 2px 8px;
          border-radius: 11px;
          font-family: 'Open Sans';
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }
        .roleFieldLow {
          color: #1aae6f;
          background-color: #b0eed3;
        }
        .roleFieldHigh {
          color: #f80031;
          background-color: #fdd1da;
        }
        .roleFieldMedium {
          color: #fb6340;
          background-color: #ffe3b8;
        }
        .priorityHeader {
          width: 6.5rem;
        }
        .epicBadge {
          border-radius: 10rem !important;
          max-width: 100% !important;
          transition: all 0.3s;
          cursor: default;
          .closeIcon {
            display: none;
          }
        }
        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .hoverHand {
          &:hover {
            cursor: pointer;
          }
        }
        .epicName {
          overflow: hidden !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          color: $gray-700;
          overflow-wrap: break-word;
          word-break: break-word;
          word-wrap: break-word;
          hyphens: auto;

          h5 {
            color: $gray-700;
          }
        }
      }
    }
  }

  .InviteTeamMemberButton {
    width: auto;
  }

  .noTeamMemberWrapper {
    min-height: 232px;
  }

  .teamMemberImageWrapper {
    display: flex;
    width: 100px;
    height: 100px;
    background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
    border-radius: 50%;
    margin-bottom: 20px;
  }
}
.alertModalWrapper {
  height: 196px !important;
  width: 423px !important;
  border-radius: 10px !important;
  padding: 32px !important;
  .deleteAlertSection {
    display: flex;
    text-align: left;
    .deleteAlertHeader {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #322d52;
    }
    .deleteAlertText {
      margin-top: 12px;
      line-height: 22px;
      color: #504b70;
      font-size: 14px;
      font-weight: 400;
      margin-right: 10px;
    }
    .deleteAlertIcon {
      margin-right: 20px;
      height: 20px;
      width: 20px;
    }
  }
  .cancelBtn {
    width: 80px;
    height: 32px;
    color: #f05353;
    border: 1px solid #f05353;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    padding: 5px 16px 5px 16px;
  }
  .confirmBtn {
    background: #f05353;
    border-radius: 4px;
    border: 0px;
    height: 32px;
    padding: 5px 16px 5px 16px;
    font-weight: 700;
  }
  p:last-child {
    margin-top: 20px !important;
    justify-content: end !important;
    margin-bottom: 12px !important;
  }
}

.modalWrapper {
  position: relative;
  height: 100px;
  width: 400px;
  padding: 16px;
  box-sizing: border-box;
  font-family: 'Nunito Sans';
  color: #504b70;
  .modalBodyWrapper {
    background: #ffe9e9;
    border: 1px solid #f05353;
    border-radius: 6px;
    .closeButton {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
.allDocBtn {
  padding: 5px 10px;
  color: #04a0f0 !important;
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.32px;
  line-height: 16.34px;
  right: 10px;
}
