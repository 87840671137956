.newInteractionsWrapper {
  padding: 10px;
  border: 1px solid #d2e3ee;
  margin-bottom: 16px;
  background-color: #f7fafc;
  .details {
    gap: 15px;
  }
  .dot {
    height: 12px;
    width: 12px;
    background-color: #2dce89;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
  .title {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
  }
  .documentTitle {
    font-weight: 700;
    overflow: hidden;
    color: #32325d;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }
  .doc {
    margin-top: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .msg {
    margin-top: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .readyForReview {
    i {
      font-size: 20px;
      color: #42b07f;
    }
    .date {
      color: #8898aa;
    }
  }
  .imageWrapper {
    img {
      max-width: 100%;
      max-height: 24px;
    }
  }
}
