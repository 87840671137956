@import 'assets/scss/custom/_variables.scss';

.formModal {
  .formHeader {
    border-bottom: 1px solid $gray-200;
  }
}
.deleteButton {
  border: none !important;
  color: $red !important;
  background-color: #fff !important;
  box-shadow: none !important;
  transform: unset !important;
}
