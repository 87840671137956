@import 'assets/scss/custom/_variables.scss';

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $warning !important;
}

.is-invalid {
  border: $warning 1px solid !important;
}
