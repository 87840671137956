@import 'assets/scss/custom/_variables.scss';
.root {
  position: relative;

  .leftIcon {
    position: absolute;
    top: 5px;
    left: 12px;
    z-index: 1;
  }

  &.hasLeftIcon {
    :global {
      .select2-selection--single {
        padding-left: 35px;
      }
    }
  }

  :global {
    .popover {
      box-shadow: $dropdown-box-shadow !important;
      max-width: 16rem;
      min-width: 12rem;
    }
  }

  .largePopover {
    max-width: 22rem !important;
    width: auto;
    .popoverBody {
      max-width: 22rem !important;
      width: auto !important;
    }
    :global {
      .rselect__menu {
        width: auto;
        min-width: 15rem;
      }
    }
  }

  .popover {
    margin: 0;
    margin-top: 5px;
    max-width: 16rem;
    min-width: 12rem;
    z-index: 25;

    .popoverBody {
      max-width: 16rem;
      min-width: 12rem;
      padding: 0.5rem 0.5rem 0 0.5rem;
    }
  }
}

.is-invalid {
  :global {
    .select2-selection {
      border: $warning 1px solid !important;
    }
  }
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $warning !important;
}
