@import 'assets/scss/custom/_variables.scss';

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
  background: $body-bg;
}

.referralDetails {
  margin-top: 32px;
  .cardBody {
    h3 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 23px;
      color: #32325d;
      margin-bottom: 16px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 23px;
        color: #32325d;
        margin-bottom: 0;
      }
      h4 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 23px;
        margin-bottom: 0;
        color: #32325d;
      }
    }
  }
}

.availability {
  background: linear-gradient(90deg, $blue 0%, $medium-purple 100%);
  border-radius: 6px;
}

.taskCompleted {
  background: linear-gradient(
    163.3deg,
    $picton-blue -5.62%,
    $dodger-blue 101.13%
  );
  border-radius: 6px;
}

.section {
  margin-top: 15px;
}

.dropdown {
  background: transparent;
  border: 0;
  margin: 10px !important;
  padding: 8px 15px;
  border-radius: 4px !important;
  &:active,
  &:hover {
    background: transparent !important;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  }
}

.timezone {
  div {
    color: $gray-600;
    font-size: 0.875rem;
    border-color: $gray-300;
  }
}

.avatar {
  width: 140px;
  height: 140px;
  object-fit: cover;
  &:hover + .overlay {
    visibility: visible !important;
  }
}

.cardStats {
  padding: 0;
}

.userStats {
  display: block;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 0.025em;
}

.contactDetails {
  padding: 5px;
  span {
    display: block;
    margin-bottom: 10px;
  }

  i {
    margin-right: 15px;
    font-size: 1.3rem;
  }
}

.locationDetails {
  i {
    font-size: 3px;
    vertical-align: middle;
  }
}

.skills {
  span {
    display: inline-block;
    background: $secondary;
    box-shadow: 0px 1px 2px rgba(68, 68, 68, 0.25);
    border-radius: 4px;
    margin: 0px 7px 7px 0px;
    padding: 10px 11px;

    font-family: Open Sans;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color: $gray-800;
  }
}

.bio {
  text-align: justify;
}

.skillTags {
  border: 1px solid $gray-300;
  border-radius: 0.25rem;
  padding: 1.25rem;
}

.disabled {
  background: $input-muted-bg !important;
  border: 1px solid $input-muted-bg;
}

.uploadBtnRow {
  position: relative;
  input[type='file'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.viewImageUpload {
  display: none;
}

.overlay {
  position: absolute;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  opacity: 0.6;
  background-color: $black;
  visibility: hidden;
  cursor: pointer;
  &:hover {
    visibility: visible !important;
  }
}
.loadingOverlay {
  position: absolute;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
}

.text {
  color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    margin: 0;
  }
}
.recentActivities {
  max-height: 600px;
  overflow: auto;
  padding: 1.5rem 3rem;
}

.manangeEmailPreferences {
  ul {
    list-style-type: none;
    padding: 0px;

    li {
      font-weight: 600;
      font-size: 15px;
      color: #32325d;
      letter-spacing: -0.01em;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.fullHeight {
  min-height: 100vh;
}
