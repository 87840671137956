@import 'assets/scss/custom/_variables.scss';

.card {
  font-weight: bold;
  font-size: 18px;

  .cardHeader {
    font-size: 18px;
    font-weight: bold;
  }

  :global {
    .form-control-label {
      color: $default;
      font-size: 14px;
    }
  }

  .row {
    width: 100%;
    margin: 0 0 16px 0;
    display: flex;
    flex: 1;
    align-items: flex-start;

    :global {
      .rselect__control {
        height: 38.5px !important;
      }
      .form-group {
        margin-bottom: 0;
      }
      textarea {
        width: 100%;
      }
    }

    .textInputRightMargin {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 12px;

      input {
        width: 100%;
      }
      select {
        width: 100%;
      }
    }

    .textInputLeftMargin {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 12px;

      input {
        width: 100%;
      }
      select {
        width: 100%;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;

      .textInputRightMargin {
        margin: 0 0 16px 0 !important;
      }
      .textInputLeftMargin {
        margin: 0 0 16px 0 !important;
      }
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;

    .row {
      align-items: initial !important;
      margin: 0 0 16px 0 !important;
    }
  }
}
