@import 'assets/scss/custom/_variables.scss';

.card {
  .cardHeader {
    font-size: 18px;
    font-weight: bold;
  }

  :global {
    .form-control-label {
      color: $gray-800;
      font-size: 14px;
    }
  }

  .row {
    width: 100%;
    margin: 0;
    display: flex;
    flex: 1;
    align-items: baseline;

    :global {
      textarea {
        width: 100%;
      }
    }

    .textInputRightMargin {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 12px;

      input {
        width: 100%;
      }
      select {
        width: 100%;
      }
    }

    .textInputLeftMargin {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 12px;

      input {
        width: 100%;
      }
      select {
        width: 100%;
      }
    }

    .textInputMiddleMargin {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 12px;
      margin-right: 12px;
      input {
        width: 100%;
      }
      select {
        width: 100%;
      }
    }

    .textInputAddress {
      display: flex;
      flex: 2;
      flex-direction: column;
      margin-right: 12px;
      input {
        width: 100%;
      }
      select {
        width: 100%;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;

      .textInputRightMargin {
        margin-right: 0;
      }
      .textInputLeftMargin {
        margin-left: 0;
      }
      .textInputMiddleMargin {
        margin-right: 0;
        margin-left: 0;
      }
      .textInputAddress {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 992px) {
    flex-direction: column;

    .row {
      align-items: initial;
    }
  }
}
