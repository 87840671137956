@import 'assets/scss/custom/_variables.scss';

.wrapper {
  margin-top: 50px;
  // height: 80vh;
  display: grid;
  z-index: 1000;
  grid-template-columns: 3fr 1fr;
  gap: 40px;
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
  // @media screen and (max-width: 768px) {
  //   margin-top: 100px;
  // }
  // @media screen and (max-width: 480px) {
  //   margin-top: 100px;
  // }
  .summery {
    position: relative;
  }
}

.estimateWrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
  position: fixed;
  top: 32px;
  left: 0px;
  margin: 0 auto;
  z-index: 15;
  right: 0;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    top: 90px;
    z-index: 8;
  }
  @media screen and (max-width: 480px) {
    top: 90px;
  }
  .clientEstimate {
    z-index: 15;
    background: #1b9763;
    box-shadow: 0px 4px 27px rgba(27, 151, 99, 0.51);
    border-radius: 11px;
    padding: 4px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 788px;
    @media screen and (max-width: 768px) {
      font-weight: 700;
      font-size: 22px;
    }
    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: baseline;
      left: 12px;
      right: 12px;
      padding: 8px 16px;
    }
    .norm {
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: #ffffff;
      text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
      }
      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .bold {
      font-weight: 700;
      font-size: 44px;
      line-height: inherit;
      height: 70px;
      color: #ffffff;
      text-shadow: 0px 1px 4px rgba(19, 125, 81, 0.61);
      @media screen and (max-width: 768px) {
        font-weight: 700;
        font-size: 32px;
        height: auto;
      }
      @media screen and (max-width: 480px) {
        font-weight: 700;
        font-size: 22px;
        height: auto;
      }
    }
  }
}
