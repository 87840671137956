@import 'assets/scss/custom/_variables.scss';

.root {
  &.error {
    .label {
      color: $danger;

      button {
        color: $danger;
      }
    }

    .timeContent {
      border-color: $danger !important;
      color: $danger;
      input,
      button {
        color: $danger;
      }
    }
  }

  .label {
    position: relative;
    color: $black;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 14px;

    button {
      color: $black;
      background: none;
      border: none;
    }

    .dropdownCalendar {
      position: absolute;
      top: 25px;

      &.right {
        right: 0px;
      }

      &.left {
        left: 0px;
      }

      .calendar {
        z-index: $zindex-dropdown;
        border: $input-border-color;
        box-shadow: $dropdown-box-shadow;
        border-radius: $dropdown-border-radius;
      }
    }
  }

  .timeContent {
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid $input-border-color;
    box-sizing: border-box;
    border-radius: $input-border-radius;
    margin-bottom: 24px;
    padding: 10px;

    input {
      border: none;
      width: 32px;
      font-size: 25px;
      margin: 0 5px;
      color: $body-color;
      text-align: center;

      @media (max-width: 375.98px) {
        width: 60px;
      }
    }

    button {
      color: $body-color;
      font-size: 14px;
      background: none;
      border: none;
    }
  }
}
