@import 'assets/scss/custom/_variables.scss';

.card {
  box-shadow: 0px 2px 10px rgba(50, 50, 93, 0.08),
    0px 1px 2px rgba(0, 0, 0, 0.1);
  .cardBody {
    padding: 21px 27px 0 27px;
    h3 {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 40px;
      color: #32325d;
    }
    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #32325d;
      margin-bottom: 32px;
    }
    .teamMember {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px;
      margin-bottom: 18px;
      .info {
        display: flex;
        gap: 10px;
        align-items: center;
        img {
          border-radius: 50%;
          height: 30px;
          width: 30px;
        }
        p {
          text-transform: capitalize;
          font-family: 'Open Sans';
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #525f7f;
          margin-bottom: 0;
        }
      }
      .position {
        align-self: center;
        p {
          font-family: 'Open Sans';
          font-weight: 300;
          font-size: 13px;
          line-height: 18px;
          color: #525f7f;
          margin-bottom: 0;
        }
      }
    }
  }
  .cardFooter {
    border-top: none;
    padding: 2px 27px 38px 27px;
    .button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      border: 2px solid #1da1f2;
      filter: drop-shadow(0px 4px 6px rgba(50, 50, 93, 0.11))
        drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));
      i {
        top: 0;
      }
      .notificationBadge {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        border-radius: 50%;
        height: 26px;
        width: 26px;
        background: #eb6363;
        color: #ffffff;
        border: 1px solid #ffffff;
      }
    }
    .bookMeeting {
      text-align: center;
      span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #172b4d;
        a {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #172b4d;
          margin-bottom: 0;
          text-decoration: underline;
        }
      }
      .bookButton {
        p {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #172b4d;
          margin-bottom: 0;
          text-decoration: underline;
        }
        &:active:focus {
          box-shadow: none;
        }
        padding: 0;
      }
    }
  }
}
