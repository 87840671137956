@import 'assets/scss/custom/_variables.scss';

.partners {
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .partnerName {
    vertical-align: middle !important;
    @media (max-width: 992px) {
      :global {
        .card-body {
          overflow-y: scroll;
        }
      }
    }
    a {
      display: block;
      //width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: unset;
    }
  }

  .search {
    display: flex;
    align-items: center;
    gap: 7px;
    color: #525f7f;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .headerButton {
    background: linear-gradient(90deg, #5e72e4 0%, #825ee4 100%);
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    padding: 10px;
    height: 43px;
    width: 185px;
    color: #fff;
    outline: none;
  }
  .noDataWrapper {
    height: calc(100vh - 280px);
  }
  .addClient {
    color: $primary;
    cursor: pointer;
  }
  .image {
    max-width: 550px;
  }
  :global {
    .react-bootstrap-table.table-responsive {
      min-height: 150px;

      // This is to apply ellipsis to the table-cells, if we do not use this it will go beyond the width
      table {
        table-layout: fixed;
      }
      .react-bs-table-no-data {
        white-space: normal;
        text-align: center;
      }

      @media (max-width: 992px) {
        table {
          width: auto;
        }
      }
    }
  }
}

.formLabel {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  .required {
    color: #ff0000;
  }
}
.detailLabel {
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
}
.desc {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-top: 8px;
}
.row {
  width: 100%;
  margin: 0 0 16px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 33px;
  grid-row-gap: 24px;
  .textInputRightMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }
  .middleMargin {
    display: flex;
    height: 100%;
    margin: 9px 8px 0 10px;
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      color: #525f7f;
    }
  }
  .textInputLeftMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }
}
.hoverHand {
  display: flex;
  cursor: pointer;
  padding: 5px 0;
  p {
    margin: 0;
  }
}
