@import 'assets/scss/custom/_variables.scss';

.container {
  display: flex;
  position: relative;
  padding: 10px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #d2e3ee;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(136, 152, 170, 0.15);
  .titleContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title {
      color: #1da1f2;
      font-family: Open Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .value {
      text-transform: uppercase;
      color: #32325d;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .icon {
    position: absolute;
    right: 24px;
    top: 13px;
  }
}
