@import 'assets/scss/custom/_variables.scss';

.root {
  display: flex;
  align-items: center;

  .timer-btn {
    margin-right: 10px;
    cursor: pointer;
    width: 31px;
    height: 31px;

    @media (max-width: 375.98px) {
      width: 20px;
      height: 20px;
    }
  }

  :global {
    @media (max-width: 450.98px) {
      .recent-timelogs {
        display: none;
      }
    }
  }

  .history-btn {
    width: 20px;
    height: 20px;
    //margin-left: 10px;
    cursor: pointer;
  }

  .selectProject {
    // margin-left: 10px;
    min-width: 280px;

    @media (max-width: 767.98px) {
      display: none;
    }
  }

  :global {
    .runningTimer {
      font-size: 22px;

      @media (max-width: 375.98px) {
        font-size: 15px;
      }

      &.stop {
        color: $gray-300;
      }
    }
  }
}
