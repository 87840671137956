@import 'assets/scss/custom/_variables.scss';
.card {
  border-radius: $login-card-border-radius;
  background-color: $login-card-bg-color;

  .alert {
    :global {
      .alert {
        align-items: flex-start;
        text-align: left;
        margin-right: 15px;
        padding: 1rem;
        button {
          top: 50%;
          right: 0.5rem;
          span {
            font-size: 1.5rem;
          }
        }
      }

      .alert-inner--text {
        text-align: left !important;
      }
    }
  }
}
