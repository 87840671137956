@import 'assets/scss/custom/_variables.scss';

.row {
  width: 100%;
  margin: 0;
  display: flex;
  flex: 1;
  align-items: baseline;

  :global {
    textarea {
      width: 100%;
    }
    .form-control-label {
      font-size: 14px;
    }
    .btn:hover {
      color: white !important;
    }
  }

  .email {
    position: relative;
    .icon {
      position: absolute;
      right: 4%;
      top: 22%;
      color: $gray-500;
      font-size: 20px;
      cursor: pointer;
      &:hover {
        color: $lyght-blue;
      }
    }
  }

  .emailAddress {
    font-size: 14px;
    font-weight: 600;
    color: #32325d;
  }

  .button {
    padding: 5px 16px;
    color: #53c3f0;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 16px;
  }

  .radio {
    :global {
      .custom-control {
        margin-left: 1.5rem !important;
      }
    }
  }

  .checkBox {
    font-size: 14px;
    font-weight: normal;
    :global {
      .custom-control-label {
        font-size: 14px;
        display: flex;
      }
      .custom-control-label::before {
        width: 0.9rem;
        height: 0.9rem;
      }
    }
  }

  .textInputRightMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 12px;

    @media (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 8px;
    }

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  .textInputLeftMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;

    @media (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 8px;
    }
    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  .textInputMiddleMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  .textInputAddress {
    display: flex;
    flex: 2;
    flex-direction: column;
    margin-right: 12px;

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: initial;
    width: 100% !important;
    :global {
      .rselect__control {
        margin-bottom: 16px;
      }
    }
    .textInputRightMargin {
      margin-right: 0;
    }
    .textInputLeftMargin {
      margin-left: 0;
    }
    .textInputMiddleMargin {
      margin-right: 0;
      margin-left: 0;
    }
    .textInputAddress {
      margin-right: 0;
    }
  }
}
