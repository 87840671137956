.container {
  display: flex;
  height: 76px;
  min-width: 800px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #8898aa;
  background: linear-gradient(0deg, #f7fafc, #f7fafc),
    linear-gradient(0deg, #8898aa, #8898aa);
  cursor: pointer;

  .cardData {
    gap: 24px;
    height: 44px;
    flex-shrink: 0;
    margin: 16px 0px 16px 24px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex: 1;

    .dataWrapper {
      display: flex;
      justify-content: space-between;
      flex: 1;
      gap: 20px;

      .rightWrapper {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        flex: 2;

        .statusWrapper {
          display: flex;
          gap: 115px;
          align-items: center;
        }
      }
    }

    .statusContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 6px;
      height: 44px;
      flex: 1;
      .yearStatusContainer {
        min-width: 100px;
      }

      .yearWrapper {
        display: flex;
        gap: 8px;
      }
      .year {
        padding: 2px 12px;
        font-family: 'Open Sans';
        margin-bottom: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 19px;
        &.none {
          color: #d2e3ee;
          border: 1px solid #d2e3ee;
        }
        &.partial {
          color: #fc7c5f;
          border: 1px solid #fc7c5f;
        }
        &.complete {
          background-color: #6fcf97;
        }
      }
      .statusText {
        color: var(--card-heading-32325-d, #32325d);
        font-family: Open Sans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.11px;
        text-transform: uppercase;
      }

      .docNo {
        color: var(--card-heading-32325-d, #32325d);
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
