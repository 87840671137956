@import 'assets/scss/custom/_variables.scss';

.right-column {
  color: $gray-800;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
  }
  .empty-avatar {
    background-color: $gray-300;
  }
  .tabWrapper {
    position: sticky;
    top: 0;
    // z-index: 9;
    width: calc(100%);
    padding: 0.5rem 0;
    border-bottom: 1px solid $gray-200;
    :global {
      .dropdown {
        position: unset;
      }
      .dropdown-menu-lg {
        min-width: 100%;
      }
    }
  }
  .underCollaboration {
    width: 80%;
  }

  .tabItem {
    min-width: 33.33% !important;
    flex: 1 !important;
    padding-right: 0 !important;
    position: relative;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
    a {
      display: flex;
      flex-direction: column;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      box-shadow: none !important;
      &:hover,
      &:active,
      &:focus {
        color: $primary !important;
      }
    }
    .tabTitleWrapper {
      display: block;
      flex-direction: row;
      justify-content: space-between;
      .leftTitle {
        color: black;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: center;
      }
      .closeButton {
        color: black;
        line-height: 32px;
        font-weight: 900;
        font-size: 40px;
      }
    }
  }

  .badgeCount {
    position: absolute;
    right: 35px;
    top: -7px;
    width: 16px;
    height: 16px;
    font-size: 0.5rem;
    border: 1px solid white;
    @media (max-width: 768px) {
      right: 65px;
    }
    @media (max-width: 576px) {
      right: 48px;
    }
  }
}
