@import 'assets/scss/custom/_variables.scss';

.storyModal {
  @media (max-width: 1200px) {
    min-width: 90%;
  }
  :global {
    .modal-title {
      color: $gray-800;
      font-weight: 600;
      font-size: 17px;
    }
    .pg-viewer-wrapper {
      max-height: calc(100% - 55px);
      overflow-y: auto;
      //padding: 10px 0;
      .react-grid-Cell {
        user-select: text;
      }
    }
    .modal-content {
      min-height: 95% !important;
    }
    .pdf-canvas {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: transparent;
      padding-bottom: 10px;
    }
    canvas {
      width: 555px;
    }
    .modal-body {
      padding: 0;
      background: #dfddea;
    }
  }
  .modalHeader {
    width: 100%;
    padding: 1.25rem;
    border-top-left-radius: 0.4375rem;
    border-top-right-radius: 0.4375rem;
    .headerWrapper {
      .header {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .content {
          display: flex;
          align-items: center;
          gap: 16px;

          .cursor {
            cursor: pointer;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 17px;
            line-height: 23px;

            color: #32335a;
          }
          .tags {
            display: flex;
            gap: 8px;
            align-items: center;
            div {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              display: flex;
              text-transform: uppercase;
              border-radius: 16px;
              padding: 3px 9px;
            }

            .processed {
              color: #1aae6f;
              background: #b0eed3;
            }
            .failed {
              color: #f80031;
              background: #fdd1da;
            }
          }
        }
      }
      .fileTypesWrapper {
        background-color: #f8f9fa;
        padding: 5px;
        border-radius: 8px;
        .fileTypes {
          &.disable {
            pointer-events: none;
          }
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 5px;
          div {
            cursor: pointer;
            text-align: center;
            padding: 6px;
            background: transparent;

            border-radius: 8px;
            &.active {
              box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08),
                0px 1px 2px rgba(50, 50, 93, 0.1);
              background: #ffffff;
            }
          }
        }
      }
    }
  }

  .fileName {
    font-size: 18px;
    font-weight: bold;
  }

  .downloadIcon {
    cursor: pointer;
  }

  .modalBodyWrapper {
    position: relative;

    .hoverBar {
      //position: absolute;
      width: 100%;
      z-index: 999;
      background: #504b70;
      opacity: 0.7;
      padding: 14px 36px 14px 25px;
      //top: -70px;
      color: white;
      display: flex;
      justify-content: space-between;
      transition: top 1s;
      @media (max-width: 468px) {
        align-items: flex-start;
      }
      .downloadButton {
        width: 150px;
        justify-content: end;
      }
    }

    //&:hover > .hoverBar {
    //  top: 0;
    //}
  }
}

//.modalBodyWrapper {
//  .pg-viewer-wrapper {
//    transition: padding-top 1s;
//  }
//  &:hover {
//    :global {
//      .pg-viewer-wrapper {
//        padding-top: 55px;
//      }
//    }
//  }
//}

.pdfOverflow {
  :global {
    .pg-viewer-wrapper {
      overflow-y: hidden;
    }
    .react-grid-HeaderCell-sortable {
      pointer-events: none;
    }
    canvas {
      width: 100%;
    }
  }
}
// .pdfWrapper {
//   height: 400px;
//   width: 100%;
// }

.zoom {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 20px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-color: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    width: 30px;
    line-height: 0;
    height: 30px;
    cursor: pointer;
    margin-right: 8px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0px;
      line-height: 0;
      height: 0px;
    }
  }
}

.optionsList {
  background-color: #f0f0f0;
  .zoomComponent {
    overflow: hidden;
    background-color: #f0f0f0;
    border-radius: 14px;
    position: absolute;
    display: flex;
    padding: 0 !important;
    z-index: 100;
    right: 90px;
    top: 11px;
    @media (max-width: 468px) {
      right: 14px;
      top: 39px;
    }
  }
}
