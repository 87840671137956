@import 'assets/scss/custom/_variables.scss';

.modalRelatedEntity {
  .cityStateInfo {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }
  .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    .delete {
      color: #f5365c;
      box-shadow: none;
      border: none;
      background-color: transparent;
    }
    .deny {
      background: #dee2e6;
      border: #dee2e6;
      color: #525f7f;
    }

    .submit {
      background: #525f7f !important;
    }

    button {
      box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
      border-radius: 4px;
      border: none;
    }
  }
}
