@import '../../assets/scss/custom/variables';

.header {
  background-image: url('../../assets/img/theme/Login_Bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 49.9vh !important;
  .separator {
    fill: $body-bg;
  }

  @media (max-height: 768px) {
    padding: 5rem !important;
  }
}
.separatorStyle {
  bottom: -2px;
}
