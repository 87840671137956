.support {
  @media screen and (max-width: 1200px) {
    margin-top: 10px;
  }
  .title {
    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #212519;
    }
  }
  .partnerManger {
    display: flex;
    justify-content: left;
  }

  .promises {
    margin-bottom: 57px;
    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #212519;
    }
    .point {
      display: flex;
      gap: 12px;
      margin-bottom: 22px;
      h4 {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #212519;
        margin-bottom: 0;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #212519;
        margin-bottom: 0;
      }
    }
  }
  .bottomCard {
    padding: 18px 22px;
    background: #ebeefb;
    border-radius: 16px;
    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #212519;
      margin-bottom: 6px;
    }
    h3 {
      font-weight: 750;
      font-size: 18px;
      line-height: 22px;
      color: #212519;
      margin-bottom: 12px;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #212519;
      margin-bottom: 0px;
    }
  }
}
