.tabComments {
  .commentItem {
    margin-bottom: 35px;
    .avatar {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .picture {
        width: 22px;
        height: 22px;
        background: #998ad4;
        border-radius: 50%;
        margin-right: 8px;

        img {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .name {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.01em;
        color: #32325d;
        margin-right: 8px;
      }

      .date {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.01em;
        color: #525f7f;
      }
    }

    .quote {
      border-left: 2px solid #d9d9d9;
      padding-left: 10px;
      display: flex;
      margin-bottom: 10px;

      .content {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.01em;
        color: #525f7f;
        margin-right: 10px;
        max-width: 300px;
      }

      button {
        border: 1px solid #525f7f;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.01em;
        color: #525f7f;
        background: none;
        height: 22px;
      }
    }

    .message {
      max-width: 600px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #212519;
    }
  }

  .noDataWrapper {
    height: calc(100vh - 380px);
  }

  .imageWrapper {
    display: flex;
    width: 150px;
    height: 150px;
    background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
    border-radius: 50%;
    margin-bottom: 20px;
  }
  .defaultImage {
    max-width: 45px;
  }

  .defaultText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #cdcdcd;
  }
}
