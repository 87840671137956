@import 'assets/scss/custom/_variables.scss';

.root {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid $border-color;

    > * {
      margin: 0px;
    }

    button.addEntry {
      border-radius: $btn-border-radius;
      box-shadow: $btn-box-shadow;
      border: none;
      img {
        margin: 10px;
      }

      &:hover {
        background-color: $primary;
        img {
          filter: brightness(0) invert(1) !important;
        }
      }
    }
  }

  .body {
    padding: 20px;
    max-height: 400px;
    overflow-y: auto;
    overflow-anchor: none;
    .noData {
      img {
        margin-bottom: 30px;
        width: 100%;
        max-width: 293px;
      }
      text-align: center;
    }

    .content {
      margin-left: 10px;

      .item {
        border-left: 2px dashed $border-color;
        position: relative;
        font-size: 13px;
        padding-left: 40px;
        padding-bottom: 10px;

        &:last-child {
          border-left: none;
        }

        .recentEntry {
          position: absolute;
          top: -2px;
          left: -18px;
        }
        .time {
          display: flex;
          justify-content: space-between;
          align-items: center;

          color: #8898aa;

          .fromTo {
            font-weight: bold;
          }

          .duration {
            display: flex;
            align-items: center;

            :global {
              .dropdown-menu-right {
                margin-top: 5px;

                &::before {
                  display: none;
                }
              }
            }

            .clockIcon {
              margin-right: 5px;
            }
          }
        }

        .title {
          margin-top: 5px;
        }

        .description {
          margin-top: 10px;
          font-size: 13px;
        }
      }
    }
  }
}
