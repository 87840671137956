@import 'assets/scss/custom/_variables.scss';
.modal {
  max-width: 884px;
  @media (max-width: 992px) {
    max-width: 596px;
  }
  .modalFooter {
    display: flex;
    justify-content: end;
    gap: 24px;

    .submitButtonWrapper {
      margin: 0 !important;
      padding: 8px 16px;
    }
    .cancelButtonClass {
      margin: 0 !important;
      padding: 8px 16px;
    }
  }

  :global {
    .ql-editor {
      height: calc(100vh - 450px);
      max-height: 300px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
