@import 'assets/scss/custom/_variables.scss';

.root {
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
  }

  .header {
    background: radial-gradient(
      50% 50% at 50% 105.39%,
      #111aef 0%,
      #1171ef 100%
    );
    mix-blend-mode: normal;
    opacity: 0.8;

    .body {
      margin: 0 40px;

      .title {
        color: $white;
        font-size: 28px;
        letter-spacing: 0.03em;
        margin-bottom: 20px;
      }

      .intro {
        color: $white;
        font-size: 16px;
        letter-spacing: -0.3px;
        margin-bottom: 20px;
      }

      .image {
        width: 100%;
      }
    }
  }

  .actions {
    margin: 20px;
    display: flex;
    justify-content: flex-end;

    & > * {
      margin-bottom: 10px;
    }

    @media (max-width: 575.98px) {
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }

  .mainContent {
    margin: 20px;

    .noDataWrapper {
      height: 50vh;
    }
    .image {
      max-width: 290px;
    }
    :global {
      .row {
        & > * {
          margin-bottom: 30px;
        }

        .card {
          height: 100%;
          cursor: pointer;
        }
      }
    }

    .section {
      .content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .footer {
        position: absolute;
        bottom: 10px;
        width: calc(100% - 40px);
      }
    }

    .section + .section {
      border-top: 1px solid $gray-300;
      margin-top: 20px;
      padding-top: 60px;
    }

    h1 {
      margin-bottom: 35px;
    }
  }
}
