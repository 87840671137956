@import 'assets/scss/custom/_variables.scss';

.card {
  // overflow: hidden;
  box-shadow: none;
  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 23px;
      color: #32325d;
    }
    .headerBadge {
      width: 26px;
      height: 26px;
      display: inline-flex;
      margin-left: 11px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: #ec0c38;
      color: #ffffff;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
    }
    .viewButton {
      padding: 0;
      font-style: normal;
      color: #1da1f2;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-decoration: underline;
      &:active:focus {
        box-shadow: none;
      }
    }
  }
  .cardFooter {
    border-top: none;
    padding: 16px 16px 32px 16px;
    .actions {
      text-align: end;
      .archiveButton {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #1da1f2;
        text-decoration: underline;
        padding: 0;
        &:active:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.notificationList {
  padding: 0;
  @media (max-width: 991px) {
    max-height: calc(100vh - 360px);
    overflow: auto;
    &::-webkit-scrollbar {
      background-color: transparent;
      opacity: 0.9;
      width: 4px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dee2e6;
      border-radius: 6px;
      transition: opacity 0.2s, background-color 0.2s linear,
        width 0.2s ease-in-out;
      -webkit-transition: opacity 0.2s, background-color 0.2s linear,
        width 0.2s ease-in-out;
      width: 4px;
    }
    &::-webkit-scrollbar {
      opacity: 0;
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      opacity: 0;
      width: 6px;
    }
  }

  :global {
    .list-group-item-action {
      &:focus {
        background-color: transparent;
      }
    }
  }
}

.notificationBadge {
  position: relative;
  top: -10px;
  left: -10px;
  border: 3px solid $secondary !important;
  font-size: 0.6rem;
  width: 1.7rem;
  height: 1.7rem;
}

.notificationHeader {
  color: $gray-600;
  .markAll {
    cursor: pointer;
  }
}
.statusChange {
  margin-bottom: 24px;
  .activityText {
    font-size: 14px;
    color: #000;
    font-family: Open Sans;
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
    justify-content: flex-start;

    .date {
      color: #8898aa;
      text-align: right;
      font-family: Open Sans;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.activityWrapper {
  margin-bottom: 40px;
  position: relative;

  .activity {
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 18px;
    align-items: center;
    margin-bottom: 16px;
    .avatarWrapper {
      .unread {
        i {
          font-size: 8px;
          color: transparent;
        }
        &.unresolved {
          i {
            color: #35b0f0;
          }
        }
      }
      .timelineWrapper {
        display: flex;
        flex-direction: column;
        .timeline {
          position: absolute;
          width: 1px;
          border-left: 1px solid #a7a7a7;
          opacity: 0.3;
          min-height: 100px;
          top: 30px;
          left: 14px;
          height: 100%;
        }
      }
      .avatar {
        aspect-ratio: 1/1;
        height: 100%;
        width: 100%;
        max-width: 100%;
      }
    }
    .contentWrapper {
      .activityText {
        font-size: 14px;
        color: #656565;
        font-family: Open Sans;

        .container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .date {
            color: #8898aa;
            text-align: right;
            font-family: Open Sans;
            font-size: 12px !important;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .statusSection {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
.activityAction {
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 18px;
  align-items: center;
  .messageCardWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    .groupedActivities {
      width: 100%;
      gap: 22px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .activityCard {
      cursor: pointer;
      display: grid;
      grid-template-columns: 30px 1fr;
      gap: 16px;
      width: 100%;
      align-items: center;
      &.grouped {
        grid-template-columns: 1fr;
      }
      .avatar {
        aspect-ratio: 1/1;
        width: 100%;
        height: auto;
        max-width: 100%;
      }

      .content {
        border-radius: 4px;
        border: 1px solid #e9ecef;
        background: #f0f3f6;
        display: grid;
        grid-template-columns: 1fr 9px;
        justify-content: space-between;
        gap: 8px;
        padding: 14px 16px;
        color: #0a0a0a;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &.grouped {
          grid-template-columns: 10px 1fr 9px;
          align-items: baseline;
        }
        .cardText {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          .fileAdded {
            display: inline-block;
            line-height: 24px;
            color: #000;
            font-family: Open Sans;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            max-height: 20px;
            p {
              color: #000;
              font-family: Open Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .documentCommentAdded {
            display: inline-block;
            line-height: 24px;

            color: #000;
            font-family: Open Sans;
            font-size: 14px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            max-height: 20px;

            p {
              color: #000;
              font-family: Open Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 21px;
            }
          }
          .messageOpenAction {
            i {
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

.notificationWrapper {
  display: flex;

  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
  overflow: hidden;
  cursor: pointer;

  .notificationSidebar {
    width: 20px;
    &.active {
      border-left: 12px solid #1da1f2;
      height: 96px;
    }
  }
  .contentWrapper {
    width: calc(100% - 20px);
    height: 96px;
    display: flex;
    flex-direction: column;
    user-select: none;
    .header {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .subject {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .icon {
          margin-right: 8px;
        }
        .title {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 23px;
          color: #32325d;
          margin-bottom: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &.unresolved {
            font-weight: 700;
          }
        }
      }
      .postDetails {
        display: flex;
        align-items: center;
        .date {
          color: #8898aa;
          text-align: right;
          font-family: Open Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 0;
        }
        .dropdownWrapper {
          :global {
            .dropdown {
              .dropdown-menu {
                padding: 0;
                min-width: fit-content;
                button {
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  color: #3c4d69;
                  font-family: Open Sans;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                }
              }
              a:hover {
                box-shadow: none;
                i {
                  color: #7889e8;
                }
              }
              a:focus {
                box-shadow: none;
                i {
                  color: #7889e8;
                }
              }
            }
          }
        }
      }
    }
    .divider {
      border-bottom: 1px solid #dee2e6;
    }
    .content {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .message {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #32325d;

        .documentCommentAdded {
          p:first-of-type {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #32325d;
            font-family: Open Sans;
            font-size: 14px;
          }
        }

        .fileAdded {
          color: #32325d;
          font-family: Open Sans;
          font-size: 14px;
          &::before {
            content: var(--before-content);
            font-weight: 700;
            color: #322d52;
          }
        }

        span {
          display: inline-flex;
          align-items: baseline;
        }
        body {
          font-weight: 600 !important;
          background: transparent;
          span {
            color: #32325d !important;
          }
          em {
            color: #32325d !important;
          }
          b {
            color: #32325d !important;
          }
          i {
            color: #32325d !important;
          }
          ul {
            li {
              span {
                color: #32325d !important;
              }
              strong {
                color: #32325d !important;
              }
            }
          }
          p {
            font-weight: 600;
            text-overflow: ellipsis;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 0;
            color: #32325d !important;
            strong {
              color: #32325d !important;
              font-weight: 700;
            }
          }
        }
      }
      .messageOpenAction {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }
}

.notificationContent {
  max-width: calc(100% - 72px);
  span {
    word-break: break-word;
  }
}

.noMessages {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 600px);
  padding: 16px;
  .content {
    padding: 40px;
    border-radius: 50%;
    background: #f6f9fc;
    aspect-ratio: 1/1;
    max-width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    svg {
      path {
        stroke-width: 1px;
        stroke: #ced4da;
      }
    }

    p {
      font-family: 'Open Sans';
      color: #ced4da;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 200px;
    }
  }
}

.filterWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 16px;
  align-items: center;
  &.three-column {
  }
  @media (max-width: 900px) {
    grid-template-columns: 60px 1fr;
  }
  @media (max-width: 768px) {
    align-items: baseline;
  }
  .label {
    p {
      font-family: 'Open Sans';
      margin-bottom: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      color: #525f7f;
    }
  }
  .filters {
    display: grid;
    align-items: center;
    flex-wrap: wrap;

    .datePicker {
      right: 0;
      left: auto;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 29px;
  justify-content: space-between;
  .filterWrapper {
    display: flex;
    align-items: center;
    // height: 106px;
    justify-content: space-between;
    gap: 50px;
    margin-bottom: 19px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: baseline;
    }
    label {
      color: #525f7f;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
      margin-bottom: 4px;
    }

    .search {
      // input {
      //   height: 32px;
      // }
      .input {
        border: 1px solid #dee2e6;
        box-shadow: none;
      }
    }
    .selectWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: baseline;
      }
    }
    .infoSection {
      p {
        color: #32325d;
        text-align: center;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: left;
      }
    }
    .filters {
      display: flex;
      align-items: center;
      gap: 24px;
      flex-wrap: wrap;
      .select {
        width: 170px;
        .menuHeader {
          text-align: left;
        }
        :global {
          // .rselect__control {
          //   min-height: 32px;
          //   height: 32px;
          // }
          .rselect__group-heading {
            color: #0b1526;
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .multi-value_label_default {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 97px;
          }
          .multi-value_label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 51px;
          }
        }
      }
      .filterIcon {
        margin-top: 10px;
        .filterIconWrapper {
          position: relative;
          .countIcon {
            position: absolute;
            top: -4px;
            right: 0;
          }
        }
        .select {
          width: 30px;
          .menuHeader {
            text-align: left;
            padding: 4px;
            svg {
              path {
                fill: #0c85d0;
              }
            }
          }
          .actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            padding: 12px;
            .clear {
            }
            .apply {
            }
          }
          :global {
            // .rselect__control {
            //   min-height: 32px;
            //   height: 32px;
            // }
            .rselect__group-heading {
              color: #0b1526;
              font-family: Open Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            .rselect__control {
              display: none;
            }
          }
        }
        cursor: pointer;
      }
    }
  }
}
