.deactivated {
  height: 275px;
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transform: translate(0, -42%);
    .bold {
      font-weight: bold;
    }
    .avatar {
      width: 140px;
      height: 140px;
    }
  }
}
