@import 'assets/scss/custom/_variables.scss';

.categories-title {
  background-color: $gray-100;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-transform: uppercase;
  font-size: $table-head-font-size;
  .category-heading-column {
    h6 {
      margin-left: 2.1rem;
    }
  }
}

.create-category-btn {
  background-color: $lyght-blue;

  &:disabled {
    color: $gray-400;
    background-color: transparent;
    border: 1px solid $gray-400;
  }
}

.articles {
  color: $gray-700;
  font-size: $table-body-font-size;
  width: 2.3rem;
}

.content-wrapper {
  overflow-y: hidden !important;
}

.content-wrapper-scroller::-webkit-scrollbar {
  display: none;
}

.content-wrapper-scroller {
  -ms-overflow-style: none;
  overflow-y: auto !important;
}

.category-name {
  overflow: hidden;
  h5 {
    color: $gray-700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
