@import 'assets/scss/custom/_variables.scss';

.support-req-modal {
  @media (min-width: 992px) {
    max-width: 596px;
  }
}

:global {
  .ql-editor {
    height: calc(100vh - 450px);
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
}
