.accordionWrapper {
  display: flex;
  flex-direction: column;

  .accordionHeader {
    cursor: pointer;
    padding: 20px 18px;
    z-index: 1;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(50, 50, 93, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    transition: transform 0.6s ease;

    .activeIcon {
      transform: rotate(-180deg);
    }
    .iconWrapper {
      i {
        color: #bdbdbd;
      }
    }
  }

  .accordionContent {
    max-height: 0;
    overflow: hidden;
    background-color: white;
    padding: 0px 40px;
    border-radius: 0px 0px 6px 6px;
    transition: 0.3s ease;

    .childrenWrapper {
      min-height: max-content;
    }
    &.activeContent {
      max-height: max-content;
      padding: 20px 40px;
    }
  }
}
