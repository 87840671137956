.wrapper {
  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      color: #32325d;

      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px; /* 168.75% */
    }
    .tableSubHeader {
      color: #adb5bd;

      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .addStudyBtn {
      padding: 7px;
      box-shadow: 0px 5px 8px 0px #0000001f;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .cardBody {
    padding: 0;
    max-height: 315px;
    overflow: auto;
    .tableWrapper {
      overflow: visible;
      min-height: 10.5rem;
      table {
        // table-layout: fixed;
        * {
          td {
            white-space: nowrap !important;
            max-width: 7.75rem;
            vertical-align: middle;
            padding-top: 24px;
            padding-bottom: 24px;
          }
        }
        .activeName {
          background: #f2fbff;
        }
        .name {
          font-weight: 600;
          // &.active {
          //   background: #f2fbff;
          // }
          span {
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        tbody {
        }
        .row {
          cursor: pointer;
        }
        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.cardwrapper {
  height: 100%;
}
.modalWrapper {
  .modalBody {
    .modalContent {
      color: #000000;
    }
    .studyText {
      font-weight: 700;
      color: #000000;
      margin-top: 8px;
      margin-bottom: 16px;
    }
    .modalText {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0.4000000059604645px;
      color: #000000;
      margin-bottom: 8px;
    }
    .yearButton {
      padding: 7px;
      border: 1px solid #04a0f0;
      box-shadow: 0px 5px 8px 0px #0000001f;
      background-color: #ffffff;
      color: #04a0f0;
      font-size: 12px;
      line-height: 16px;
      &:active,
      &:focus,
      &:hover {
        padding: 7px;
        border: 1px solid #04a0f0;
        box-shadow: 0px 5px 8px 0px #0000001f;
        background-color: #ffffff;
        color: #04a0f0;
        font-size: 12px;
        line-height: 16px;
      }
      &.selected {
        color: #ffffff;
        background-color: #1da1f2;
      }
    }
  }
  .modalFooter {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e9ecef;
    padding: 13px 24px;
    .cancelBtn {
      padding: 0px 16px;
      color: #1da1f2;
      background-color: #ffffff;
      box-shadow: none;
      &:active,
      &:focus,
      &:hover {
        padding: 0px 16px;
        box-shadow: none !important;
        color: #1da1f2;
        background-color: #ffffff;
      }
    }
    .addBtn {
      padding: 11px 16px;
      border-radius: 4px;
      color: #ffffff;
      background-color: #1da1f2;
      box-shadow: 0px 5px 8px 0px #0000001f;
      &:active,
      &:focus,
      &:hover {
        color: #ffffff;
        background-color: #1da1f2;
      }
    }
  }

  .yearWrapper {
    margin-bottom: 24px;
  }
  .creditEstimate {
  }
  .error {
    color: red;
    font-size: 13px;
    margin-top: 8px;
  }
}
