@import 'assets/scss/bootstrap/mixins/_breakpoints.scss';
@import 'assets/scss/custom/_variables.scss';

.sideBar {
  margin-left: 1.5rem;

  @include media-breakpoint-up(lg) {
    width: 20%;
  }
  @include media-breakpoint-down(lg) {
    margin-right: 1.5rem;
  }
  .sideBarContent {
    width: 100%;
    position: relative;
    padding-left: 1rem;
    &.hoverHand {
      cursor: pointer;
    }

    .icon {
      position: absolute;
      background-color: white;
      margin-right: 0.5rem;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: #cbc9d6;
        font-size: 1rem;
      }
    }

    .active {
      background-color: #2191be;

      i {
        color: white;
      }
    }

    .descriptionWrapper {
      border-left: 1px dashed #dfddea;
      padding-left: 1.5rem;
      min-height: 100px;
      padding-bottom: 2.375rem;

      :last-child {
        border-left: 0;
      }
    }
    .last {
      border-left: 0;
    }
    .activeBorder {
      border-left: 2px solid #2191be;
    }

    .label {
      font-weight: bold;
      font-size: 1.5rem;
      color: #a2a0b4;
    }
    .activeLabel {
      color: #0373a0;
    }
    .description {
      font-weight: normal;
      font-size: 16px;
      color: #504b70;
    }
  }
}
