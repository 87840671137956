.notesCard {
  margin-bottom: 0px;
  .notesHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    .title {
      color: #32325d;
    }
    .addNoteBtn {
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
      padding: 7px;
    }
  }
  .cardBody {
    max-height: 400px;
    overflow: scroll;
    padding: 0px 24px;
  }
}
.noNoteBody {
  height: 250px;
  padding: 0px 24px;
  .noNotes {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
.allNotesButton {
  background-color: transparent !important;
  box-shadow: none !important;
  &:active {
    background-color: transparent !important;
  }
}
.allNotesModal {
  max-width: 600px;

  :global {
    .modal-title {
      color: #32325d;
      font-size: 16px;
      font-weight: 700;
      line-height: 27px;
    }
  }
}
.modalBody {
  padding: 0px 22px;
  max-height: 600px;
  overflow: scroll;
}
