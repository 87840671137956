@import 'assets/scss/custom/_variables.scss';

.notificationList {
  max-height: 400px;
  overflow-y: auto;

  :global {
    .list-group-item-action {
      &:focus {
        background-color: transparent;
      }
    }
  }
}

.notificationBadge {
  position: relative;
  top: -10px;
  left: -10px;
  border: 3px solid $secondary !important;
  font-size: 0.6rem;
  width: 1.7rem;
  height: 1.7rem;
}

.unread {
  background: $secondary;
}

.notificationHeader {
  color: $gray-600;
  .markAll {
    cursor: pointer;
  }
}
.notificationItem {
  border-left: 3px solid $lyght-blue;
  .time {
    min-width: 100px;
  }
  .markAsRead {
    display: none;
  }
  &:hover {
    .time {
      display: none;
    }
    .markAsRead {
      display: unset;
      transition: all 0.3s;
      min-width: 100px;
    }
  }
}

.notificationContent {
  max-width: calc(100% - 72px);
  span {
    word-break: break-word;
  }
}
