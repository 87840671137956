.root {
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
  }

  .container {
    margin: 0 30px;

    & > * {
      margin-bottom: 20px;
    }

    .content {
      .actions {
        float: right;
        height: 25px;
        min-width: 122px;
        @media (max-width: 575.98px) {
          width: 100%;
          margin-bottom: 10px;
        }
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 10px;

        button {
          margin-right: 0px;
        }
      }
    }

    .mostArticle {
      :global {
        .list-group-item {
          cursor: pointer;
        }
      }
    }
  }
}
