.wrapper {
  padding: 24px 29px;
  .header {
    margin-bottom: 48px;
    display: flex;
    gap: 24px;
    .breadcrumb {
      margin-top: 6px;
      color: #8898aa;
      span {
        cursor: pointer;
      }
    }
    h3 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 35px;

      color: #32325d;
    }
  }
  .card {
    .cardHeader {
      color: #32325d;
      font-family: Open Sans;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .cardBody {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      min-height: calc(100vh - 466px);
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
    .cardFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cancel {
        color: #5e72e4;
        text-align: center;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px; /* 150% */
        letter-spacing: 0.4px;
      }
      .upload {
        // border: 1px solid #d2e3ee;
        color: #fff;
        background: #5e72e4;
        padding: 12px 24px;
        box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.5);
        border-radius: 4px;
        &:active {
          background: #5e72e4;
          color: #fff;
          // border: 1px solid #d2e3ee;
          box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.5);
        }
      }
    }
  }
}
