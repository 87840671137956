@import 'assets/scss/custom/_variables.scss';
.modalEditContract {
  :global {
    .modal-content {
      overflow: visible;
    }
    .modal-body {
      overflow-y: visible;
    }
  }
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #32325d;
    margin-bottom: 30px;
  }
  .modalBody {
    max-height: 715px;
    overflow: auto;
  }

  .select {
    :global {
      .rselect__menu {
      }
      .rselect__menu-list {
        max-height: 10rem;
        width: 100%;
      }
    }
  }

  label {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #32325d;
  }

  .addEntity {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #32325d;
    background: none;
    border: none;
    margin-bottom: 20px;
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .deny {
      background: #dee2e6;
      border: #dee2e6;
      color: #525f7f;
    }

    .submit {
      background: #525f7f !important;
    }

    button {
      box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
      border-radius: 4px;
      border: none;
    }
  }
}
