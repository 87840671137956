.row {
  width: 100%;
  margin: 0;
  display: flex;
  flex: 1;
  align-items: baseline;

  :global {
    textarea {
      width: 100%;
    }
    .form-control-label {
      font-size: 14px;
    }
  }

  .textInputRightMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 12px;

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  .textInputLeftMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  .textInputMiddleMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  .textInputAddress {
    display: flex;
    flex: 2;
    flex-direction: column;
    margin-right: 12px;

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: initial;
    :global {
      .rselect__control {
        margin-bottom: 16px;
      }
    }
    .textInputRightMargin {
      margin-right: 0;
    }
    .textInputLeftMargin {
      margin-left: 0;
    }
    .textInputMiddleMargin {
      margin-right: 0;
      margin-left: 0;
    }
    .textInputAddress {
      margin-right: 0;
    }
  }
}
