@import 'assets/scss/custom/_variables.scss';

.teamMembers {
  width: 100%;
  &.teamLoading {
    height: calc(100vh - 14.75rem);
  }
  .loading {
    position: absolute;
    height: inherit;
    margin: auto;
    z-index: 999;
    background: $body-bg;
  }
  .userName {
    vertical-align: middle !important;
    font-weight: 600;
    width: 25%;
    @media (max-width: 768px) {
      width: 300px;
    }
    a {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: unset;
    }
  }
  .middle {
    vertical-align: middle;
  }
  .duration {
    max-width: 150px;
  }
  .noDataWrapper {
    height: calc(100vh - 280px);
  }
  .addUser {
    color: $primary;
    cursor: pointer;
  }
  .image {
    max-width: 550px;
  }
  :global {
    .react-bootstrap-table.table-responsive {
      overflow: auto;
      min-height: 150px;
      @media (max-width: 480px) {
        overflow: auto;
      }

      .rselect__menu {
        z-index: 9;
      }
      // This is to apply ellipsis to the table-cells, if we do not use this it will go beyond the width
      table {
        table-layout: fixed;
      }
      .react-bs-table-no-data {
        white-space: normal;
        text-align: center;
      }
    }
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.applyHeight {
  height: 46px;
}
.access {
  height: 81px;
}
.legend {
  width: 15px;
  height: 15px;
  border: 1px solid $white;
  background: $gray-200;
}
.info {
  margin-top: 2px;
  cursor: pointer;
}
.customNoData {
  &:hover {
    background: $gray-100;
    cursor: pointer;
  }
}
.icon {
  font-size: 28px;
}
.selectedOption {
  :global {
    .rselect__multi-value {
      position: relative;
      transition: all 0.3s;
    }
    .rselect__multi-value__remove {
      display: none;
      &:hover {
        background: unset;
      }
    }
  }
  &:hover {
    :global {
      .rselect__multi-value {
        padding-right: 20px;
      }
      .rselect__multi-value__remove {
        display: block;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
  }
}

.userOption {
  &:hover {
    background: $gray-100;
    cursor: pointer;
  }
}
.optionAvatar {
  width: 20px;
  height: 20px;
}
.smallColumns {
  width: 10%;
  @media (max-width: 768px) {
    width: 150px;
  }
}
.utilizationColumn {
  width: 20%;
  @media (max-width: 768px) {
    width: 350px;
  }
  .text {
    width: 25%;
  }
  :global {
    .progress {
      height: 8px !important;
      cursor: pointer;
    }
  }
}
.accessColumn {
  width: 25%;
  min-width: 100%;
  @media (max-width: 768px) {
    width: 250px;
  }
}
.accessText {
  width: 90%;
}
