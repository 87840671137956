.dataContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    color: #8899a9;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    font-weight: 700 !important;

    :global {
      body {
        font-weight: 700 !important;
      }
    }
  }

  .description {
    color: var(--color-black-95, rgba(0, 0, 0, 0.95));
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
}
.wrapper {
  max-height: 600px;
  overflow-y: auto;
}

.headText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #32325d;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
  background-color: #e9ecef78;
  padding: 4px;
  border-radius: 4px;
  a {
    margin-right: 5px;
    margin-top: 5px;
    i {
      padding: 6px;
      background-color: white;
      color: #1da1f2;
      margin-right: 0px;
    }
  }
  span {
    margin-left: 5px;
  }
}

.container {
  flex-shrink: 0;
  border-radius: 5px;
  background: #fff;
  padding: 24px 16px 0 16px;
}

.collabratorContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: 'flex-start';
  margin-bottom: 15px;
}

.collabItem {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.avatar {
  width: 26px;
  height: 26px;
}

.collabratorHeader {
  color: var(--color-black-95, rgba(0, 0, 0, 0.95));
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.collabratorName {
  color: var(--color-black-95, rgba(0, 0, 0, 0.95));
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.iconWrapper {
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customAccordionHeader {
  cursor: pointer;
  padding: 12px 18px !important;
  z-index: 1;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(50, 50, 93, 0.1),
    0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  transition: transform 0.6s ease;

  .activeIcon {
    transform: rotate(-180deg);
  }
  .iconWrapper {
    i {
      color: #bdbdbd;
    }
  }
}
.overviewWrapper {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'flex-start';
  gap: '24px';
  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headerContent {
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: 0px;
      justify-content: space-between;
      font-size: 14px;
    }
    .addIcon {
      color: #bdbdbd;
      cursor: pointer;
    }
  }
}
.icon {
  height: 14px;
}
