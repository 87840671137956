@import 'assets/scss/custom/_variables.scss';

.container {
  margin-top: -17rem;
  .card {
    border-radius: $login-card-border-radius;
    background-color: $login-card-bg-color;
  }
}

.brandImage {
  width: 50%;
  min-width: 200px;
}
.placeholderElement {
  z-index: 100;
}
