@import 'assets/scss/custom/_variables.scss';

.root {
  display: flex;
  align-items: center;

  .button {
    button {
      border: none;
      background: none;
      outline: none;
      box-shadow: none;
    }

    .active {
      img {
        filter: invert(54%) sepia(24%) saturate(5996%) hue-rotate(165deg)
          brightness(101%) contrast(90%);
      }

      .count {
        color: $primary;
      }
    }

    .count {
      font-size: 12px;
      color: $gray-800;
      margin-left: 5px;
    }
  }
}
