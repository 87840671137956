@import 'assets/scss/custom/_variables.scss';

.documentProgress {
  .back {
    margin-top: 5px;
    margin-bottom: 30px;
    .backButton {
      i {
        top: 1px;
      }

      padding: 0;
      &:active:focus {
        box-shadow: none;
      }
    }
  }
}

.dashboardWrapper {
  display: grid;
  grid-template-columns: 9fr 5fr;
  gap: 24px;
  @media (max-width: 1350px) {
    grid-template-columns: 9fr;
  }
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 550px) {
    grid-template-columns: 0.5fr;
  }
  .study {
    .header {
      .topHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: 500px) {
          flex-direction: column;
        }
        h3 {
          font-family: 'Open Sans';
          font-weight: 600;
          font-size: 24px;
          line-height: 33px;
          color: #32325d;
          i {
            cursor: pointer;
          }
        }
        .chat {
          display: none;
          @media (max-width: 1350px) {
            display: inline-block;
          }
          i {
            color: #53c3f0;
            display: inline-block;
          }
        }
      }

      p {
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #32325d;
        @media (max-width: 500px) {
          max-width: 500px;
          word-break: break-word;
        }
      }
    }

    .documentGathering {
      margin-bottom: 32px;
    }
    .divider {
      border-top: 1px solid #969696;
      margin-bottom: 24px;
    }
  }

  .comment {
    width: 100%;
    @media (max-width: 1350px) {
      display: none;
    }
    h3 {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #32325d;
      i {
        cursor: pointer;
      }
    }
    .container {
      background-color: white;
      border-radius: 6px;
      display: block;

      .card-footer {
        background-color: transparent !important;
      }
    }
  }
  .supportingDocs {
    .card {
      text-align: center;
      .cardBody {
        .content {
          max-width: 230px;
          margin: 0 auto;
          p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            text-align: center;

            color: #525f7f;
            margin-bottom: 13px;
          }
          .button {
            width: 100%;
            border: 2px solid #5e72e4;
            border-radius: 4px;
            color: #5e72e4;
            span {
              margin-left: 5px;
              i {
                font-size: 10px;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}
.responsiveComments {
  display: none;
  background-color: white;
  position: absolute;
  right: 0px;
  z-index: 999;
  top: 80px;
  height: max-content;
  width: 45%;
  border: 1px solid;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  @media (max-width: 1350px) {
    display: block;
  }
}
