.modalContentWrapper {
  .modalBody {
    padding: 16px 28px 32px 28px;
    .description {
      margin-bottom: 24px;
      h3 {
        margin-bottom: 3px;
        font-family: 'Open Sans';
        font-weight: 700;
        font-size: 16px;
        line-height: 27px;
        color: #3c4d69;
      }
      p {
        margin-bottom: 0;
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        color: #3c4d69;
      }
    }
    .examples {
      h3 {
        margin-bottom: 3px;
        font-family: 'Open Sans';
        font-weight: 700;
        font-size: 16px;
        line-height: 27px;
        color: #3c4d69;
      }
      p {
        margin-bottom: 7px;
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        color: #3c4d69;
      }
      ul {
        list-style: none;
        padding-left: 20px;
        li {
          font-family: 'Open Sans';
          font-weight: 400;
          font-size: 16px;
          line-height: 27px;
          color: #0c85d0;
        }
      }
    }
  }
  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 5px 24px 11px 24px;
    .back {
      box-shadow: none !important;
      background: transparent;
      &:active {
        background-color: transparent;
      }
      &:hover {
        transform: none;
      }

      span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #4f4f4f;
        i {
          top: 0;
        }
      }
    }
    .next {
      min-width: 170px;
      border: 1px solid #d2e3ee;
      background: transparent;
      box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.5);
      border-radius: 4px;
      &:active {
        background: transparent;
        border: 1px solid #d2e3ee;
        box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.5);
      }
    }
  }
}
