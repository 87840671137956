.card {
  height: calc(100vh - 150px);
  margin-bottom: 0;
  overflow: auto;
  @media screen and (max-width: 576px) {
    &.showAgreement {
      height: 100%;
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.5);
    border-radius: 60px;
  }
  .cardBody {
    padding: 50px 36px 36px 36px;
    position: relative;
    @media screen and (max-width: 576px) {
      padding: 50px 16px 36px 16px;
    }
    .title {
      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: #32325d;
      }
    }
    .content {
      margin-bottom: 45px;
      color: #32325d;
      span {
        color: #32325d !important;
      }
      em {
        color: #32325d !important;
      }
      b {
        color: #32325d !important;
      }
      i {
        color: #32325d !important;
      }
      strong {
        color: #32325d !important;
      }
      ul {
        li {
          span {
            color: #32325d !important;
          }
          strong {
            color: #32325d !important;
          }
        }
      }
      blockquote {
        font-size: 24px;
        strong {
          font-weight: 600;
        }
      }
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #32325d;
        margin-bottom: 0;
      }
      .text {
        display: flex;
        gap: 12px;
        align-items: baseline;
        margin-bottom: 16px;
        i {
          color: #0ca8eb;
        }
      }
    }
    .stickyWrapper {
      @media screen and (min-width: 1200px) {
        padding: 20px 36px 0 36px;
        margin-left: -36px;
        margin-right: -36px;
        background: #fff;
        position: sticky;
        bottom: 0;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
      }
    }
    .checkbox {
      margin-bottom: 56px;
      :global {
        .custom-control-label {
          height: auto !important;
        }
      }
      .text {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #212519;
        user-select: none;
        a {
          text-decoration: underline;
          color: #2f80ed;
        }
      }
    }
    .buttons {
      display: grid;
      grid-template-columns: 1fr 4fr;
      gap: 16px;
      padding-bottom: 30px;
      @media screen and (max-width: 544px) {
        grid-template-columns: 1fr;
      }
      button {
        margin: 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.01em;
        box-shadow: none;
        border-radius: 56px;
        font-style: normal;
        @media screen and (max-width: 544px) {
          font-size: 20px;
        }
      }
      .decline {
        color: #525f7f;
        background: linear-gradient(268deg, #e3e3f0 6.06%, #dcdced 94.08%);
      }
      .agree {
        color: #ffffff;
        background: linear-gradient(91.05deg, #f7cb45 1.07%, #f08b34 99.1%);
      }
    }
    // .show {
    //   max-height: 100% !important;
    //   transition: max-height 0.3s ease-in-out;
    // }
    .agreement {
      color: #32325d;
      margin-bottom: 44px;
      max-height: 290px;
      overflow: hidden;
      position: relative;
      padding: 35px 32px 0px 32px;
      background: #f5f5f5;
      border: 1px solid #dfddea;
      box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      @media screen and (max-width: 576px) {
        padding: 16px;
      }
      .mainAgreement {
        color: #32325d;
        strong {
          color: #32325d !important;
        }
        span {
          color: #32325d !important;
        }
        em {
          color: #32325d !important;
        }
        b {
          color: #32325d !important;
        }
        i {
          color: #32325d !important;
        }
        ul {
          li {
            span {
              color: #32325d !important;
            }
            strong {
              color: #32325d !important;
            }
          }
        }
        blockquote {
          font-family: 'Open Sans';
          font-weight: 600;
          font-size: 24px;
          line-height: 33px;
          color: #32325d;
        }
        p {
          font-family: 'Open Sans';
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #32325d;
          margin-bottom: 0;
        }
      }

      &.show {
        max-height: fit-content;
        transition: max-height 0.6s ease-out;
      }

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: #32325d;
        margin-bottom: 24px;
      }
      .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #32325d;
        a {
          color: #2f80ed;
          text-decoration: underline;
        }
      }
      .terms {
        margin-bottom: 32px;
        .title {
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          color: #32325d;
          margin-bottom: 8px;
        }
        .details {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #32325d;
        }
      }
      .blur {
        height: 114px;
        background: linear-gradient(
          360deg,
          #f5f5f5 50%,
          rgba(245, 245, 245, 0) 131.76%
        );
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 0px;
      }
      .action {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        button {
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
          border-radius: 30px;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #32325d;
          padding: 5px 23px;
          margin-bottom: 12px;
        }
      }
    }
  }
}

.successAlertContent {
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #525f7f;
  }
  .profile {
    display: flex;
    margin-bottom: 24px;
    // align-items: baseline;
    justify-content: center;
    gap: 13px;
    .image {
      img {
        border: 3px solid #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        overflow: hidden;
      }
    }
    .details {
      h3 {
        text-align: left;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #212519;
        margin-bottom: 4px;
      }
      a {
        display: flex;
        gap: 10px;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #212519;
        margin-bottom: 3px;
      }
    }
  }
}
.cancelBtnStyle {
  background-color: red !important;
}
