.tabActivity {
  .itemContainer {
    display: flex;
    margin-bottom: 20px;

    .avatar {
      background: #53c3f0;
      color: white;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      font-size: 12px;
      img {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        object-fit: cover;
      }
    }

    .label {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.01em;
      color: #a2a0b4;
      margin-bottom: 7px;
    }

    .value {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #322d52;
      margin-bottom: 7px;
    }
  }

  .noDataWrapper {
    height: calc(100vh - 380px);
  }

  .imageWrapper {
    display: flex;
    width: 150px;
    height: 150px;
    background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
    border-radius: 50%;
    margin-bottom: 20px;
  }
  .defaultImage {
    max-width: 45px;
  }

  .defaultText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #cdcdcd;
  }
}
