.modal {
  .card {
    position: relative;
    margin-bottom: 0px;
    .cardHeader {
      border-bottom: none;
      padding: 16px 0 10px 24px;
      .backButton {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #525f7f;
        box-shadow: none;
        background: transparent;
        transform: none !important;
        padding: 0;
        &:active {
          box-shadow: none;
        }
        &:focus {
          box-shadow: none;
        }
        i {
          margin-right: 8px;
        }
      }
    }
    .cardBody {
      padding: 0px 36px 48px 36px;
      .title {
        p {
          text-align: center;
          font-weight: 400;
          font-size: 24px;
          line-height: 33px;
          color: #32325d;
        }
      }
      .optionButtons {
        max-width: 326px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 16px;
        button {
          width: 100%;
          margin: 0;
        }
      }
      .otherInput {
        max-width: 326px;
        margin: 0 auto;
        .input {
          margin-bottom: 16px;
          height: 41px;
        }
        .buttons {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 16px;
          .disabled {
            border: 1px solid #525f7f;
            background: transparent;
            color: #525f7f;
          }
        }
      }
    }
    .cardFooter {
      background-color: #f2f2f2;
      text-align: center;
      max-height: 0px;
      overflow: hidden;
      padding: 0;
      border: none;
      // display: none;
      &.showCardFooter {
        max-height: 300px;
        transition: all 0.3s ease-out;
        padding: 36px;
        // display: block;
      }
    }
  }
}

.alertContent {
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #525f7f;
  }
  .profile {
    display: flex;
    // align-items: baseline;
    justify-content: center;
    gap: 13px;
    .image {
      img {
        border: 3px solid #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        overflow: hidden;
      }
    }
    .details {
      h3 {
        text-align: left;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #212519;
        margin-bottom: 4px;
      }
      a {
        display: flex;
        gap: 10px;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #212519;
        margin-bottom: 3px;
      }
    }
  }
}
.thanksText {
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #32325d;
  text-align: center;
  margin-bottom: 0px;
}
