@import 'assets/scss/custom/_variables.scss';

.partnerTrackerDashboard {
  .header {
    margin-top: 17.5px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .title {
      font-weight: 700;
      font-size: 26px;
      line-height: 35px;
      /* Primary text (32325D) */
      color: #32325d;
    }
  }
  .infoWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 24px;
    margin-bottom: 2rem;
    div {
      min-width: 265px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      background: #ffffff;
      border-radius: 6px;
      padding: 20px;
      filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
        drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));

      cursor: pointer;
      @media screen and (max-width: 480px) {
        width: 100%;
      }
      p {
        margin-bottom: 0;
      }
      p:first-child {
        font-weight: 600;
        text-align: left;
        font-size: 14px;
        line-height: 18px;
        color: #32325d;
        width: 162px;
      }
      p + p {
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        text-align: right;
        margin-right: 10px;
        width: 85px;
        color: #32325d;
      }

      div:last-child {
        margin-right: 0;
      }
    }
  }
  .contentWrapper {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 32px;
    @media screen and (max-width: 1485px) {
      grid-template-columns: 1fr;
      display: block;
    }

    .baseTableWrapper {
      overflow: auto;
      .table {
        .referredTablePartner {
          .tableWrapper {
            overflow-x: auto;
            border-radius: 6px;
            max-height: 700px;
            overflow-y: auto;
          }
          table {
            min-width: 1200px;
            border-bottom: 0px !important;
            * {
              td {
                vertical-align: middle;
                padding-right: 0.5rem;
              }
            }
            thead {
              border-left: 5px solid #e8e8e8;
              height: 44px;
              width: calc(100% - 1em);
              position: -webkit-sticky;
              position: sticky;
              top: 0;
              z-index: 99;
              tr {
                display: table;
                width: 100%;
                table-layout: fixed;
                th {
                  padding: 14.5px 20px;
                  background-color: #e8e8e8;
                  font-weight: 700;
                  font-size: 12px;
                  color: #000000;
                  text-align: left;
                }
              }
            }
            tfoot {
              border-left: 5px solid #e8e8e8;
              height: 44px;
              width: calc(100% - 1em);
              position: -webkit-sticky;
              position: sticky;
              bottom: 0;
              tr {
                display: table;
                width: 100%;
                table-layout: fixed;
                th {
                  padding: 12.5px 20px;
                  text-align: left;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 30px;
                  background-color: #e8e8e8;
                  color: #000000;
                }
              }
            }
            tbody {
              tr {
                display: table;
                width: 100%;
                table-layout: fixed;
                td {
                  padding: 8.5px 20px;
                  text-align: left;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 30px;
                }
              }
            }
          }
          .tableHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            gap: 16px;
            @media screen and (max-width: 480px) {
              flex-direction: column;
              flex-direction: column;
              align-items: baseline;
            }

            .title {
              font-weight: 700;
              font-size: 26px;
              line-height: 35px;
              color: #000000;
            }
            .search {
              display: flex;
              align-items: center;
              gap: 10px;
              @media screen and (max-width: 480px) {
                width: 100%;
              }
              .icon {
                cursor: pointer;
              }
              button {
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
              }
              .input {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #cdcdcd;
              }
            }
          }
          .card {
            overflow: hidden;
            border-radius: 6px;
          }
        }
      }
    }
    .statusAndActivity {
      display: grid;
      gap: 32px;
      grid-template-columns: 1fr;
      @media screen and (max-width: 1485px) {
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }
      .partnerStatus {
        .cardWrapper {
          filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
            drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
          margin-bottom: 0;
        }
        .pieChart {
          padding: 22px 24px;
          margin-bottom: 0;
          box-shadow: none;
          overflow-x: auto;

          p {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #32325d;
          }
        }
      }
      .partnerActivity {
        .activityCard {
          margin-bottom: 0;
          box-shadow: none;
          max-height: 30rem;
          overflow-y: auto;
          filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
            drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
          h3 {
            font-weight: 600;
            font-size: 17px;
            line-height: 23px;
            color: #000000;
          }
          .recentActivities {
            .activity {
              display: flex;
              align-items: baseline;
              gap: 10px;
              .content {
                margin-bottom: 24px;
                .title {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19px;
                  color: #525f7f;
                  margin-bottom: 3px;
                }

                .timestamp {
                  font-weight: 700;
                  font-size: 10px;
                  line-height: 14px;
                  color: #525f7f;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
.tooltipCustom {
  :global {
    .tooltip.show {
      opacity: 1;
    }
    .tooltip-inner {
      max-width: 331px !important;
      padding: 16px;
    }
  }
  .tooltipPoints {
    display: flex;
    align-items: baseline;
  }
  .hide {
    display: none !important;
  }

  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 10px;
    text-align: left;
    .bold {
      font-weight: 600;
    }
  }
}
.noDataWrapper {
  border-radius: 6px;
  overflow: hidden;
}

.emptyStateHeader {
  height: 47px;
  background: #e8e8e8;
  width: 100%;
  margin-bottom: 20px;
}

.noData {
  .iconWrapper {
    background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
    opacity: 0.4;
    padding: 16px;
    border-radius: 50%;
    i {
      font-size: 25px;
    }
  }
}
.defaultText {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #cdcdcd !important;
}

.earningsInfoTooltip {
  background: #ffffff;
  padding: 8px 12px;
  filter: drop-shadow(0px 1px 2px rgba(50, 50, 93, 0.1))
    drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #525f7f;
    text-align: left;
    margin-bottom: 0;
  }
}
