.editor {
  :global {
    .ql-toolbar {
      border-radius: 0px;
      background-color: #f6f9fc;
      position: relative;
    }
    .ql-editor {
      min-height: 260px;
      color: #000;
      font-family: 'Open Sans';
      padding-right: 45px;
      border-radius: 0px;
      box-shadow: none;
      line-height: normal;
    }
    .ql-formats {
      border-right: 1px solid #e9ecef;
    }
    .ql-align {
      margin-right: 15px;
    }
    .ql-picker-label {
      border: none !important;
    }
  }
}

.toolbarSection {
  .pin {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 10;
    @media (max-width: 417px) {
      top: 75px;
    }

    .pinButton {
      position: absolute;
      right: 10px;
      padding: 0px;
      box-shadow: none;
      background: transparent;
      &:active {
        background-color: transparent !important;
        box-shadow: none !important;
      }
      svg {
        height: 28px !important;
        width: 28px !important;
      }
    }
  }
}
.modalWrapper {
  max-width: 673px;
  .footer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 13px 17px;
    .cancelBtn {
      background-color: transparent;
      color: #1da1f2;
      font-size: 14px;
      font-weight: 600;
      padding: 7px;
      box-shadow: none !important;
      border: none;
      &:active {
        color: #1da1f2 !important;
      }
    }
    .postNoteBtn {
      background-color: rgba(29, 161, 242, 1);
      border-radius: 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      padding: 7px 20px;
      box-shadow: none !important;
      &:active {
        background-color: #1da1f2 !important;
        color: #fff !important;
      }
    }
  }
}
