@import 'assets/scss/custom/_variables.scss';
@import './BaseStyle.module.scss';

@mixin generateBackgroundColor($base-color) {
  .icon {
    background-color: $base-color;
  }
  .title {
    color: $base-color;
  }
}

.option {
  cursor: pointer;
  .icon {
    color: $white;
    font-size: 2.375rem !important;
    align-self: stretch;
    width: 4.6875rem !important;
  }
  &.administrator {
    @include generateBackgroundColor($administratorColor);
  }
  &.financialCollaborator {
    @include generateBackgroundColor($financialCollaboratorColor);
  }
  &.masterCollaborator {
    @include generateBackgroundColor($masterCollaboratorColor);
    .icon {
      font-size: 1.5rem !important;
    }
  }
  &.basicTeamMember {
    @include generateBackgroundColor($basicTeamMemberColor);
  }
  &.trustedTeamMember {
    @include generateBackgroundColor($trustedTeamMemberColor);
  }
  &.projectManager {
    @include generateBackgroundColor($projectManagerColor);
  }
  &.companyAdministrator {
    @include generateBackgroundColor($companyAdministratorColor);
  }
  &.partnerAdministrator {
    @include generateColor($partnerAdministratorColor);
  }
  &.partnerManager {
    @include generateColor($partnerManagerColor);
  }
}

.textWrap {
  white-space: pre-wrap;
}

.control {
  padding: 0rem !important;
  border-radius: 0.25rem;
  height: 6.75rem;
  // line-height: 7rem;
  .valueContainer {
    padding: 0;
    height: 100%;
    border-radius: inherit;
    .singleValue {
      margin: 0 !important;
      align-self: stretch !important;
      border-radius: inherit;
      height: 100%;
    }
  }
}

.descriptionCharacterCount {
  bottom: 1.5rem;
  right: 1.5rem;
}

.noteFieldLabel {
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
}

.noteField {
  resize: none;
}

.permissionListContainer {
  .administrator {
    @include generatePaneColor($administratorColor);
  }
  .financialCollaborator {
    @include generatePaneColor($financialCollaboratorColor);
  }
  .masterCollaborator {
    @include generatePaneColor($masterCollaboratorColor);
  }
  .basicTeamMember {
    @include generateBackgroundColor($basicTeamMemberColor);
  }
  .trustedTeamMember {
    @include generateBackgroundColor($trustedTeamMemberColor);
  }
  .projectManager {
    @include generateBackgroundColor($projectManagerColor);
  }
  .companyAdministrator {
    @include generateBackgroundColor($companyAdministratorColor);
  }
  .partnerAdministrator {
    @include generateColor($partnerAdministratorColor);
  }
  .partnerManager {
    @include generateColor($partnerManagerColor);
  }
}

.col-auto > button#toggler {
  color: #53c3f0 !important;
  font-family: Nunito Sans !important;
}
