@import 'assets/scss/custom/_variables.scss';

.title {
  word-break: break-word;
  white-space: normal;
  line-height: normal;
}
.description {
  word-break: break-word;
  white-space: normal;
  padding-left: 25px;
}
.label {
  i {
    margin-top: 5px;
  }
}
.optionWrapper {
  :global {
    .custom-radio .custom-control-input ~ .custom-control-label {
      height: unset;
    }
  }
}
:global {
  .rselect__multi-value {
    min-width: fit-content !important;
  }
  .rselect__control.rselect__control--is-disabled {
    cursor: not-allowed;
    pointer-events: all;
    .rselect__multi-value--is-disabled {
      background: unset;
    }
  }
  .rselect__dropdown-indicator {
    display: unset !important;
  }
}
.disabled {
  background: $gray-300;
  cursor: not-allowed;
  :global {
    .custom-checkbox .custom-control-input ~ .custom-control-label {
      cursor: not-allowed;
    }
  }
}
