.row {
  width: 100%;
  margin: 0;
  display: flex;
  flex: 1;
  align-items: baseline;

  :global {
    textarea {
      width: 100%;
    }
  }

  .assignments {
    margin: 8px 0 18px;
    color: #32325d;
    font-weight: 400;

    p {
      margin: 0;
    }
  }

  .hoverHand {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #32325d;
    margin-left: 6px;
    &:hover {
      cursor: pointer;
    }
  }

  .referred_by {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #32325d;
    margin-left: 6px;
  }

  .textInputRightMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 12px;

    :global {
      .rselect__control {
        height: calc(0.5em + 1.25rem + 5px);
      }
    }
    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  .textInputLeftMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;

    :global {
      .rselect__control {
        height: calc(0.5em + 1.25rem + 5px);
      }
    }
    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  .textInputMiddleMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;
    :global {
      .rselect__control {
        height: calc(0.5em + 1.25rem + 5px);
      }
    }
    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  .textInputAddress {
    display: flex;
    flex: 2;
    flex-direction: column;
    margin-right: 12px;
    :global {
      .rselect__control {
        height: calc(0.5em + 1.25rem + 5px);
      }
    }
    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: initial;
    :global {
      .rselect__control {
        margin-bottom: 16px;
      }
    }
    .textInputRightMargin {
      margin-right: 0;
    }
    .textInputLeftMargin {
      margin-left: 0;
    }
    .textInputMiddleMargin {
      margin-right: 0;
      margin-left: 0;
    }
    .textInputAddress {
      margin-right: 0;
    }
  }
}
