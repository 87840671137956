.profile {
  display: flex;
  margin-bottom: 24px;
  // align-items: baseline;
  justify-content: center;
  gap: 13px;
  .image {
    img {
      border: 3px solid #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      overflow: hidden;
      width: 98px;
      height: 92px;
      object-fit: contain;
    }
  }
  .details {
    h3 {
      text-align: left;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #212519;
      margin-bottom: 4px;
    }
    a {
      user-select: none;
      cursor: pointer;
      display: flex;
      gap: 10px;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #212519;
      margin-bottom: 3px;
    }
  }
}
