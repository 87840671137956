@import 'assets/scss/custom/_variables.scss';
.modal {
  max-width: 32em;
  .infoIcon {
    width: 80px;
    height: 80px;
    border: none;
    border-radius: 0px;
    margin: 20px auto;
    position: relative;
    box-sizing: content-box;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/img/icons/info-popup-icon.svg');
  }
  h2 {
    position: relative;
    max-width: 100%;
    margin: 0px 0px 0.4em;
    padding: 0px;
    color: rgb(89, 89, 89);
    font-size: 1.875em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    overflow-wrap: break-word;
  }
  .para {
    z-index: 1;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    color: rgb(84, 84, 84);
    font-size: 1.125em;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    overflow-wrap: break-word;
  }
  .creditReleaseDate {
    text-align: center;
  }
  .modalHeader {
    display: none;
  }
  .dateWrapper {
    position: relative;
  }
  .icon {
    position: absolute;
    font-size: 20px;
    top: 30%;
    right: 4%;
    color: #a2a0b4;
  }
  .dragContainerCancelBtnStyle {
    color: #32325d;
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
    border: 0px solid #ffffff;
    background-color: #ffffff;
    box-shadow: none !important;
    &:hover {
      color: #000000;
      border: 0px solid #ffffff;
      background-color: #ffffff;
      box-shadow: none !important;
    }
    &:active {
      background-color: #ffffff !important;
      color: #000000 !important;
      border-color: #ffffff !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }

  .dragContainerConfirmBtnStyle {
    color: #fff;
    text-align: center;
    /* H4 Mobile - 14px/SemiBold */
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.28px;
    border-radius: 4px;
    background: #172b4d;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08),
      0px 4px 6px 0px rgba(50, 50, 93, 0.11) !important;
    border-color: transparent !important;
    &:hover {
      color: #fff;
      background: #172b4d;
    }
    &:active {
      color: #fff;
      background: #172b4d;
    }
  }
  .buttons {
    display: flex;
    z-index: 1;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 1.25em auto 0px;
  }
}
