@import 'assets/scss/custom/_variables.scss';

.contracts {
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .contractName {
    vertical-align: middle !important;
    word-break: normal;
    @media (max-width: 992px) {
      :global {
        .card-body {
          overflow-y: scroll;
        }
      }
    }
    a {
      display: block;
      //width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: unset;
    }
  }

  .search {
    display: flex;
    align-items: center;
    gap: 7px;
    color: #525f7f;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .headerButton {
    background: linear-gradient(90deg, #5e72e4 0%, #825ee4 100%);
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    padding: 10px;
    height: 43px;
    width: 185px;
    color: #fff;
    outline: none;
    border-color: transparent;
    &:active {
      color: #fff !important;
    }
  }

  :global {
    // .react-bootstrap-table .table {
    //   border-bottom: none;
    // }
    .react-bootstrap-table.table-responsive {
      min-height: 325px;
      //   min-height: 150px;

      // This is to apply ellipsis to the table-cells, if we do not use this it will go beyond the width
      table {
        table-layout: fixed;
        // tbody {
        //   :last-child {
        //     border: none;
        //   }
        // }
      }
      .react-bs-table-no-data {
        white-space: normal;
        text-align: center;
      }

      @media (max-width: 992px) {
        table {
          width: auto;
        }
      }
    }
  }
}
.noData {
  :global {
    .react-bootstrap-table.table-responsive {
      min-height: 325px;
      table {
        table-layout: fixed;
        margin-bottom: 0;
      }
    }
  }
}

.noDataWrapper {
  min-height: 300px;
  border-radius: 6px;
  overflow: hidden;
}

.noData {
  margin-bottom: 20px;
  .iconWrapper {
    background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
    opacity: 0.4;
    padding: 50px 56px;
    border-radius: 50%;
    i {
      font-size: 25px;
    }
  }
}
.defaultText {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #cdcdcd;
}
