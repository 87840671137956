.heading {
  font-size: 1.875rem;
}

.disabledIntegration {
  pointer-events: none;
  opacity: 0.5;
}

.cardBody {
  .title {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    color: #322d52;
  }
  .bodyText {
    font-size: 16px;
    width: 90%;
    line-height: 22px;
    color: #32325d;
    a {
      color: #32325d;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .footer {
    background: #f7fafc;
    padding: 19px 30px;
    .footerText {
      font-size: 14px;
      line-height: 24px;
      color: #525f7f;
      span {
        color: #2d9cdb;
        margin-left: 5px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}
