@import 'assets/scss/custom/_variables.scss';

.documentModalWrapper {
  @media (max-width: 1200px) {
    min-width: 90%;
  }

  max-width: 1208px;

  :global {
    .modal-title {
      color: $gray-800;
      font-weight: 600;
      font-size: 17px;
    }
    .modal-content {
      min-height: 95% !important;
      overflow: visible;
    }
  }

  .document-modal {
    .fileName {
      overflow: hidden;
      color: #525f7f;
      text-overflow: ellipsis;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .tData {
      color: #172b4d;
      font-family: Open Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .dropdownMenu {
        :global {
          .dropdown-item {
            padding: 8px 16px;
            display: flex;
            align-items: center;
          }
        }
        .downloadText {
          span {
            color: #3c4d69;
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          svg {
            padding-left: 0;
            margin-right: 4px;
          }
        }
        .deleteText {
          font-family: Open Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $danger;
          i {
            margin-right: 4px;
            font-size: 12px;
            // padding-left: 3px;
          }
        }
      }
    }
    :global {
      .row-expansion-style {
        padding: 0 !important;
      }
      .table thead th {
        color: #8898aa;
        font-family: Open Sans;
        text-transform: capitalize;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px; /* 120% */
        letter-spacing: 0.9px;
      }
      .table th {
        padding: 16px;
        vertical-align: baseline;
      }

      .table td {
        vertical-align: middle;
        font-size: 14px;
        color: $gray-800;
        padding: 0;
      }
      .table tr {
        .fileName {
          overflow: hidden;
          color: #525f7f;
          text-overflow: ellipsis;
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .table .table-data {
        border-radius: 2.591px;
        border-top: 1px solid #d2e3ee;
        border-bottom: 1px solid #d2e3ee;
        min-height: 49px;
      }

      .table tbody tr td {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 0px;
        padding-right: 0px;
      }
      .expand-cell {
        i {
          border-radius: 2.591px;
          border-top: 1px solid #d2e3ee;
          border-bottom: 1px solid #d2e3ee;
          border-left: 1px solid #d2e3ee;
          min-height: 49px;
          vertical-align: inherit;
          display: flex;
          align-items: center;
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
      }

      .table tr :first-child .table-data {
        border-left: 1px solid #d2e3ee;
      }
      .table tr :last-child .table-data {
        border-right: 1px solid #d2e3ee;
      }

      .table tbody tr :first-child {
        padding-left: 4px;
      }
      .table tbody tr :last-child {
        padding-right: 4px;
      }
    }

    .modalWrapper {
      @media (max-width: 768px) {
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .fileTable {
      &::-webkit-scrollbar {
        background-color: transparent;
        opacity: 0.9;
        width: 4px;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $scrollbar;
        border-radius: 6px;
        transition: opacity 0.2s, background-color 0.2s linear,
          width 0.2s ease-in-out;
        -webkit-transition: opacity 0.2s, background-color 0.2s linear,
          width 0.2s ease-in-out;
        width: 4px;
      }
      width: 100%;

      .table {
        &::-webkit-scrollbar {
          background-color: transparent;
          opacity: 0.9;
          width: 4px;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $scrollbar;
          border-radius: 6px;
          transition: opacity 0.2s, background-color 0.2s linear,
            width 0.2s ease-in-out;
          -webkit-transition: opacity 0.2s, background-color 0.2s linear,
            width 0.2s ease-in-out;
          width: 4px;
        }
        max-height: calc(100vh - 273px);
        overflow: auto;
      }
      .modalHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        .folderTitle {
          color: #172b4d;
          font-weight: 600;
          font-size: 17px;
          line-height: 23px;
          i {
            margin-right: 8px;
          }
        }
        .actions {
          display: flex;
          align-items: center;
          gap: 15px;
          .adminAction {
            display: flex;
            align-items: center;
            gap: 12px;
            button {
              border-radius: 4px;
              border: 1px solid #dcdde5;
              background: #f8fbfc;
              display: inline-flex;
              padding: 6px 12px;
              align-items: flex-start;
              color: #172b4d;
              text-align: center;
              font-family: Open Sans;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: 0.24px;
              &:hover {
                box-shadow: 0 0px 0px rgba(50, 50, 93, 0.1),
                  0 3px 6px rgba(0, 0, 0, 0.08);
                transform: translateY(-1px);
              }
            }
            .previewButton {
            }
            .downloadAllButton {
            }
          }
          .viewButton {
            border-left: 1px solid #dcdde5;
            padding-left: 15px;
            button {
              border-radius: 4px;
              border: 1px solid #dcdde5;
              background: #f8fbfc;
              display: inline-flex;
              padding: 6px 12px;
              align-items: flex-start;
              color: #172b4d;
              text-align: center;
              font-family: Open Sans;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: 0.24px;
              &:hover {
                box-shadow: 0 0px 0px rgba(50, 50, 93, 0.1),
                  0 3px 6px rgba(0, 0, 0, 0.08);
                transform: translateY(-1px);
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        width: 100%;
        overflow: auto;
      }

      .cursor {
        cursor: pointer;
      }
    }

    .deliverableTable {
      width: 100%;
      @media (max-width: 768px) {
        overflow: auto;
      }

      .cursor {
        cursor: pointer;
      }
    }

    .comment {
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .loading {
      z-index: 999;
      height: 600px !important;
    }
    @media (max-width: 768px) {
      flex-direction: column !important;
      flex-wrap: unset !important;
    }

    // .fileName {
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   max-width: 400px;
    //   white-space: nowrap;
    //   cursor: pointer;
    // }

    .tableHeader {
      background: white;
    }

    .scrollBar {
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      width: 60%;
      height: 600px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .scrollBar::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    p {
      margin-bottom: 0;
    }

    .owner-popover {
      margin: 0;
      .owner-popover-body {
        width: 16rem;
        padding: 0.5rem;
        .owner-selector {
          :global {
            .rselect__menu {
              margin-top: 0.125rem;
              box-shadow: 0rem 2rem 2rem 0rem rgba(0, 0, 0, 0.2);
              width: 16rem;
              display: flex;
              justify-content: center;
              left: -0.5rem;
              border-radius: 0 0 0.4375rem 0.4375rem;
            }
            .rselect__menu-list {
              max-height: 15rem;
              width: 100%;
              margin: 0 0.5rem;
            }
            .rselect__option:hover,
            .rselect__option--is-focused,
            .rselect__option--is-selected {
              background-color: $lyght-blue;
              color: $white;
              border-radius: 0.375rem;
            }
          }
        }
      }
    }

    h4 {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    .hover-hand {
      &:hover {
        cursor: pointer;
      }
    }

    .dropdown-wrapper {
      max-width: 100%;
      > button {
        max-width: 100%;
      }
    }

    .dropdown-menu {
      max-width: 12.5rem;
      padding: 0.5rem 0.75rem;
    }

    .result-wrapper {
      margin-top: 0.5rem;
      max-height: 12.5rem;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .toggle {
      padding: 0;
      border: unset;
      background: unset;
      box-shadow: unset;
      &:hover,
      &:focus {
        box-shadow: none !important;
        transform: unset !important;
        border: unset !important;
        background: unset !important;
      }
    }

    .result-item {
      cursor: pointer;
      padding: 0.25rem;
      border-radius: 6px;
      &.active {
        background: $lyght-blue;
        color: $white;
      }
      &:hover {
        background: $lyght-blue;
        color: $white;
      }
    }
    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .left-column {
      color: $gray-700;
      .epic-badge {
        border-radius: 10rem !important;
        max-width: 100% !important;
        transition: all 0.3s;
        cursor: default;
        .close-icon {
          display: none;
        }
      }

      .allow-epic-edit {
        cursor: pointer;
        &:hover {
          .close-icon {
            transition: all 0.3s;
            display: inline-block;
          }
        }
      }

      .description {
        height: max-content;
        border-color: transparent;
        border-radius: 0.5rem;
        img {
          max-width: 100%;
          cursor: pointer;
        }
      }

      .empty-description {
        height: 9rem !important;
      }
      .empty-content {
        background-color: $gray-100;
      }
    }
  }
  .document-modal-mobile {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
  }
}

.addDescription {
  padding: 16px 0 0 16px;
  font-size: 16px;
  color: #ced4d9;
  background: #f7f9fc;
  border-radius: 6px;
  height: 124px;
}

.expandedTable {
  .first-expand-cell {
    padding: 0 !important;
    width: 48px;
    // min-width: 48px;
    // display: block;
  }
  .expand-name-cell {
    width: 25%;
    div {
      overflow: hidden;
      color: #525f7f;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .expand-size-cell {
    width: 20%;
  }
  .expand-date-created-cell {
    width: 20%;
    // padding-left: 18px !important;
  }
  .expand-date-download-cell {
    width: 20%;
  }
  .expand-download-cell {
    width: 300px;
    :global {
      .dropdown-menu {
        min-width: auto;
        padding: 0px !important;
        button {
          padding: 8px 16px !important;
        }
      }
    }
  }
  //

  td > div {
    border-radius: 2.591px;
    border-top: 1px solid #d2e3ee;
    border-bottom: 1px solid #d2e3ee;
    min-height: 49px !important;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #172b4d;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  tr :first-child > div {
    border-left: 1px solid #d2e3ee;
    padding-right: 10px !important;
  }
  tr :last-child > div {
    justify-content: right;
    padding-right: 14px !important;
    border-right: 1px solid #d2e3ee;
  }
  tr :last-child {
    padding-right: 0 !important;
  }
}
