@import 'assets/scss/custom/_variables.scss';
.wrapper {
  .cardHeader {
    h3 {
      color: #32325d;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px; /* 168.75% */
    }
  }
  .cardBody {
    max-height: 333px;
    overflow: auto;
    .tableWrapper {
      overflow: visible;
      min-height: 10.5rem;
      table {
        // table-layout: fixed;
        * {
          td {
            white-space: nowrap !important;
            max-width: 7.75rem;
            vertical-align: middle;
            padding-top: 24px;
            padding-bottom: 24px;
          }
        }
        tbody {
        }
        .row {
          cursor: pointer;
        }
        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
