@import 'assets/scss/custom/_variables.scss';

.breadcrumb {
  .name {
    color: $gray-900;
  }
}
.leftIcon {
  cursor: pointer;
}
