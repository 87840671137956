@import 'assets/scss/custom/_variables.scss';
.interactionWrapper {
  cursor: pointer;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  background: #fff;
  padding: 16px;
  margin-bottom: 16px;

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
      color: #32325d;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .content {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1.7fr;
    justify-items: right;
    gap: 40px;
    justify-self: end;

    @media (max-width: 768px) {
      gap: 20px;
      grid-template-columns: 1fr;
    }
    .counts {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      justify-items: right;
      width: 110px;
      @media (max-width: 768px) {
        display: none;
      }
      div {
        display: flex;
        align-items: center;
        gap: 4px;

        span {
          text-align: right;
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .doc {
        span {
          background: linear-gradient(140deg, #fb6640 0%, #faab41 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        svg {
          width: 24px;
          height: 24pxs;
        }
      }
      .msg {
        span {
          background: linear-gradient(134deg, #9c79e4 0%, #b965e0 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        svg {
          width: 24px;
          height: 24pxs;
        }
      }
    }
    .date {
      display: flex;
      align-items: center;
      gap: 10px;
      white-space: nowrap;
      p {
        color: #8898aa;
        text-align: right;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }
    }
  }
}
