@import 'assets/scss/custom/_variables.scss';
.selector {
  p {
    margin: 0;
  }

  :global {
    .rselect__menu {
      width: 15rem;
    }
    .rselect__multi-value:hover {
      .rselect__multi-value__remove {
        visibility: visible;
      }
    }
    .rselect__multi-value__remove {
      visibility: hidden;
      &:hover {
        color: inherit !important;
        background-color: inherit !important;
      }
    }
  }
}
