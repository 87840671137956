.container {
  display: flex;
  height: 76px;
  min-width: 800px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #d2e3ee;
  background: #fff;
  cursor: pointer;

  .cardData {
    gap: 24px;
    height: 44px;
    flex-shrink: 0;
    margin: 16px 0px 16px 24px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex: 1;

    .dataWrapper {
      display: flex;
      justify-content: space-between;
      flex: 1;
      gap: 20px;

      .leftWrapper {
        display: flex;
        gap: 16px;
        flex: 1;
      }

      .rightWrapper {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        flex: 2;

        .statusWrapper {
          display: flex;
          gap: 24px;
          align-items: center;
          justify-content: space-between;
          flex: 1;
        }
      }
    }

    .rightArrowIcon {
      margin-right: 20px;
    }

    .documentTitle {
      display: flex;
      height: 44px;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      color: var(--card-heading-32325-d, #32325d);
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .statusContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 6px;
      height: 44px;
      flex: 1;

      .yearWrapper {
        display: flex;
        gap: 8px;
        overflow: auto;
        max-width: 165px;
        padding-bottom: 4px;
        &::-webkit-scrollbar {
          margin-top: 4px;
          background-color: transparent;
          opacity: 0.9;
          height: 4px;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #dee2e6;
          border-radius: 6px;
          transition: opacity 0.2s, background-color 0.2s linear,
            width 0.2s ease-in-out;
          -webkit-transition: opacity 0.2s, background-color 0.2s linear,
            width 0.2s ease-in-out;
          width: 4px;
        }
        &::-webkit-scrollbar {
          opacity: 0;
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          opacity: 0;
          width: 6px;
        }
      }

      .status {
        font-family: 'Open Sans';
        text-transform: capitalize;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        color: #32325d;

        &.new_doc {
          color: #32325d;
          border: 1px solid #32325d;
        }
        &.not_started {
          color: #32325d;
          border: 1px solid #32325d;
        }

        &.docs_submited {
          color: #037fbe;
          border: 1px solid #037fbe;
        }

        &.in_review {
          color: #e9b411;
          border: 1px solid #e9b411;
        }

        &.needs_attention {
          color: #ec0c38;
          border: 1px solid #ec0c38;
        }

        &.ready_for_review {
          color: #1da1f2;
        }
        &.completed {
          color: #24a46d;
          border: 1px solid #24a46d;
        }
        &.ready_for_review {
          color: #04a0f0;
          border: 1px solid #04a0f0;
        }
      }

      .statusBadge {
        text-transform: capitalize;
        background: #fff;
      }
      .year {
        padding: 2px 12px;
        font-family: 'Open Sans';
        margin-bottom: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 19px;
        &.none {
          color: #d2e3ee;
          border: 1px solid #d2e3ee;
        }
        &.partial {
          color: #fc7c5f;
          border: 1px solid #fc7c5f;
        }
        &.complete {
          background-color: #6fcf97;
        }
      }
      .statusText {
        color: var(--card-heading-32325-d, #32325d);
        font-family: Open Sans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.11px;
        text-transform: uppercase;
      }

      .docNo {
        color: var(--card-heading-32325-d, #32325d);
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
.container:only-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: 16px;
}
