.greeting {
  background-color: #172b4d;
  .text {
    margin: 24px 65px;
    &.mobileMargin {
      margin: 16px;
    }
    h1 {
      color: #fff;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      color: #fff;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
