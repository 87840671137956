.tabELReview {
  .label {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #32325d;
    margin-bottom: 7px;
    display: flex;
    cursor: pointer;
    img[action='button'] {
      margin-left: 5px;
      visibility: hidden;
    }

    &:hover {
      img[action='button'] {
        visibility: visible;
      }
    }
  }

  .value {
    // margin-left: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: #212519;
    margin-bottom: 7px;
  }
}
