@import 'assets/scss/custom/_variables.scss';

.root {
  &.disabled {
    .control {
      background-color: $input-disabled-bg;
    }
  }

  position: relative;
  .control {
    width: 100%;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    font-size: $input-btn-font-size;
    padding: 5px;
    color: $input-color;
    height: 46px !important;
    display: flex;
    align-items: center;
    .value {
      color: $input-color;
      padding: 0px 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .placeholder {
      color: $input-placeholder-color;
      padding: 0px 10px;
    }

    &.open {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  .menu {
    display: none;

    &.open {
      display: block;
    }

    z-index: $zindex-dropdown;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 46px;
    border-left: 1px solid $input-border-color;
    border-right: 1px solid $input-border-color;
    border-bottom: 1px solid $input-border-color;
    background-color: $white;

    border-bottom-left-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;

    padding: 15px;
    .menuSearch {
      input {
        height: 44px;
        margin-bottom: 10px;
      }

      margin-bottom: 10px;
      border-bottom: 1px solid $input-border-color;

      :global {
        .btn-link {
          color: $black;
          display: block;
          padding: 0px;
          margin: 0 0 10px 0;
        }
      }
    }

    .menuList {
      font-size: $input-btn-font-size;
      overflow-y: auto;
      max-height: 120px;

      .option {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        input {
          margin-right: 15px;
        }
      }

      & :last-child {
        margin-bottom: 0px;
      }
    }
  }
}
