@import 'assets/scss/custom/_variables.scss';

.story-modal {
  .loading {
    z-index: 999;
    height: 600px !important;
  }
  @media (max-width: 768px) {
    flex-direction: column !important;
    flex-wrap: unset !important;
  }
  .scrollBar {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    width: 60%;
    height: 600px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .scrollBar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  p {
    margin-bottom: 0;
  }

  .owner-popover {
    margin: 0;
    .owner-popover-body {
      width: 16rem;
      padding: 0.5rem;
      .owner-selector {
        :global {
          .rselect__menu {
            margin-top: 0.125rem;
            box-shadow: 0rem 2rem 2rem 0rem rgba(0, 0, 0, 0.2);
            width: 16rem;
            display: flex;
            justify-content: center;
            left: -0.5rem;
            border-radius: 0 0 0.4375rem 0.4375rem;
          }
          .rselect__menu-list {
            max-height: 15rem;
            width: 100%;
            margin: 0 0.5rem;
          }
          .rselect__option:hover,
          .rselect__option--is-focused,
          .rselect__option--is-selected {
            background-color: $lyght-blue;
            color: $white;
            border-radius: 0.375rem;
          }
        }
      }
    }
  }

  h4 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .hover-hand {
    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-wrapper {
    max-width: 100%;
    > button {
      max-width: 100%;
    }
  }

  .dropdown-menu {
    max-width: 12.5rem;
    padding: 0.5rem 0.75rem;
  }

  .result-wrapper {
    margin-top: 0.5rem;
    max-height: 12.5rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .toggle {
    padding: 0;
    border: unset;
    background: unset;
    box-shadow: unset;
    &:hover,
    &:focus {
      box-shadow: none !important;
      transform: unset !important;
      border: unset !important;
      background: unset !important;
    }
  }

  .result-item {
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 6px;
    &.active {
      background: $lyght-blue;
      color: $white;
    }
    &:hover {
      background: $lyght-blue;
      color: $white;
    }
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .left-column {
    color: $gray-700;
    .epic-badge {
      border-radius: 10rem !important;
      max-width: 100% !important;
      transition: all 0.3s;
      cursor: default;
      .close-icon {
        display: none;
      }
    }

    .allow-epic-edit {
      cursor: pointer;
      &:hover {
        .close-icon {
          transition: all 0.3s;
          display: inline-block;
        }
      }
    }

    .description {
      height: max-content;
      border-color: transparent;
      border-radius: 0.5rem;
      img {
        max-width: 100%;
        cursor: pointer;
      }
    }

    .empty-description {
      height: 9rem !important;
    }
    .empty-content {
      background-color: $gray-100;
    }
  }
}
.story-modal-mobile {
  flex-direction: column !important;
  flex-wrap: nowrap !important;
}
.storyDropdown {
  button {
    background: #e4e5ed;
    &:hover {
      background: $white;
      color: $gray-800;
    }
  }
}
.addDescription {
  padding: 16px 0 0 16px;
  font-size: 16px;
  color: #ced4d9;
  background: #f7f9fc;
  border-radius: 6px;
  height: 124px;
}
