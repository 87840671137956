@import 'assets/scss/custom/_variables.scss';
.invalid {
  height: 275px;
  border-radius: $login-card-border-radius;
  background-color: $login-card-bg-color;
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    h4 {
      font-weight: bold;
    }
    .avatar {
      width: 140px;
      height: 140px;
    }
  }
}
