@import 'assets/scss/custom/_variables.scss';

.upgradeModalRoot {
  position: relative;
  background: linear-gradient(240.71deg, #f38607 0%, #b659ff 100%);

  &::after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    height: 180px;
    background-image: url('../../assets/img/theme/waves.png');
  }

  h2 {
    font-style: normal;
    font-weight: bold !important;
    font-size: 22px !important;
    line-height: 30px;
    display: flex;
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    color: #ffffff !important;
    margin-bottom: 16px !important;
  }

  .messageBody {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative !important;
  }

  .text {
    color: white;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 40px;

    b {
      font-weight: bold;
    }
  }

  .upgradeButton {
    font-size: 14px;
    color: $lyght-blue;
    font-weight: bold;
  }

  .noThanksText {
    font-size: 12px;
    text-decoration: underline;
    margin-top: 15px;
    color: white;
    cursor: pointer;
  }

  .contactText {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 15px;
    color: white;
    font-weight: normal;
    img {
      margin-right: 5px;
    }
  }
}
