@import 'assets/scss/custom/_variables.scss';

.card {
  overflow: hidden;
  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 23px;
      color: #32325d;
    }
    .headerBadge {
      width: 26px;
      height: 26px;
      display: inline-flex;
      margin-left: 11px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: #ec0c38;
      color: #ffffff;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
    }
    .viewButton {
      padding: 0;
      font-style: normal;
      color: #1da1f2;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-decoration: underline;
      &:active:focus {
        box-shadow: none;
      }
    }
  }
  .cardFooter {
    border-top: none;
    padding: 16px 16px 32px 16px;
    .actions {
      text-align: end;
      .archiveButton {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #1da1f2;
        text-decoration: underline;
        padding: 0;
        &:active:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.notificationList {
  max-height: 470px;
  overflow-y: auto;
  padding: 0;
  &.fullView {
    max-height: 100%;
    height: calc(100vh - 360px);
  }
  &::-webkit-scrollbar {
    display: none;
    background-color: transparent;
    opacity: 0.9;
    width: 4px;
    border-radius: 6px;
  }
  .messagesWrapper {
    padding: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar;
    border-radius: 6px;
    transition: opacity 0.2s, background-color 0.2s linear,
      width 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s, background-color 0.2s linear,
      width 0.2s ease-in-out;
    width: 4px;
  }

  :global {
    .list-group-item-action {
      &:focus {
        background-color: transparent;
      }
    }
  }
}

.notificationBadge {
  position: relative;
  top: -10px;
  left: -10px;
  border: 3px solid $secondary !important;
  font-size: 0.6rem;
  width: 1.7rem;
  height: 1.7rem;
}

.unread {
  background: $secondary;
}

.notificationHeader {
  color: $gray-600;
  .markAll {
    cursor: pointer;
  }
}
.notificationWrapper {
  display: flex;

  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
  overflow: hidden;
  cursor: pointer;

  .notificationSidebar {
    width: 20px;
    &.active {
      border-left: 12px solid #1da1f2;
      height: 96px;
    }
  }
  .contentWrapper {
    width: calc(100% - 20px);
    height: 96px;
    display: flex;
    flex-direction: column;
    user-select: none;
    .header {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .subject {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .icon {
          margin-right: 8px;
        }
        .title {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 23px;
          color: #32325d;
          margin-bottom: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .postDetails {
        display: flex;
        align-items: center;
        .date {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          text-align: end;
          color: #8898aa;
          margin-bottom: 0;
        }
        .avatar {
          margin-left: 16px;
          margin-right: 16px;
          height: 28px;
          width: 28px;
        }
      }
    }
    .divider {
      border-bottom: 1px solid #dee2e6;
    }
    .content {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .message {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
        body {
          font-weight: 600 !important;
          background: transparent;
          span {
            color: #32325d !important;
          }
          em {
            color: #32325d !important;
          }
          b {
            color: #32325d !important;
          }
          i {
            color: #32325d !important;
          }
          ul {
            li {
              span {
                color: #32325d !important;
              }
              strong {
                color: #32325d !important;
              }
            }
          }
          p {
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 0;
            color: #32325d !important;
            strong {
              color: #32325d !important;
              font-weight: 700;
            }
          }
        }
      }
      .messageOpenAction {
        margin-left: 16px;
        margin-right: 24px;
      }
    }
  }
}

.notificationContent {
  max-width: calc(100% - 72px);
  span {
    word-break: break-word;
  }
}

.noMessages {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 358px;
  padding: 16px;
  .content {
    max-width: 607px;
    h3 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #3c4d69;
      margin-bottom: 8px;
    }
    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #3c4d69;
      margin-bottom: 0;
    }
  }
}

.filterWrapper {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 16px;
  align-items: center;
  &.three-column {
  }
  @media (max-width: 900px) {
    grid-template-columns: 60px 1fr;
  }
  @media (max-width: 768px) {
    align-items: baseline;
  }
  .label {
    p {
      font-family: 'Open Sans';
      margin-bottom: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      color: #525f7f;
    }
  }
  .filters {
    display: grid;
    align-items: center;
    flex-wrap: wrap;

    width: 605px;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;
    &.three-column {
      width: 470px;
      grid-template-columns: repeat(3, 1fr);
      @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
      }
      @media (max-width: 572px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
      }
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
    }
    @media (max-width: 572px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
    }
    .datePicker {
      right: 0;
      left: auto;
    }
  }
}
