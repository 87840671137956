.container {
  margin-top: 6px;
  .backToDashboard {
    padding: 0;
    margin-bottom: 22px;
    &:active:focus {
      box-shadow: none !important;
    }
    span {
      i {
        top: 1px !important;
      }
    }
  }
}
