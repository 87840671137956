@import 'assets/scss/custom/_variables.scss';
.docVerify {
  .modalBody {
    padding: 24px;
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      background-color: transparent;
      opacity: 0.9;
      width: 4px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 40px;
      width: 4px;
    }
    .title {
      width: 100%;
      h3 {
        max-width: 572px;
        margin: 0 auto;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #32325d;
      }
    }
  }
  .uploadNumber {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid #e6e6e6;
    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #32325d;
      margin-bottom: 7px;
    }
  }
  .uploadedFileSection {
    padding: 16px 24px 24px 24px;
    .fileList {
      width: 100%;
      max-height: 192px;
      overflow-y: auto;
      padding-right: 8px;

      &::-webkit-scrollbar {
        background-color: transparent;
        opacity: 0.9;
        width: 4px;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 40px;
        width: 4px;
      }
      .file {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .fileDetails {
          display: flex;
          gap: 14px;
          img {
            width: 48px;
            max-width: 100%;
          }
          .title {
            h3 {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              color: #525f7f;
              margin-bottom: 4px;
            }
            p {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              color: #adb5bd;
              margin-bottom: 0;
            }
          }
        }
        .removeFile {
          cursor: pointer;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 28px;
          background: #f5365c;
          box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
            0px 1px 3px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          i {
            font-size: 12px;
            color: #ffffff;
          }
        }
      }
    }
  }
  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 5px 24px 11px 24px;
    .back {
      box-shadow: none !important;
      background: transparent;
      &:active {
        background-color: transparent;
      }
      &:hover {
        transform: none;
      }

      span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #4f4f4f;
        i {
          top: 0;
        }
      }
    }
    .actions {
      .next {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #212529;
        border: 1px solid #d2e3ee;
        background: transparent;
        box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.5);
        border-radius: 4px;
        &:hover {
          background: #1da1f2;
          color: #ffffff;
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
  }
}
