@import 'assets/scss/custom/_variables.scss';
.cardContainer {
  max-width: 720px;
}

.formWrapper {
  @media (max-width: 1200px) {
    width: 80%;
  }

  @media (max-width: 991px) {
    width: auto;
    margin: 0 24px 30px 24px;
  }

  :global {
    .row {
      align-items: center;

      @media (max-width: 991px) {
        flex-wrap: nowrap;
      }
    }
    .rselect__control {
      min-width: 160px;
    }
    .form-group {
      margin-bottom: 0;
    }
  }

  .number {
    font-size: 14px;
    color: white;
    width: 32px;
    height: 32px;
    background: #53c3f0;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
  }

  .question {
    font-weight: 600;
    font-size: 20px;
    color: #0e0931;
  }

  .star {
    margin-left: 0.5rem;
    color: $danger;
  }

  .inputField {
    margin: 16px 0 40px 48px;

    .date {
      width: 75px;
    }

    .input {
      :global {
        .form-control {
          width: 310px !important;
          background-color: red;
        }
      }
    }
  }

  .info {
    color: #a2a0b4;
    margin-left: 8px;
  }
}
