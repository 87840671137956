.row {
  width: 100%;
  margin: 0;
  display: flex;
  flex: 1;
  align-items: baseline;

  .dateWrapper {
    position: relative;
  }
  .icon {
    position: absolute;
    font-size: 20px;
    top: 30%;
    right: 4%;
    color: #a2a0b4;
  }

  :global {
    textarea {
      width: 100%;
    }
    .input-group {
      border: 0 !important;
    }
    .rdtPicker {
      right: 0;
    }
  }

  .textInputRightMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 12px;

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
    @media (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  .textInputLeftMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
    @media (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  .textInputMiddleMargin {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
    @media (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  .textInputAddress {
    display: flex;
    flex: 2;
    flex-direction: column;
    margin-right: 12px;

    input {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: initial;

    .textInputRightMargin {
      margin-right: 0;
    }
    .textInputLeftMargin {
      margin-left: 0;
    }
    .textInputMiddleMargin {
      margin-right: 0;
      margin-left: 0;
    }
    .textInputAddress {
      margin-right: 0;
    }
  }
}
