@import 'assets/scss/custom/_variables.scss';

.card {
  border-radius: 0.625rem;
  padding: 2rem 1.5rem;
  max-width: 45rem;
  .cardBody {
    .documentCard {
      .cardTitle {
        background: linear-gradient(
            269.38deg,
            rgba(52, 100, 119, 0.65) -93.24%,
            rgba(77, 121, 139, 0.65) -67.76%,
            #026592 -29.92%,
            #0373a0 8.77%,
            #005582 72.69%,
            rgba(45, 94, 114, 0.58) 103.18%,
            rgba(74, 142, 169, 0.65) 113.46%
          ),
          linear-gradient(
            270deg,
            #005582 1.87%,
            #026592 9.65%,
            #0373a0 16.39%,
            rgba(77, 121, 139, 0.65) 56.3%,
            rgba(52, 100, 119, 0.65) 76%,
            rgba(45, 94, 114, 0.58) 84.29%,
            rgba(74, 142, 169, 0.65) 98.29%
          );
        color: $white;
        text-align: center;
      }
    }
  }
}
