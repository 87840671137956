@import 'assets/scss/custom/_variables.scss';

.root {
  position: fixed;
  z-index: 9999999;
  div {
    div {
      z-index: $zindex-popover !important;
    }
  }
}
:global {
  .rna-wrapper .alert [data-notify='icon'] {
    padding-top: 5px;
  }
}
