@import 'assets/scss/custom/_variables.scss';

.root {
  width: 100%;
  .buttonGroup {
    button {
      border: 1px solid #dee2e6;
      border-radius: 4px;
      background: #fff;
      color: #a2a0b4;
      color: #525f7f;
      text-align: center;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.28px;
      padding: 6px 26px;
      &.active {
        background: #0ca8eb;
        span {
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    .title {
      color: #32325d;
      font-family: Open Sans;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 20px;
      .desc {
        color: #525f7f;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px; /* 168.75% */
      }
    }

    .searchBox {
      height: 30px;
      max-width: 250px;
      margin-left: 7px;
    }
    .calendarIcon {
      margin-left: 15px;
      background: #35b0f0;
      height: 32px;
      width: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 16px;
        color: #fff;
      }
    }
    .switchIcon {
      display: flex;
      .switchText {
        font-size: 14px;
        // line-height: 19px;
        margin-left: 10px;
      }
    }
    .expandCollapse {
      margin-left: 15px;
      .expandButton {
        background: #fff;
        padding: 4px;
        width: 32px;
        height: 32px;
        padding: 4px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
        i {
          font-size: large;
        }
      }
    }
    .filterButtonWrapper {
      margin-left: 12px;
      .filterButton {
        background: #fff;
        padding: 4px 8px;
        color: #525f7f;
        text-align: center;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        box-shadow: none;
        border-radius: 4px;
        border: 1px solid #dee2e6;
      }
    }
  }

  .filters {
    padding: 0 30px 30px;
    span {
      padding: 5px 10px;
      background: $gray-200;
      color: #32325d;
      border-radius: 5px;
      font-size: 80%;
      font-weight: 600;
      &:hover {
        a {
          display: inline-block;
        }
      }
      a {
        font-size: 80%;
        font-weight: 600;
        margin-left: 8px;
        color: #32325d;
        display: none;
      }
    }
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
  }

  &.fullscreen {
    padding-top: 20px;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 1px;
    left: 1px;
    z-index: $zindex-modal;
    background: #f8f9fe;

    .content {
      height: calc(100vh - 12rem);
    }
  }

  .expand {
    color: #32325d;
    cursor: pointer;
  }
  .header {
    font-size: 14px;
    font-weight: normal;

    .subText {
      font-size: 14px;
      font-weight: 300;
      @media (max-width: 375.98px) {
        display: block;
      }
    }

    .headerContainer {
      margin-top: -52px;
    }

    .status {
      font-size: 14px;
      font-weight: 300;
      margin-right: 20px;

      b {
        font-weight: 600;
        font-size: 18px;
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .scrollAction {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 16px;
    svg {
      cursor: pointer;
    }
  }

  .customScrollWrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 16px;
    &::-webkit-scrollbar {
      // width: 5px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #dee2e6;
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
      background: #525f7f;
      border-radius: 50px;
    }
  }

  .contentContainer {
    overflow-x: auto;
    padding-bottom: 16px;
    position: relative;
    &::-webkit-scrollbar {
      // width: 5px;
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      background: #dee2e6;
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
      background: #525f7f;
      border-radius: 50px;
    }

    .content {
      flex: 1;
      white-space: nowrap;

      display: flex;
      min-height: calc(100vh - 20rem);

      .column {
        margin-right: 20px;
        min-width: 310px;
        display: flex;
        flex-direction: column;
        @media (max-width: 1023.98px) {
          width: 320px;
        }

        .columnHeader {
          display: flex;
          justify-content: space-between;
          h4 {
            font-weight: 600;
            font-size: 17px;
            line-height: 23px;
            color: #212529;
          }
          .badge {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px 8px;
            width: 26px;
            height: 27px;
            color: $white;
            &.onboarding {
              background: linear-gradient(91deg, #d429af 0%, #a433da 100%);
            }
            &.document_gathering {
              background: linear-gradient(91deg, #a433da 0%, #5b58e1 100%);
            }
            &.calculations {
              background: linear-gradient(91deg, #5b58e1 0%, #2db1ce 100%);
            }
            &.calculations_in_review {
              background: linear-gradient(91deg, #2db1ce 0%, #2dce89 100%);
            }
            &.credits_delivered {
              background: linear-gradient(91deg, #2dce89 0%, #86d03c 100%);
            }
            &.final_report {
              background: linear-gradient(91deg, #cec72a 0%, #da8a11 100%);
            }
            &.final_report_in_review {
              background: linear-gradient(91deg, #da8a11 0%, #dc6522 100%);
            }
            &.complete {
              background: linear-gradient(90deg, #f5365c 0%, #f56636 100%);
            }
            &.on_hold {
              background: linear-gradient(91deg, #dd3a3a 0%, #d429af 100%);
            }
            &.problem_account {
              background: linear-gradient(90deg, #8b7797 0%, #5a5986 100%);
            }
          }
        }

        &:last-child {
          margin-right: 0px;
        }

        .list {
          width: 100%;
          @media (max-width: 1023.98px) {
            width: 320px;
          }
          padding: 10px 10px 0 10px;
          margin-top: 10px;
          background: $white;
          flex: 1 0 300px;
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          &.withFilter {
            margin-top: 6px;
          }
          > div {
            min-height: 100%;
          }
        }
      }
    }
  }

  .expandCollapse {
    cursor: pointer;
    color: #35b0f0;
    margin-left: 6px;
  }
}
.dropdown {
  margin: 0.125rem 0 0 0.125rem;
  .chevron {
    transform: rotate(90deg);
  }
  button {
    padding: 0;
    border: unset;
    background: unset;
    box-shadow: unset;
    font-size: 17px;
    &:hover,
    &:focus {
      box-shadow: none !important;
      transform: unset !important;
      border: unset !important;
      background: unset !important;
    }
  }
  .toggle {
    padding: 0;
    border: unset;
    background: unset;
    box-shadow: unset;
    &:hover,
    &:focus {
      box-shadow: none !important;
      transform: unset !important;
      border: unset !important;
      background: unset !important;
    }
  }
  .dropdownItem {
    font-size: 14px;
    line-height: 19px;
    color: $gray-700;
  }
}

.disablePointer {
  cursor: default !important;
}

.dragContainerCancelBtnStyle {
  color: #32325d;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  border: 0px solid #ffffff;
  background-color: #ffffff;
  box-shadow: none !important;
  &:hover {
    color: #000000;
    border: 0px solid #ffffff;
    background-color: #ffffff;
    box-shadow: none !important;
  }
  &:active {
    background-color: #ffffff !important;
    color: #000000 !important;
    border-color: #ffffff !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

.dragContainerConfirmBtnStyle {
  color: #fff;
  text-align: center;
  /* H4 Mobile - 14px/SemiBold */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
  border-radius: 4px;
  background: #172b4d;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08),
    0px 4px 6px 0px rgba(50, 50, 93, 0.11) !important;
  border-color: transparent !important;
  &:hover {
    color: #fff;
    background: #172b4d;
  }
  &:active {
    color: #fff;
    background: #172b4d;
  }
}

.customPopupClass {
  background-color: red;
}

.customPopupClass {
  p {
    justify-content: space-between !important;
  }
  :global {
    .text-muted {
      color: #322d52;
      text-align: center;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      p {
        color: #322d52;
        text-align: center;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
