@import 'assets/scss/custom/_variables.scss';

.tooltip {
  background-color: $black;
  padding: 5px 10px;
  border-radius: $popover-border-radius;
  color: $white;
  font-size: $popover-font-size;

  img {
    margin-right: 2px;
  }
}
