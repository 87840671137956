@import 'assets/scss/custom/_variables.scss';

.root {
  background: #f7fafc;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 8px;

  .progressBar {
    font-weight: bold;
    font-size: 16px;
    position: relative;
    border-radius: 6px;
    padding: 10px 10px;

    &.onboarding {
      background: linear-gradient(91deg, #d429af 0%, #a433da 100%);
    }
    &.document_gathering {
      background: linear-gradient(91deg, #a433da 0%, #5b58e1 100%);
    }
    &.calculations {
      background: linear-gradient(91deg, #5b58e1 0%, #2db1ce 100%);
    }
    &.calculations_in_review {
      background: linear-gradient(91deg, #2db1ce 0%, #2dce89 100%);
    }
    &.credits_delivered {
      background: linear-gradient(91deg, #2dce89 0%, #86d03c 100%);
    }
    &.final_report {
      background: linear-gradient(91deg, #cec72a 0%, #da8a11 100%);
    }
    &.final_report_in_review {
      background: linear-gradient(91deg, #da8a11 0%, #dc6522 100%);
    }
    &.complete {
      background: linear-gradient(90deg, #f5365c 0%, #f56636 100%);
    }
    &.on_hold {
      background: linear-gradient(91deg, #dd3a3a 0%, #d429af 100%);
    }
    &.problem_account {
      background: linear-gradient(90deg, #8b7797 0%, #5a5986 100%);
    }

    .locked {
      position: absolute;
      right: 12px;
      top: 7px;
      font-size: 12px;
      color: $gray-700;
    }
  }

  .companyName {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $white;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .body {
    padding: 5px 10px;

    .studyRow {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .studyYear {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #000000;
      }
      .studyCompletion {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #172b4d;
      }
      .studyDeadline {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
      }
      .avatar {
        width: 16px;
        height: 16px;
      }
    }
  }

  .summary {
    color: #32325d;
    font-weight: 600;
    font-size: 10px;
  }
  .taskDetails {
    padding: 5px 10px;
    color: #32325d;
    background: #dce5ef;
    position: relative;
    .grayOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #c4c4c4;
      opacity: 0.4;
    }
    .circleBackground {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      border-radius: 50%;
    }
    .completed {
      background: $white;
      color: $success;
      position: absolute;
      justify-content: center;
      font-size: 10px;
      border-radius: 47%;
      opacity: 0.9;
      i {
        opacity: 1;
      }
    }
    .userAvatar {
      width: 16px;
      height: 16px;
    }
    .noUser {
      background: #757575;
      color: $gray-900;
    }
  }
}

.popoverLocked {
  font-size: 14px;
  text-align: left;
  padding: 15px 10px;
  min-width: 280px;
}

.toggleAnimation {
  transition: 0.5s;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 250px;
  min-height: 170px;
  max-height: 600px;
  padding: 12px 11px 12px 11px;
  border-radius: 6px 6px 6px 6px;
  margin-top: 10px;
  &.onboarding {
    background: linear-gradient(91deg, #f1b7e5 0%, #d3aee4 100%);
  }
  &.document_gathering {
    background: linear-gradient(91deg, #d3aee4 0%, #d0cffa 100%);
  }
  &.calculations {
    background: linear-gradient(91deg, #d0cffa 0%, #b3edfa 100%);
  }
  &.calculations_in_review {
    background: linear-gradient(91deg, #b3edfa 0%, #caf7e3 100%);
  }
  &.credits_delivered {
    background: linear-gradient(91deg, #caf7e3 0%, #c8dfb1 100%);
  }
  &.final_report {
    background: linear-gradient(91deg, #eeebc1 0%, #f3e0c4 100%);
  }
  &.final_report_in_review {
    background: linear-gradient(91deg, #f3e0c4 0%, #f5c5a9 100%);
  }
  &.complete {
    background: linear-gradient(90deg, #f3d4da 0%, #e7beb0 100%);
  }
  &.on_hold {
    background: linear-gradient(91deg, #ecbdbd 0%, #f1c8e9 100%);
  }
  &.problem_account {
    background: linear-gradient(90deg, #d2cad6 0%, #8b8b9b 100%);
  }
}
.dataContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    color: $black;
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 700 !important;
    line-height: 10.89px;
    letter-spacing: 0.02em;
    text-align: left;

    :global {
      body {
        font-weight: 700 !important;
      }
    }
  }
  .item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 5px;
    .avatar {
      width: 20px;
      height: 20px;
    }
    .description {
      color: #000000;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.34px;
      letter-spacing: 0.02em;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 90px;
    }
    .interaction {
      display: flex;
      flex-wrap: wrap;
      font-family: Open Sans;
      font-size: 10px;
      font-style: italic;
      text-decoration: underline;
      font-weight: 600;
      line-height: 12.26px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #000000;
      white-space: wrap;
      overflow: hidden;
      overflow-wrap: break-word;
      .date {
        font-weight: 700;
      }
    }
  }
}
