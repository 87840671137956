//
// Avatar
//

// General styles

.avatar {
  color: $white;
  background-color: $gray-500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: $border-radius;
  height: 48px;
  width: 48px;
  object-fit: cover;
  img {
    width: 100%;
    border-radius: $border-radius;
  }

  &.rounded-circle {
    img {
      @extend .rounded-circle;
    }
  }

  + .avatar-content {
    display: inline-block;
    margin-left: 0.75rem;
  }
}

// Avatar size variations

.avatar-xl {
  width: 74px;
  height: 74px;
}

.avatar-lg {
  width: 58px;
  height: 58px;
  font-size: $font-size-sm;
}

.avatar-sm {
  width: 36px;
  height: 36px;
  font-size: $font-size-sm;
}

.avatar-xs {
  width: 24px;
  height: 24px;
  font-size: $font-size-xs;
  &.medium {
    width: 32px;
    height: 32px;
  }
}
