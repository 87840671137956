.avatar {
  width: 140px;
  height: 140px;
  object-fit: cover;
}
.googleWrapper {
  width: calc(100% + 6rem);
  margin-left: -3rem;
  margin-right: -3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.lockScreenBody {
  padding-top: 130px;
}
.unlock {
  margin: 35px 0;
}
