.noteContainer {
  position: relative;
  margin-bottom: 8px;
  border-radius: 2px;
  background-color: rgba(243, 247, 251, 1);
  padding: 9px 40px 50px 20px !important;
  color: #32325d;
  font-size: 14px;
  max-height: unset !important;
  border-top: 0px;
  .pinButton {
    position: absolute;
    right: 3px;
    top: 2px;
    padding: 0px;
    box-shadow: none;
    background: transparent;
    border: none;
    &:active {
      background-color: transparent !important;
      box-shadow: none !important;
    }
    img {
      height: 20px;
      width: 20px;
    }
  }
  .noteActions {
    display: flex;
    bottom: 10px;
    position: absolute;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .editedByTime {
      color: rgba(162, 160, 180, 1);
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
    }
    .dropDown {
      margin-right: 25px;
      .commentsDropdown {
        padding: 5px;
        &.text-light {
          color: rgba(50, 50, 93, 1);
        }
      }
    }
  }
}
