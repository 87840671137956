@import 'assets/scss/custom/_variables.scss';

.wrapper {
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
  }

  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    margin-top: 32px;
    margin-bottom: 32px;
    /* Primary text (32325D) */
    color: #32325d;
  }
  .contentWrapper {
    .nav {
      margin-bottom: 25px;
      gap: 20px;
      .navItem {
        .navLink {
          cursor: pointer;
          color: #32325d;
          font-family: Open Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:hover {
            border: 1px solid transparent;
          }
          &.active {
            font-weight: 600;
            border-bottom: 4px solid#08A5EF;
          }
        }
      }
    }
  }
}

.deals {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(50, 50, 93, 0.1),
    0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  margin-bottom: 20px;

  .dealDeskItem {
    div {
      @media screen and (max-width: 900px) {
        margin-bottom: 10px;
      }

      h3 {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
      }
      p {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #32325d;
      }
      p:first-child {
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #4f4f4f;
      }
    }

    .SignatoryEmail {
      overflow: hidden;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .status {
    max-width: 150px;

    .review {
      // background: #f0ea53;
      border-radius: 12px;
      color: #000000;
      font-size: 10px;
      padding: 5px 12px;
      text-align: center;
    }

    .sent {
      background: #93c37c;
      border-radius: 12px;
      color: #000000;
      font-size: 10px;
      padding: 5px 12px;
    }
  }
}
