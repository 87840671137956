@import 'assets/scss/custom/_variables.scss';

.fileInputWrapper {
  height: 5.625rem;
  border: 1px dashed $gray-300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    margin: 0;
  }
}

.file-row {
  overflow-x: hidden;
  .file-title {
    overflow-x: hidden;
    h4 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.icon {
  background: $white;
}
