@import 'assets/scss/custom/_variables.scss';
.root {
  position: relative;

  .leftIcon {
    position: absolute;
    top: 5px;
    left: 12px;
    z-index: 1;
  }

  &.hasLeftIcon {
    :global {
      .select2-selection--single {
        padding-left: 35px;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }

  .control {
    display: none;
  }

  :global {
    .popover {
      box-shadow: $dropdown-box-shadow !important;
    }
  }

  .popover {
    .popoverBody {
      max-width: 16rem;
      min-width: 12rem;
      padding: 0.5rem 0.5rem 0 0.5rem;
      .popoverSelector {
        :global {
          .rselect__menu {
            margin-top: 0.125rem;
            box-shadow: none;
          }
          .rselect__menu-list {
            max-height: 15rem;
          }
          .rselect__menu {
            position: relative;
          }
          .rselect__option:hover,
          .rselect__option--is-focused,
          .rselect__option--is-selected {
            background-color: $lyght-blue;
            color: $white;
            border-radius: 0.375rem;
          }
        }
      }
    }
  }
}

.is-invalid {
  :global {
    .select2-selection {
      border: $warning 1px solid !important;
    }
  }
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $warning !important;
}

.epicBadge {
  border-radius: 10rem !important;
  max-width: 100% !important;
  transition: all 0.3s;
  display: flex;
  cursor: pointer;
  &.disable {
    padding-right: 0.875em !important;
    cursor: default !important;
    span {
      cursor: default !important;
    }
  }
  .closeIcon {
    display: none;
  }
  &:hover {
    padding-right: 1.5rem;
    .closeIcon {
      transition: all 0.3s;
      right: 10px;
      position: absolute;
      display: block;
    }
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hoverHand {
  cursor: pointer;
}
