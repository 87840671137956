.root {
  .publish {
    display: flex;
    align-items: center;
    height: calc(1.5em + 1.25rem + 5px);
    justify-content: flex-end;
  }

  :global {
    .ql-editor {
      height: calc(100vh - 450px);
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
