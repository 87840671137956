@import 'assets/scss/custom/_variables.scss';

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $warning !important;
}

.is-invalid {
  :global {
    .ql-toolbar {
      border: $warning 1px solid !important;
    }

    .ql-toolbar + .ql-container .ql-editor {
      border: $warning 1px solid !important;
    }
  }
}

.mention-list-container {
  z-index: 1;
  box-shadow: 0px 5px 34px rgba(0, 0, 0, 0.25);
  border-radius: 0.75rem;
  background-color: $white;
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0.7rem;
  }
  &:before {
    border-bottom: 0.6rem solid rgba(0, 0, 0, 0.05);
    border-left: 0.6rem solid transparent;
    border-right: 0.6rem solid transparent;
  }
  &:after {
    border-bottom: 0.5rem solid #fff;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
  }
  &:hover {
    &::-webkit-scrollbar {
      background-color: transparent;
      opacity: 0.9;
      width: 0.375rem;
      border-radius: 0.375rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar;
      border-radius: 0.375rem;
      transition: opacity 0.2s, background-color 0.2s linear,
        width 0.2s ease-in-out;
      -webkit-transition: opacity 0.2s, background-color 0.2s linear,
        width 0.2s ease-in-out;
      width: 0.375rem;
      opacity: 0.5;
    }
  }
  &::-webkit-scrollbar {
    opacity: 0;
    width: 0.375rem;
  }
  &::-webkit-scrollbar-thumb {
    opacity: 0;
    width: 0.375rem;
  }
}

.mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background: white;
  overflow-y: scroll;
  max-height: 15rem;
  width: 10.5rem;
  margin: 0.75rem 0rem;
  overflow: hidden;
  :global {
    .mention-list-item.selected {
      background-color: $gray-100;
    }
  }
}

.mention-list-item {
  padding: 0.5rem;
  width: inherit;
  transition: background-color 0.2s ease;
  cursor: pointer;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    background-color: $gray-100;
  }
  &:active {
    background-color: $lyght-blue !important;
    color: $white;
  }
}
