.studiesCard {
  min-height: 287px;
  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #322d52;
      margin-bottom: 0;
    }
    .button {
      padding: 0;
      text-decoration: underline;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #1da1f2;
      text-decoration: underline;
      &:active:focus {
        box-shadow: none;
      }
    }
  }
  .cardBody {
    padding: 0;
    margin-bottom: 24px;
    box-shadow: 0px 4px 12.8169px 1px rgba(136, 152, 170, 0.08);
    .studyType {
      padding: 4px 16px;
      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #32325d;
        margin-bottom: 0;
      }
    }
    .studies {
      .tableWrapper {
        thead {
          tr {
            th {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 700;
              font-size: 10px;
              line-height: 12px;
              color: #8898aa;
            }
          }
        }
        tbody {
          tr {
            cursor: pointer;
            td {
              vertical-align: middle;
            }
          }
        }
        .companyName {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: #525f7f;
        }
        .years {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #32325d;
        }
        .progressBar {
          p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            display: flex;
            align-items: center;
            color: #525f7f;
            margin-bottom: 0px;
          }
          .bar {
            width: 191px;
            height: 5px;
            background: #e9ecef;
            :global {
              .progress-bar {
                background-color: #2dce89;
              }
            }
          }
        }
        .badgeWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 27px;
          .messages {
            position: relative;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            border-radius: 50%;
            height: 26px;
            width: 26px;
            background: #eb6363;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
            }
          }
        }
      }
    }
  }

  .noStudies {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 16px;
    .content {
      max-width: 400px;
      h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #3c4d69;
        margin-bottom: 8px;
      }
      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #3c4d69;
        margin-bottom: 0;
      }
    }
  }
}

.noDataWrapper {
  height: 100%;
}
.imageWrapper {
  display: flex;
  width: 100px;
  height: auto;
}
.defaultImage {
  max-width: 100px;
}
.defaultText {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #cdcdcd;
}
