@import './BaseStyle.module.scss';
@import 'assets/scss/custom/_variables.scss';

.card {
  border-radius: 0.625rem;
  padding: 2rem 1.5rem;
  // $base-font-size:1rem;
  .administrator {
    @include generateColor($administratorColor);
  }
  .financialCollaborator {
    @include generateColor($financialCollaboratorColor);
  }
  .masterCollaborator {
    @include generateColor($masterCollaboratorColor);
  }
  .basicTeamMember {
    @include generateColor($basicTeamMemberColor);
  }
  .trustedTeamMember {
    @include generateColor($trustedTeamMemberColor);
  }
  .projectManager {
    @include generateColor($projectManagerColor);
  }
  .companyAdministrator {
    @include generateColor($companyAdministratorColor);
  }
  .partnerAdministrator {
    @include generateColor($partnerAdministratorColor);
  }
  .partnerManager {
    @include generateColor($partnerManagerColor);
  }
  .tabBackground {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    border-radius: 0;
    box-shadow: none !important;
    font-weight: $font-weight-bolder !important;
    &.inactive {
      background-color: rgba($color: $gray-100, $alpha: 0.85);
      opacity: 0.5;
    }
  }
}

.teamMemberTable {
  overflow-x: auto;
  .tableBody {
    td {
      vertical-align: middle;
    }
  }
}
