@import 'assets/scss/custom/_variables.scss';

.label {
  color: var(--card-heading-32325-d, #32325d);
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: capitalize;
  &.bucketStatusLabel {
    text-transform: unset;
  }
}
.formWrapper {
  @media (max-width: 1200px) {
    min-width: 90%;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 18px;
  background: #f7fafc;

  .formContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  .select1 {
    flex: 1;
    min-width: 170px;
  }
  .select {
    flex: 1;
    min-width: 72px;
  }
  .editBtn {
    //font-family: 'Inter';
    font-size: 12px;
    color: #0ca8eb;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.docDeatailWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.addDescription {
  padding: 16px 0 0 16px;
  font-size: 16px;
  color: #ced4d9;
  background: #f7f9fc;
  border-radius: 6px;
  height: 124px;
}
