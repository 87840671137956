.custom-tooltip {
  .tooltip-inner {
    background-color: #fff !important;
    color: #525f7f !important;
    font-size: 14px !important;
    min-width: 230px !important;
    max-width: 100% !important;
    border-color: transparent;
    padding: 10px;
    margin: 0 0 0 10px;
    filter: drop-shadow(rgba(0, 0, 0, 0.4) 0 2px 3px);
  }
  .tooltip-inner {
    background: #fff;
  }

  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^='top'] .arrow::before {
    border-top-color: #fff;
  }
  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^='right'] .arrow::before {
    border-right-color: #fff;
  }
  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    border-bottom-color: #fff;
  }

  .bs-tooltip-left .arrow::before,
  .bs-tooltip-auto[x-placement^='left'] .arrow::before {
    border-left-color: #fff;
  }

  .row-view {
    display: flex;
    justify-content: space-between;

    & > span,
    & > p {
      margin-bottom: 4px;
    }
  }

  hr {
    margin: 8px 0;
  }
}
