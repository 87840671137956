.color-picker {
  display: flex;
  height: 8.5rem;
  width: 10.2rem;
  .saturation-picker {
    width: 90%;
    height: 100%;
    position: relative;
    border: 1px solid #b3b3b3;
    border-bottom: 1px solid #f0f0f0;
    overflow: hidden;
  }
  .hue-picker-container {
    position: relative;
    width: 1.2rem;
    border: 1px solid #b3b3b3;
    .hue-picker {
      position: relative;
    }
  }
}
.hue-picker-pointer {
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 2px;
  transform: translateX(-2px);
  border: none;
  background-color: #fff;
  height: 0.1rem;
  width: 1.3rem;
}

.saturation-picker-pointer {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0 1px #fff;
  transform: translate(-0.3rem, -0.3rem);
}
