.modalWrapper {
  max-width: 800px;

  .modalBody {
    padding-top: 50px;
    padding-bottom: 40px;
    h3 {
      color: #32325d;
      text-align: center;
      font-family: Nunito Sans;
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 24px;
    }
    .text {
      max-width: 600px;
      margin: 0 auto 32px;

      p {
        color: #3c4d69;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 24px;
      }
    }
    .content {
      max-width: 600px;
      margin: 0 auto;
    }
    .teamInfo {
      .content {
        max-width: 492px;
        h3 {
          color: #32325d;
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-align: left;
        }
      }
    }
    .documentInfo {
      .content {
        text-align: center;
        min-height: 280px;
        img {
          max-width: 330px;
          width: 100%;
        }
      }
    }
    .progressInfo {
      .content {
        text-align: center;
        min-height: 280px;
        img {
          max-width: 500px;
          width: 100%;
        }
      }
    }
    .welcome {
      .content {
        ul {
          padding: 0;
          list-style-position: inside;
          li {
            color: #3c4d69;
            font-family: Open Sans;
            line-height: 52.819px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
          }
        }
      }
    }
  }
  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 5px 24px 11px 24px;
    .back {
      box-shadow: none !important;
      background: transparent;
      padding: 0;
      border: none;
      &:active {
        background-color: transparent;
      }
      &:hover {
        transform: none;
      }

      span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #4f4f4f;
        i {
          top: 0;
        }
      }
    }
    .nextWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      .stepCount {
        color: #525f7f;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 52.819px; /* 440.161% */
      }
      .next {
        border: 1px solid #d2e3ee;
        background: transparent;
        box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.5);
        border-radius: 4px;
        color: #212529;
        text-align: center;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        &:active {
          background: transparent;
          border: 1px solid #d2e3ee;
          color: #212529;
          box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.5);
        }
      }
    }
  }
}
