@import 'assets/scss/custom/_variables.scss';

.form {
  .input {
    input[type='text'] {
      padding-left: $input-btn-padding-x;
    }
  }
  :global {
    .custom-control-label {
      height: auto !important;
    }
    .rselect__control {
      border: none;
      z-index: 0;
    }
    .rselect__menu {
      z-index: 2;
    }
  }
}
.root {
  .card {
    background-color: $gray-100;
    margin: 0;
  }
}
.icon {
  color: $gray-500;
  left: 12px !important;
  &.usersIcon {
    left: 8px !important;
  }
}
.brandImage {
  width: 75%;
  min-width: 200px;
}
