.wrapper {
  display: flex;
  align-items: center;
  // height: 106px;
  gap: 50px;
  padding: 13px 28px 18px 28px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  background: #f7fafc;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;
  }
  label {
    color: #525f7f;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    margin-bottom: 4px;
  }

  .search {
    // input {
    //   height: 32px;
    // }
  }
  .selectWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: baseline;
    }
    .select {
      width: 190px;
      .menuHeader {
        text-align: right;
      }
      :global {
        // .rselect__control {
        //   min-height: 32px;
        //   height: 32px;
        // }
        .rselect__group-heading {
          color: #0b1526;
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .multi-value_label_default {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 115px;
        }
        .multi-value_label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 65px;
        }
      }
    }
  }
  .infoSection {
    p {
      color: #32325d;
      text-align: center;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: left;
    }
  }
}
