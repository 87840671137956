.wrapper {
  // background: #e8e8e8;

  :global {
    .navbar-horizontal.navbar-transparent {
      position: relative;
    }
  }

  .contentWrapper {
    min-height: calc(100vh - 155px);
  }

  .btn {
    background: #757190;
    border-radius: 4px;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    margin-right: 24px;
  }

  .footer {
    background: #ffffff;
    box-shadow: 0.806871px -24.987px 13px rgba(0, 0, 0, 0.02),
      0.522972px -16.1953px 7.61343px rgba(0, 0, 0, 0.0151852),
      0.310795px -9.62461px 4.14074px rgba(0, 0, 0, 0.0121481),
      0.161374px -4.9974px 2.1125px rgba(0, 0, 0, 0.01),
      0.0657451px -2.03598px 1.05926px rgba(0, 0, 0, 0.00785185),
      0.0149421px -0.462722px 0.511574px rgba(0, 0, 0, 0.00481481);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 0;

    .buttonWrapper {
      width: calc(70% + 50px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
