.commentFeature {
  position: relative;

  .messageIcon {
    margin-left: 5px;
  }

  .commentBox {
    position: absolute;
    z-index: 100;
    left: 25px;
    top: 0px;
    width: 400px;

    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 24px;

    .title {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #32325d;

      button {
        border: none;
        background: none;
      }

      .date {
        color: #525f7f;
        font-weight: 400;
      }
    }

    .content {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #212519;
      margin-top: 15px;
    }

    textarea {
      width: 100%;
      margin-top: 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #212519;
      padding: 10px;
      border: 1px solid #e9ecef;
      border-radius: 8px;
      resize: none;

      &::placeholder {
        color: #adb5bd;
      }
    }

    .btSubmit {
      border: none;
      background: none;
    }
  }
}
