.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 24px;
  padding: 16px;
  margin: 0 16px;
  .studyWrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    align-self: start;
    .card {
      max-height: calc(100vh - 276px);
    }
  }

  .allDocumentsWrapper {
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    overflow: auto;
  }
}
