.tabContract {
  .topContract {
    font-size: 14px;
    color: #000000;

    span.title {
      margin-right: 15px;
      margin-bottom: 15px;
    }

    .btPreview {
      background: #525f7f;
      box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
      border-radius: 4px;
      font-size: 12px;
      color: #ffffff;

      padding: 0 10px;
      border: none;
      outline: none;
      height: 31px;
      margin-bottom: 10px;
    }
    .btPreviewOutline {
      background: #ffffff;
      box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
      border-radius: 4px;
      font-size: 12px;

      padding: 0 10px;
      border: none;
      outline: none;
      height: 31px;
      margin-bottom: 10px;
      border: 1px solid #525f7f;
    }

    .link {
      background: #ffffff;
      border: 1px solid #ecedee;
      box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
      border-radius: 4px;
      font-size: 12px;
      color: #000000;
      height: 31px;
      & + .link {
        margin-left: 12px;
        margin-bottom: 12px;
      }
    }
  }

  .box {
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
    border-radius: 6px;
    padding: 22px;
    margin-bottom: 25px;

    .heading {
      width: 100%;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -0.02em;
      color: #32325d;
      display: flex;
      margin-bottom: 10px;

      button {
        margin-left: auto;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #525f7f;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 23px;
      }
    }

    .label {
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.01em;
      color: #32325d;
      margin-bottom: 7px;
      display: flex;
      cursor: pointer;
      img[action='button'] {
        margin-left: 5px;
        visibility: hidden;
      }

      &:hover {
        img[action='button'] {
          visibility: visible;
        }
      }
    }

    .value {
      margin-left: 10px;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.01em;
      color: #212519;
      margin-bottom: 7px;
      &.signatoryEmail {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .addEntity {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #32325d;
    background: none;
    border: none;
    margin-bottom: 20px;
  }
}
