.container {
  padding: 24px;
  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header {
      margin-bottom: 24px;
      display: flex;
      gap: 8px;
      align-items: center;
      .leftIcon {
        font-size: 26px;
        cursor: pointer;
      }
      .dashIcon {
        color: '#8898AA';
      }
      .studyName {
        color: #32325d;
        color: #32325d;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .docType {
        color: rgba(50, 50, 93, 1);
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .breadcrumb {
        margin-top: 6px;
        color: #8898aa;
      }
    }
  }
  .cardHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    .docType {
      color: rgba(50, 50, 93, 1);
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
