@import 'assets/scss/custom/_variables.scss';

.root {
  .runningTime {
    display: flex;
    align-items: center;

    .timer-btn {
      margin-right: 10px;
      cursor: pointer;
      width: 25px;
      height: 25px;
    }

    span {
      font-size: 26px;
      @media (max-width: 375.98px) {
        font-size: 20px;
      }

      &.error {
        color: $danger;
      }
    }
  }

  .toggleTime {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    color: $black;
    label {
      margin: 0 10px;
    }

    @media (max-width: 375.98px) {
      font-size: 10px;
    }
  }

  .customTime {
    display: flex;
    justify-content: space-between;

    @media (max-width: 375.98px) {
      flex-direction: column;

      :global {
        .arrow-to {
          display: none;
        }
      }
    }
  }
}
