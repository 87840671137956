@import 'assets/scss/custom/_variables.scss';
.modalApprove {
  .title {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #32325d;
    margin-bottom: 30px;
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .deny {
      background: #dee2e6;
      border: #dee2e6;
      color: #525f7f;
    }

    button {
      box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
      border-radius: 4px;
    }
  }

  .successState {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #32325d;

    img {
      display: block;
      margin-bottom: 20px;
      width: 50px;
      height: 50px;
    }
  }
}
