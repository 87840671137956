@import 'assets/scss/custom/_variables.scss';
.root {
  position: relative;

  .leftIcon {
    position: absolute;
    top: 12px;
    left: 15px;
    z-index: 1;
  }

  &.hasLeftIcon {
    :global {
      .rselect__control {
        padding-left: 35px;
      }
    }
  }

  :global {
    .rselect__control {
      border: 1px solid $input-border-color;
      border-radius: $input-border-radius;
      font-size: $input-btn-font-size;
      padding: 5px;
      color: $input-color;
      min-height: 46px !important;

      &:hover {
        border-color: $input-border-color;
      }

      &.rselect__control--is-focused {
        box-shadow: none;
        border-color: $input-border-color;
      }
    }

    .rselect__placeholder {
      color: $input-placeholder-color;
    }

    .rselect__single-value,
    .rselect__option {
      color: $input-color;
      font-weight: normal;
    }

    .rselect__option--is-focused {
      background-color: $dropdown-link-hover-bg;
      color: $dropdown-link-color;
    }

    .rselect__option--is-selected {
      background-color: $dropdown-link-hover-bg;
      color: $dropdown-link-color;
    }

    .rselect__option:hover {
      background-color: $dropdown-link-hover-bg;
      color: $dropdown-link-color;
    }

    .rselect__indicator {
      padding: 0 2px;
    }

    .rselect__indicator-separator,
    .rselect__dropdown-indicator {
      display: none;
    }

    .mr-3 {
      padding: 2rem !important;
    }
  }
}

.is-invalid {
  :global {
    .rselect__control {
      border-color: $warning !important;
    }
  }
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $warning !important;
}
