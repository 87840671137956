.openSignatureModal {
  display: block !important;
  border-radius: 6px;
  z-index: 1;
  position: absolute;
  inset: 0;
  background: rgba(128, 128, 128, 0.5);
  backdrop-filter: blur(4px);
}
.customModal {
  display: none;
  padding: 15px;
  @media screen and (max-width: 576px) {
    // overflow: auto;
    padding: 0;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.5);
    border-radius: 60px;
  }
  .card {
    display: none;
    &.showCard {
      display: block !important;
    }
    max-width: 788px;
    margin: 163px auto;
    @media screen and (max-width: 544px) {
      margin: auto;
      // height: 100%;
    }
    .cardBody {
      padding: 48px 36px 36px 36px;
      @media screen and (max-width: 576px) {
        // overflow: auto;
        padding: 16px;
      }

      .title {
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #32325d;
        margin-bottom: 38px;
        @media screen and (max-width: 576px) {
          font-size: 22px;
        }
      }
      .checkbox {
        margin-bottom: 32px;
        :global {
          .custom-control-label {
            height: auto !important;
          }
          .custom-control-label::before {
            border: 1px solid;
            border-color: #525f7f;
          }
          .custom-checkbox
            .custom-control-input:checked
            ~ .custom-control-label::before {
            border-color: #0ca8eb;
          }
        }
        .text {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #32325d;
          text-align: center;
          @media screen and (max-width: 576px) {
            font-size: 16px;
          }
        }
      }
      .buttons {
        margin-bottom: 32px;
        display: flex;
        justify-content: center;
        gap: 16px;
        // @media screen and (max-width: 544px) {
        //   flex-direction: column;
        // }
        button {
          margin: 0;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          box-shadow: none;
          border-radius: 56px;
          padding: 15px 50px;
          @media screen and (max-width: 576px) {
            padding: 8px 24px;
            font-size: 18px;
          }
        }
        .decline {
          color: #525f7f;
          background: linear-gradient(268deg, #e3e3f0 6.06%, #dcdced 94.08%);
        }
        .agree {
          color: #ffffff;
          background: linear-gradient(91.05deg, #f7cb45 1.07%, #f08b34 99.1%);
        }
      }
      .noAuthority {
        p {
          cursor: pointer;
          text-align: center;
          margin-bottom: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          text-decoration-line: underline;
          color: #32325d;
        }
      }
    }
    .cardFooter {
      background-color: #f2f2f2;
      text-align: center;
      max-height: 0px;
      overflow: hidden;
      padding: 0;
      border: none;
      // display: none;
      &.showCardFooter {
        max-height: 300px;
        transition: all 0.3s ease-out;
        padding: 36px;
        // display: block;
      }
      .inputWrapper {
        max-width: 576px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 3fr 2fr;
        gap: 16px;

        .buttonWrapper {
          button {
            width: 100%;
          }
          .disabled {
            border: 1px solid #525f7f;
            background: transparent;
            color: #525f7f;
          }
        }
      }
    }
  }
}

.alertText {
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #525f7f;
  }
}
