.wrapper {
  .header {
    p {
      color: #000;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.4px;
      .required {
        color: #ff0000;
      }
      .tooltip {
        cursor: pointer;
        padding-left: 8px;
      }
    }
  }
  .inputs {
    display: flex;
    gap: 24px;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      justify-content: flex-start;
    }
    .inputGroup {
      cursor: pointer;
      color: #000;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.4px;
      .input {
      }
      label {
        cursor: pointer;
      }
    }
  }
  .preview {
    border-radius: 16px;
    border: 1px solid #8898aa;
    background: #fff;
    position: relative;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height: calc(100vh - 598px);

    i {
      cursor: pointer;
      position: absolute;
      right: 8px;
      top: 8px;
      font-size: 20px;
      transform: rotate(45deg);
      z-index: 1000;
    }
    h3 {
      color: #525f7f;
      text-align: center;
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.4px;
    }
    img {
      width: 80%;
      margin: auto;
      max-width: 100%;
    }
  }
}

.tooltipCustom {
  padding: 8px;
  max-width: 400px;
  text-align: left;
  span {
    text-align: left;
    color: #fff;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }
}
.innerTooltip {
  padding: 8px;
  max-width: 415px;
  text-align: left;
  span {
    text-align: left;
    color: #fff;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }
}
