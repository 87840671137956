@import 'assets/scss/custom/_variables.scss';
.uploadSection {
  .modalBody {
    padding: 0;
    .docInfo {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #393f49;
    }
    .description {
      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #212529;
      }
    }

    .fileInputWrapper {
      margin-bottom: 8px;
      width: 100%;
      background: rgba(241, 241, 241, 0.7);
      border: 1.5px dashed #8898aa;
      box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      min-height: 186px;
      height: 100%;

      &.hoverDrop {
        background: #d2e3ee !important;
      }

      &.pendingReview {
        border: 2px dashed #52d75f;
      }
      &.verified {
        border: 2px solid #cbc9d6;
      }

      p {
        margin: 0;
      }

      .onDropText {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          max-width: 90px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 23px;
          color: #32325d;
          margin-bottom: 10px;
          text-align: center;
        }
        .dropIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 32px;
          background: #7889e8;
          border-radius: 4px;
          i {
            color: #ffffff;
          }
        }
      }
      .uploadActions {
        margin-top: 34px;
        text-align: center;
        .text {
          max-width: 90px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 18px;
          text-align: center;
          p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 0;
          }
        }
        .uploadButton {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #212529;
          background: #f7fafc;
          box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
            0px 1px 3px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
        }
      }
    }
    .fileList {
      width: 100%;
      max-height: 192px;
      overflow-y: auto;
      padding-right: 8px;

      &::-webkit-scrollbar {
        background-color: transparent;
        opacity: 0.9;
        width: 4px;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 40px;
        width: 4px;
      }
      .file {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .fileDetails {
          display: flex;
          gap: 14px;
          img {
            width: 48px;
            max-width: 100%;
          }
          .title {
            h3 {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              color: #525f7f;
              margin-bottom: 4px;
            }
            p {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              color: #adb5bd;
              margin-bottom: 0;
            }
          }
        }
        .removeFile {
          cursor: pointer;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 28px;
          background: #f5365c;
          box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
            0px 1px 3px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          i {
            font-size: 12px;
            color: #ffffff;
          }
        }
      }
    }
  }
  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: none;
    .back {
      box-shadow: none !important;
      background: transparent;
      &:active {
        background-color: transparent;
      }
      &:hover {
        transform: none;
      }

      span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #5e72e4;
      }
    }
    .next {
      // border: 1px solid #d2e3ee;
      color: #fff;
      background: #5e72e4;
      padding: 12px 24px;
      box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.5);
      border-radius: 4px;
      &:active {
        background: #5e72e4;
        color: #fff;
        // border: 1px solid #d2e3ee;
        box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.5);
      }
    }
  }
}
