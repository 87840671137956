.modalContentWrapper {
  .modalBody {
    padding: 24px 28px 32px 28px;
    .content {
      max-width: 473px;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      .title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #32325d;
        margin-bottom: 28px;
      }
      .description {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #212529;
        margin-bottom: 20px;
      }
      .collaborators {
        li {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          text-align: center;
          color: #212529;
          width: fit-content;
          margin: 0 auto;
          margin-bottom: 8px;
        }
      }
    }
  }
  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 5px 24px 11px 24px;
    .back {
      box-shadow: none !important;
      background: transparent;
      &:active {
        background-color: transparent;
      }
      &:hover {
        transform: none;
      }

      span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #4f4f4f;
        i {
          top: 0;
        }
      }
    }
    .submit {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
    }
  }
}
