@import 'assets/scss/custom/_variables.scss';
@import './BaseStyle.module.scss';

@mixin generateBackgroundColor($base-color) {
  .icon {
    background-color: $base-color;
  }
  .title {
    color: $base-color;
  }
  .description {
    color: #222529;
  }
}

.option {
  cursor: pointer;
  .icon {
    color: $white;
    font-size: 2.375rem !important;
    align-self: stretch;
    width: 4.6875rem !important;
  }
  &.administrator {
    @include generateBackgroundColor($administratorColor);
  }
  &.financialCollaborator {
    @include generateBackgroundColor($financialCollaboratorColor);
  }
  &.masterCollaborator {
    @include generateBackgroundColor($masterCollaboratorColor);
    .icon {
      font-size: 1.5rem !important;
    }
  }
  &.basicTeamMember {
    @include generateBackgroundColor($basicTeamMemberColor);
  }
  &.trustedTeamMember {
    @include generateBackgroundColor($trustedTeamMemberColor);
  }
  &.projectManager {
    @include generateBackgroundColor($projectManagerColor);
  }
  &.companyAdministrator {
    @include generateBackgroundColor($companyAdministratorColor);
  }
  &.partnerAdministrator {
    @include generateColor($partnerAdministratorColor);
  }
  &.partnerManager {
    @include generateColor($partnerManagerColor);
  }
}

.textWrap {
  white-space: pre-wrap;
}

.descriptionCharacterCount {
  bottom: 0;
}

.control {
  padding: 0rem !important;
  border-radius: 0.25rem;
  height: 6.75rem;
  // line-height: 7rem;
  .valueContainer {
    padding: 0;
    height: 100%;
    border-radius: inherit;
    .singleValue {
      margin: 0 !important;
      align-self: stretch !important;
      border-radius: inherit;
      height: 100%;
    }
  }
}

.FormData {
  color: #525f7f !important;
  .workFlowSection {
    h5 {
      color: #525f7f !important;
    }
  }
  .lastNameLabel {
    margin-top: 1.7rem;
  }
  .inputLabel {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-right: 6px;
    color: #322d52;
  }
  .statusLabel {
    font-family: 'Nunito Sans';
    font-size: 16px;
    line-height: 24px;
    color: #f09553;
  }
  .statusTimeLabel {
    font-family: 'Open Sans';
    display: flex;
    font-size: 14px;
    line-height: 22px;
    height: 20px;
    color: #a2a0b4;
    margin-top: 6px;
    span {
      margin-right: 3px;
    }
  }
  .resendBtn {
    background: #53c3f0;
    border-radius: 4px;
  }
}

.roleSelectorDropdown {
  width: 85% !important;
  min-width: 200px;
}

.emptyTable {
  table {
    margin-bottom: 0px !important;
    border-bottom: none !important;
  }
}

.noTeamMemberWrapper {
  height: 283px;
  width: 100%;
  .teamMemberImageWrapper {
    display: flex;
    width: 100px;
    height: 100px;
    background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
    border-radius: 50%;
    margin-bottom: 20px;
  }
  .defaultText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #cdcdcd;
  }
}

// .users {
//   .userName {
//     vertical-align: middle !important;
//     font-weight: 600;
//     @media (max-width: 768px) {
//       max-width: 20vw;
//     }
//     a {
//       display: block;
//       width: 100%;
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       color: unset;
//     }
//   }
//   .middle {
//     vertical-align: middle;
//   }
//   .duration {
//     max-width: 150px;
//   }
//   .noDataWrapper {
//     height: calc(100vh - 280px);
//   }
//   .addUser {
//     color: $primary;
//     cursor: pointer;
//   }
//   .image {
//     max-width: 550px;
//   }
//   :global {
//     .react-bootstrap-table.table-responsive {
//       overflow: visible;
//       min-height: 150px;
//       @media (max-width: 480px) {
//         overflow: auto;
//       }

//       .rselect__menu {
//         z-index: 9;
//       }
//       // This is to apply ellipsis to the table-cells, if we do not use this it will go beyond the width
//       table {
//         table-layout: fixed;
//       }
//       .react-bs-table-no-data {
//         white-space: normal;
//         text-align: center;
//       }
//     }
//   }
// }
// .ellipsis {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
// .detailColumns {
//   width: 22%;
//   @media (max-width: 768px) {
//     width: unset;
//     max-width: 20vw;
//   }
//   @media (max-width: 480px) {
//     width: 200px;
//   }
// }
// .statusColumns {
//   width: 12%;
//   @media (max-width: 768px) {
//     width: 160px;
//   }
// }
// .roleColumn {
//   width: 32%;
//   min-width: 250px;
//   @media (max-width: 768px) {
//     width: 420px;
//   }
// }
// .applyHeight {
//   height: 46px;
// }
// .projectOption {
//   &:hover {
//     background: $gray-100;
//     cursor: pointer;
//   }
// }
// .selectedOption {
//   :global {
//     .rselect__multi-value {
//       position: relative;
//       transition: all 0.3s;
//     }
//     .rselect__multi-value__remove {
//       display: none;
//       &:hover {
//         background: unset;
//       }
//     }
//   }
//   &:hover {
//     :global {
//       .rselect__multi-value {
//         padding-right: 20px;
//       }
//       .rselect__multi-value__remove {
//         display: block;
//         position: absolute;
//         right: 2px;
//         top: 2px;
//       }
//     }
//   }
// }
