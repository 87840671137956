@import 'assets/scss/custom/_variables.scss';

.root {
  :global {
    .modal-header {
      border-bottom: 1px solid $gray-200;
      .close {
        padding: 15px;
        &.version_2 {
          padding: 0px;
          margin: auto;
          span {
            font-size: 30px;
            font-weight: 700;
          }
        }
      }
    }

    .modal-body {
      padding: 1.25rem;
    }
  }
}
