@import 'assets/scss/custom/_variables.scss';

.redirectButton {
  color: #1da1f2;
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  padding: 6px 9px;
  margin-right: 24px !important;
  &:hover {
    color: white;
  }
  i {
    top: 0;
  }
}

.modalWrapper {
  max-width: 603px;
  height: 868px;
  :global {
    .modal-content {
      height: 100%;
    }
  }
}

.story-modal {
  overflow-y: inherit !important;
  .loading {
    z-index: 999;
    height: 600px !important;
  }
  @media (max-width: 768px) {
    flex-direction: column !important;
    flex-wrap: unset !important;
  }
  .scrollBar {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    width: 60%;
    height: 750px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .scrollBar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  p {
    margin-bottom: 0;
  }

  .owner-popover {
    margin: 0;
    .owner-popover-body {
      width: 16rem;
      padding: 0.5rem;
      .owner-selector {
        :global {
          .rselect__menu {
            margin-top: 0.125rem;
            box-shadow: 0rem 2rem 2rem 0rem rgba(0, 0, 0, 0.2);
            width: 16rem;
            display: flex;
            justify-content: center;
            left: -0.5rem;
            border-radius: 0 0 0.4375rem 0.4375rem;
          }
          .rselect__menu-list {
            max-height: 15rem;
            width: 100%;
            margin: 0 0.5rem;
          }
          .rselect__option:hover,
          .rselect__option--is-focused,
          .rselect__option--is-selected {
            background-color: $lyght-blue;
            color: $white;
            border-radius: 0.375rem;
          }
        }
      }
    }
  }

  h4 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .hover-hand {
    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-wrapper {
    max-width: 100%;
    > button {
      max-width: 100%;
    }
  }

  .dropdown-menu {
    max-width: 12.5rem;
    padding: 0.5rem 0.75rem;
  }

  .result-wrapper {
    margin-top: 0.5rem;
    max-height: 12.5rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .toggle {
    padding: 0;
    border: unset;
    background: unset;
    box-shadow: unset;
    &:hover,
    &:focus {
      box-shadow: none !important;
      transform: unset !important;
      border: unset !important;
      background: unset !important;
    }
  }

  .result-item {
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 6px;
    &.active {
      background: $lyght-blue;
      color: $white;
    }
    &:hover {
      background: $lyght-blue;
      color: $white;
    }
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .left-column {
    color: $gray-700;
    .statusBarWrapper {
      margin-bottom: 8px;
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      height: 16px;
      border-radius: 8px;
      overflow: hidden;
      grid-gap: 1px;
      .bar {
        height: 16px;
        background-color: #f0f3f5;
      }
      .completed {
        background-color: #32325d;
      }
    }
    .badgeWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        background: #32325d;
        border-radius: 23px;
        padding: 9px 24px;
        color: #ffffff;
      }
    }
    .clientDetails {
      margin-bottom: 20px;
      h5 {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #32325d;
        margin-bottom: 24px;
      }
      p {
        text-transform: capitalize;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #32325d;
      }
      .studyDetailsWrapper {
        display: flex;
        gap: 15px;
        margin-bottom: 10px;
        align-items: center;
        .studyType {
          width: 15%;
          h3 {
            font-weight: 700;
            font-size: 13px;
            line-height: 18px;
            color: #32325d;
            margin-bottom: 0;
          }
          p {
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #525f7f;
            margin-bottom: 0;
          }
        }
        .paidInFull {
          width: 15%;
          display: flex;
          flex-direction: column;
          position: relative;
          div {
            height: 16px;
            background-color: #f0f3f5;
            border-radius: 8px;
          }
          p {
            position: absolute;
            top: -20px;
            width: 100%;
            text-align: center;
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            margin-bottom: 0;
          }
        }
        .studyProgression {
          width: 70%;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          height: 16px;
          border-radius: 8px;
          grid-gap: 1px;
          .barWrapper {
            display: flex;
            flex-direction: column;
            position: relative;
            .activeBar {
              background-color: #4fb3c9;
            }
            .completedStatus {
              background-color: #4fb3c9;
            }
            .halfReport {
              background: linear-gradient(to right, #4fb3c9 50%, #f0f3f5 50%);
            }
            .firstChild {
              border-radius: 8px 0px 0px 8px;
            }
            .lastChild {
              border-radius: 0px 8px 8px 0px;
            }
            .onHoldBar {
              background: repeating-linear-gradient(
                135deg,
                rgba(0, 0, 0, 0.1),
                rgba(0, 0, 0, 0.1) 3px,
                rgba(0, 0, 0, 0.1) 3px,
                rgba(0, 0, 0, 0) 5px
              ) !important;
            }
          }
          p {
            position: absolute;
            top: -20px;
            width: 100%;
            font-weight: 400;
            margin-bottom: 0;
            font-size: 9px;
            line-height: 12px;
            text-align: center;
          }
          .bar {
            height: 16px;
            background-color: #f0f3f5;
          }
        }
        .paidInFull {
          p {
            font-weight: 400;
            font-size: 9px;
            line-height: 12px;
            margin-bottom: 0;
            text-align: center;
          }
        }
        .fullBar {
          div {
            height: 16px;
            background-color: #4fb3c9 !important;
            border-radius: 8px;
          }
        }
      }
    }
    .details {
      margin-bottom: 24px;
      h5 {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #32325d;
      }
      p {
        text-transform: capitalize;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #32325d;
      }
    }
    .epic-badge {
      border-radius: 10rem !important;
      max-width: 100% !important;
      transition: all 0.3s;
      cursor: default;
      .close-icon {
        display: none;
      }
    }

    .action-item-wrapper {
      width: 100%;
      margin-top: 20px;
      button {
        width: 100%;
      }
    }

    .allow-epic-edit {
      cursor: pointer;
      &:hover {
        .close-icon {
          transition: all 0.3s;
          display: inline-block;
        }
      }
    }

    .description {
      height: max-content;
      border-color: transparent;
      border-radius: 0.5rem;
      img {
        max-width: 100%;
        cursor: pointer;
      }
    }

    .empty-description {
      height: 9rem !important;
    }
    .empty-content {
      background-color: $gray-100;
    }
  }
}
.story-modal-mobile {
  flex-direction: column !important;
  flex-wrap: nowrap !important;
}
.storyDropdown {
  button {
    background: #e4e5ed;
    &:hover {
      background: $white;
      color: $gray-800;
    }
  }
}

:global {
  .ql-editor {
    height: unset !important;
  }
}
