@import 'assets/scss/custom/_variables.scss';
.empty-avatar {
  background-color: $gray-300;
}

.medium-avatar {
  width: 32px;
  height: 32px;
}

.ellipsis {
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.username {
  color: #000;
}
