.inputWrapper {
  max-width: 480px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 16px;

  .buttonWrapper {
    button {
      width: 100%;
    }
    .disabled {
      border: 1px solid #525f7f;
      background: transparent;
      color: #525f7f;
    }
  }
}

.alertText {
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #525f7f;
  }
}
