@import 'assets/scss/custom/_variables.scss';
.container {
  padding: 24px 29px;
  .header {
    margin-bottom: 48px;
    display: flex;
    gap: 24px;
    .breadcrumb {
      margin-top: 6px;
      color: #8898aa;
    }
    h3 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 35px;

      color: #32325d;
    }
  }
  .card {
    .cardBody {
      //   overflow: auto;
      &.scroll {
      }
      .headerStyles {
        justify-content: space-between;
        .uploadFilesButton {
          display: flex;
          align-items: center;
          color: #ffffff;
          gap: 4px;
          i {
            font-size: 12px;
          }
          p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0;
          }
        }
        .headerButton {
          background: #5e72e4;
          border-color: #5e72e4;
          box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
            0px 1px 3px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
        }
      }
      .tableWrapper {
        // min-height: 10.5rem;
        // overflow-x: inherit;
        table {
          * {
            td {
              vertical-align: middle;
              padding-right: 0.5rem;
            }
          }
          tbody {
            tr {
              //   td:first-child {
              //     border-left: 5px solid $lyght-blue;
              //   }
            }
          }
          .row {
            cursor: pointer;
          }
          .storyName {
            width: fit-content;
            max-width: 100%;
          }
          .nameField {
            color: #525f7f;
            font-family: 'Open Sans';
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
          }
          .projectName {
            span,
            a {
              font-weight: $font-weight-bold;
              display: block;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: unset;
            }
          }
          .roleField {
            padding: 2px 8px;
            border-radius: 11px;
            font-family: 'Open Sans';
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
          }
          .roleFieldLow {
            color: #1aae6f;
            background-color: #b0eed3;
          }
          .roleFieldHigh {
            color: #f80031;
            background-color: #fdd1da;
          }
          .roleFieldMedium {
            color: #fb6340;
            background-color: #ffe3b8;
          }
          .priorityHeader {
            width: 6.5rem;
          }
          .epicBadge {
            border-radius: 10rem !important;
            max-width: 100% !important;
            transition: all 0.3s;
            cursor: default;
            .closeIcon {
              display: none;
            }
          }
          .ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .hoverHand {
            &:hover {
              cursor: pointer;
            }
          }
          .epicName {
            overflow: hidden !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient: vertical !important;
            color: $gray-700;
            overflow-wrap: break-word;
            word-break: break-word;
            word-wrap: break-word;
            hyphens: auto;

            h5 {
              color: $gray-700;
            }
          }
        }
        .actionButtonWrapper {
          button {
            padding: 5px 10px;
          }
          .delete {
            i {
              top: 0;
            }
          }
          .view {
            color: #fff;
            border: 1px solid #5e72e4;
            background: #5e72e4;
          }
          .retry {
            color: #fff;
            border: 1px solid #fb6340;
            background: #fb6340;
          }
        }
      }
    }
  }
}
:global {
  .react-bootstrap-table {
    .reset-expansion-style {
      padding: 0 !important;
      td {
        font-weight: 400;
      }
    }
  }
}

.first-expand-cell {
  padding: 0 !important;

  min-width: 48px;
  // display: block;
}
.expand-name-cell {
  min-width: 400px;
}
.expand-files-cell {
  min-width: 200px;
}
.expand-size-cell {
  min-width: 150px;
  // padding-left: 18px !important;
}
.expand-date-cell {
  min-width: 100px;
}
.expand-accuracy-cell {
  min-width: 100px;
  // padding-left: 18px !important;
}
.expand-status-cell {
  min-width: 130px;
  display: flex;
  align-items: center;
  // width: 130px;
  // padding-left: 26px !important;
  // padding-right: 6px !important;
}
.expand-action-cell {
  min-width: 218px;
}

.noDataWrapper {
  height: calc(100vh - 380px);
}
.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: linear-gradient(116.58deg, #e0e0e0 5.32%, #fcfcfc 136.31%);
  border-radius: 50%;
  margin-bottom: 20px;
  .defaultImage {
    width: 34px;
    height: 34px;
    margin: 10px !important;
  }
  h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }
}

.expandIcon {
  font-size: 24px;
  color: #000000;
}
