.modalWrapper {
  .modalBody {
    .modalContent {
      color: #000000;
    }
    .studyText {
      font-weight: 700;
      color: #000000;
      margin-top: 8px;
      margin-bottom: 16px;
    }
    .modalText {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0.4000000059604645px;
      color: #000000;
      margin-bottom: 8px;
    }
  }
  .modalFooter {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e9ecef;
    padding: 13px 24px;
    .cancelBtn {
      padding: 0px 16px;
      color: #1da1f2;
      background-color: #ffffff;
      box-shadow: none;
      &:active,
      &:focus,
      &:hover {
        padding: 0px 16px;
        box-shadow: none !important;
        color: #1da1f2;
        background-color: #ffffff;
      }
    }
    .deleteBtn {
      padding: 11px 16px;
      border-radius: 4px;
      color: #ffffff;
      background-color: #ec0c38;
      box-shadow: 0px 5px 8px 0px #0000001f;
      &:active,
      &:focus,
      &:hover {
        color: #ffffff;
        background-color: #ec0c38;
      }
    }
  }

  .confirmedCheck {
    color: #4fd69c;
  }
}
